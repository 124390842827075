import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getSeasonsForMenus } from "@/service/SeasonApi";
import {
  activateInscription,
  deactivateInscription,
  getInscriptionById,
  getSeasonPreviousInscriptions,
  transferInscriptionBetweenSeasons,
} from "@/service/InscriptionApi";

Vue.component("downloadExcel", JsonExcel);

const state = {
  seasonPreviousInscription: null,

  seasonPreviousInscriptions: [],
  seasons_for_menus: [],
  errorMessages: [],
  partnerId: null,
  city: null,
  state: null,

  target_season: null,

  modalToggleActivateInscription: false,
  modalToggleDeactivateInscription: false,
  modalToggleTransferInscription: false,

  seasonPreviousInscriptionsTotal: 0,
  seasonPreviousInscriptionsTotalPerPage: 10,
  seasonPreviousInscriptionsOffset: 0,

  params: {
    name: null,
    cpf: null,
    rg: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadSeasonPreviousInscriptions: (state, seasonPreviousInscriptions) => {
    state.seasonPreviousInscriptions = seasonPreviousInscriptions;
  },

  // MUTATIONS - FIELDS TO API
  setSeasonPreviousInscriptionMutation: (state, seasonPreviousInscription) => {
    state.seasonPreviousInscription = seasonPreviousInscription;
  },
  setSeasonsMutation: (state, seasons_for_menus) => {
    state.seasons_for_menus = seasons_for_menus;
  },
  setTargetSeasonMutation: (state, target_season) => {
    target_season.id = target_season.value;
    state.target_season = target_season;
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterCpfMutation: (state, cpf) => {
    state.params.cpf = cpf;
  },
  setFilterRgMutation: (state, rg) => {
    state.params.rg = rg;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalActivateInscription: (state) => {
    state.modalToggleActivateInscription = !state.modalToggleActivateInscription;
  },
  toggleModalDeactivateInscription: (state) => {
    state.modalToggleDeactivateInscription = !state.modalToggleDeactivateInscription;
  },
  toggleModalTransferInscription: (state) => {
    state.modalToggleTransferInscription = !state.modalToggleTransferInscription;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setSeasonPreviousInscriptionsTotalPerPage: (state, seasonPreviousInscriptionsTotalPerPage) => {
    state.seasonPreviousInscriptionsTotalPerPage = seasonPreviousInscriptionsTotalPerPage;
  },
  setSeasonPreviousInscriptionsOffset: (state, seasonPreviousInscriptionsOffset) => {
    state.seasonPreviousInscriptionsOffset = seasonPreviousInscriptionsOffset;
  },
  setSeasonPreviousInscriptionsTotal: (state, seasonPreviousInscriptionsTotal) => {
    state.seasonPreviousInscriptionsTotal = seasonPreviousInscriptionsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    state.seasonPreviousInscription = null;
  },
  clearTargetSeason: (state) => {
    state.target_season = null;
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.params.cpf = null;
    state.params.rg = null;
    state.seasonPreviousInscriptions = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams(rootGetters) {
  return {
    name: state.params.name,
    cpf: state.params.cpf,
    rg: state.params.rg,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    per_page: state.seasonPreviousInscriptionsTotalPerPage,
    offset: state.seasonPreviousInscriptionsOffset,
  };
}

function getFilterParamsForTransferInscription() {
  return {
    id: state.seasonPreviousInscription?.id,
    season_id: state.target_season?.id,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadSeasonPreviousInscriptions: ({ commit, rootGetters }, from) => {
    const params = getFilterParams(rootGetters);
    getSeasonPreviousInscriptions(params)
      .then((response) => {
        commit("loadSeasonPreviousInscriptions", response.data.season_previous_inscriptions);
        commit("setSeasonPreviousInscriptionsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSeasons: ({ commit }) => {
    getSeasonsForMenus()
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit, dispatch }, payload) => {
    await getInscriptionById(payload.id)
      .then((response) => {
        dispatch("Inscription/load", response.data.inscriptions, { root: true });
        commit("setSeasonPreviousInscriptionMutation", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  setTargetSeason: ({ commit }, payload) => {
    commit("setTargetSeasonMutation", payload);
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  toggleModalActivateInscription: ({ commit }) => {
    commit("toggleModalActivateInscription");
  },
  toggleModalDeactivateInscription: ({ commit }) => {
    commit("toggleModalDeactivateInscription");
  },
  openModalTransferInscription: ({ commit }) => {
    commit("toggleModalTransferInscription");
  },
  closeModalTransferInscription: ({ commit }) => {
    commit("toggleModalTransferInscription");
    commit("clearTargetSeason");
  },

  activateInscriptionLoad: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalActivateInscription");
  },
  deactivateInscriptionLoad: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalDeactivateInscription");
  },
  transferInscriptionLoad: async ({ dispatch }, payload) => {
    await dispatch("loadData", payload);
    dispatch("openModalTransferInscription");
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterCpf: ({ commit }, payload) => {
    commit("setFilterCpfMutation", payload.value);
  },
  setFilterRg: ({ commit }, payload) => {
    commit("setFilterRgMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setSeasonPreviousInscriptionsTotalPerPage: ({ commit }, payload) => {
    commit("setSeasonPreviousInscriptionsTotalPerPage", payload.totalPerPage);
  },
  setSeasonPreviousInscriptionsOffset: ({ commit }, payload) => {
    commit("setSeasonPreviousInscriptionsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  activateInscription: ({ commit, dispatch, state }) => {
    activateInscription(state.seasonPreviousInscription.id)
      .then(() => {
        dispatch("loadSeasonPreviousInscriptions");
        dispatch("toggleModalActivateInscription");
        Vue.$toast.success("Inscrição ativada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel ativar a inscrição! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  deactivateInscription: ({ commit, dispatch, state }) => {
    deactivateInscription(state.seasonPreviousInscription.id)
      .then(() => {
        dispatch("loadSeasonPreviousInscriptions");
        dispatch("toggleModalDeactivateInscription");
        Vue.$toast.success("Inscrição desativada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel desativar a inscrição! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  transferInscription: ({ commit, dispatch }) => {
    const params = getFilterParamsForTransferInscription();
    transferInscriptionBetweenSeasons(params)
      .then(() => {
        dispatch("loadSeasonPreviousInscriptions");
        dispatch("closeModalTransferInscription");
        Vue.$toast.success("Transferência realizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel realizar a transferência! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
