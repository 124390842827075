<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
        Matriz Problema-Impacto - Problemas
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-facility"
          showIcon
          iconName="add"
          text="Cadastrar Problema"
          dispatch="MatrixProblem/toggleModalMatrixProblems"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-3">
          <BaseLabel
            text="Pesquisar por descrição"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por descrição"
            dispatch="MatrixProblem/filterListByName"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-matrix-problems"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="MatrixProblem/loadMatrixProblems"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="matrix_problems.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-matrix-problems">
      <div class="mx-12">
        <MatrixProblemsCards :data="matrix_problems" />
      </div>
      <div id="matrix_problems-pagination" class="mx-12 my-6 pb-3" v-if="matrix_problems.length > 0">
        <BasePagination
          :total="totalMatrixProblems"
          :total-per-page="totalPerPage"
          dispatch="MatrixProblem/setMatrixProblemOffset"
        />
      </div>
      <ModalMatrixProblem :open="modalToggleMatrixProblems" />
      <DeleteMatrixProblemConfirmation :open="modalConfirmDeleteMatrixProblem" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import MatrixProblemsCards from "./list/MatrixProblemsCards.vue";
import BasePagination from "../components/BasePagination.vue";
import ModalMatrixProblem from "./ModalMatrixProblem.vue";
import DeleteMatrixProblemConfirmation from "./alerts/DeleteMatrixProblemConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    MatrixProblemsCards,
    ModalMatrixProblem,
    DeleteMatrixProblemConfirmation,
    BaseInput,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      clearFields() {
        this.$store.dispatch("MatrixProblem/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.canAcces("matrix_problem_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("matrix_problem_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      matrix_problems: (state) => state.MatrixProblem.matrix_problems,

      modalToggleMatrixProblems: (state) => state.MatrixProblem.modalToggleMatrixProblems,
      modalConfirmDeleteMatrixProblem: (state) => state.MatrixProblem.modalConfirmDeleteMatrixProblem,

      city: (state) => state.City.city,

      offset: (state) => state.MatrixProblem.matrixProblemOffset,
      totalPerPage: (state) => state.MatrixProblem.matrixProblemTotalPerPage,
      totalMatrixProblems: (state) => state.MatrixProblem.matrixProblemTotal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("MatrixProblem/loadMatrixProblems");
      this.backToTop();
    },
  },
};
</script>
