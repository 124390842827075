<template>
  <div class="font-system mb-16">
    <div id="title-page" class="mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl">{{ season.name }}</div>
    </div>

    <div id="breadcrumb" class="mx-12 my-6" v-if="city">
      <div class="text-ashes font-medium text-sm uppercase flex flex-row flex-wrap">
        <div class="mx-1">{{ city.name }}</div>
        <div class="mx-1">|</div>
        <div class="mx-1">{{ type_edition }}</div>
        <div class="mx-1">|</div>
        <div class="mx-1 text-burnedYellow">{{ season.name }}</div>
      </div>
    </div>

    <div class="mx-12 my-8 text-burnedYellow hover:text-burnedYellow-hover grid grid-flow-col auto-cols-max">
      <router-link :to="go_back_url" class="flex items-center">
        <AppMaterialIcon iconName="arrow_back" iconType="outlined" iconSize="18" class="mr-1" />
        <BaseText text="Voltar" type-text="custom" weight="semibold" class="hover:underline" />
      </router-link>
    </div>

    <div id="edition" class="mx-12 my-6">
      <AppCardSeason :season="season" />
    </div>

    <div id="fases" class="mx-12 my-6 flex flex-row flex-wrap justify-center items-center">
      <a href="javascript:void(0)" @click="setPartial('inscriptions')">
        <div :class="classButtonAssignment">
          <AppMaterialIcon iconName="assignment" iconType="outlined" iconSize="48" />
        </div>
      </a>
      <div :key="index" v-for="(phase, index) in phases" :class="classButtonPhases(phase.name)">
        <a href="javascript:void(0)" @click="setPhase(phase)">
          <div class="flex flex-col justify-center items-center mx-12 my-4">
            <div class="uppercase font-black text-3xl">{{ phase.name }}</div>
            <div class="font-light text-sm">
              {{ getNamesDisciplines(phase) }}
            </div>
          </div>
        </a>
      </div>
    </div>

    <div v-if="partial === 'inscriptions'">
      <div id="title-pre-inscriptions" class="mx-12 my-6">
        <BaseText text="relação de inscritos e participantes" typeText="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>

      <div id="filters" class="mx-14">
        <FilterComponent>
          <div class="col-span-2">
            <Multiselect
              id="filterArea"
              selectPlaceholder="Selecionar"
              label-text="filtrar por área"
              class="mr-5"
              closeOnSelect
              show-label
              :multipleOption="false"
              :options="user_areas"
              :value="area"
              :dispatch="setArea"
            />
          </div>
          <div class="col-span-2">
            <Multiselect
              :close-on-select="true"
              show-label
              clear-on-select
              select-placeholder="Equipamento"
              :multiple-option="false"
              :value="equipment"
              :options="equipments"
              :label-text="getLabelEquipmentFilter()"
              :dispatch="filterListByEquipment"
            />
          </div>
          <div>
            <BaseLabel text="Filtrar por Bairro" />
            <BaseInput
              active
              id="filter-student-neighborhood"
              inputName=""
              inputType="text"
              iconName="search"
              ref="neighborhood"
              showIcon
              dispatch="Season/filterListByNeighborhood"
              :value="neighborhood"
            />
          </div>
          <div class="col-span-2">
            <Multiselect
              :close-on-select="true"
              label-text="Filtrar por Município"
              show-label
              clear-on-select
              select-placeholder="Selecione o Município"
              :multiple-option="false"
              :value="city_filtered"
              :options="cities"
              :dispatch="filterListByCity"
            />
          </div>
          <div class="col-span-2">
            <Multiselect
              :close-on-select="true"
              label-text="Filtrar por Nome do Aluno(a)"
              show-label
              clear-on-select
              select-placeholder="Digite o nome do aluno(a) para busca"
              :multiple-option="false"
              :value="inscription_filtered"
              :options="inscriptionsForGeneralMenus"
              :dispatch="filterListByName"
              load-dispatch="Season/loadInscriptionsForGeneralMenus"
            />
          </div>
          <div>
            <Multiselect
              :close-on-select="true"
              label-text="Possui CPF?"
              show-label
              clear-on-select
              select-placeholder="Possui CPF?"
              :multiple-option="false"
              :value="has_cpf"
              :options="positive_negative_options"
              :dispatch="filterListByHasCpf"
            />
          </div>
          <div>
            <BaseLabel text="Filtrar por CPF" />
            <BaseInput
              active
              id="filter-student-cpf"
              inputName=""
              inputType="text"
              iconName="search"
              ref="cpf"
              showIcon
              dispatch="Season/filterListByCPF"
              :value="cpf"
            />
          </div>
          <div>
            <Multiselect
              :close-on-select="true"
              label-text="Possui RG?"
              show-label
              clear-on-select
              select-placeholder="Possui RG?"
              :multiple-option="false"
              :value="has_rg"
              :options="positive_negative_options"
              :dispatch="filterListByHasRg"
            />
          </div>
          <div>
            <Multiselect
              :close-on-select="true"
              label-text="Possui Conta Bancária?"
              show-label
              clear-on-select
              select-placeholder="Possui Conta Bancária?"
              :multiple-option="false"
              :value="has_bank_account"
              :options="positive_negative_options"
              :dispatch="filterListByHasBankAccount"
            />
          </div>
          <div>
            <Multiselect
              :close-on-select="true"
              label-text="Origem da Inscrição"
              show-label
              clear-on-select
              select-placeholder="Selecione a opção desejada"
              :multiple-option="false"
              :value="inscription_origin_filtered"
              :options="inscription_origin_options"
              :dispatch="filterListByInscriptionOrigin"
            />
          </div>
          <div class="mt-5 flex items-end flex-wrap col-span-5">
            <div>
              <AppButtonStatus
                text="Em aberto"
                :active="isActive('open')"
                status="open"
                :number="String(amountInscriptionOpen)"
                typeButton="primary"
                dispatch="Season/setFilterStatusInscriptions"
                class="w-52"
              />
            </div>
            <div class="ml-2">
              <AppButtonStatus
                text="Ativo"
                :active="isActive('accepted')"
                status="accepted"
                :number="String(amountInscriptionAccepted)"
                typeButton="success"
                dispatch="Season/setFilterStatusInscriptions"
                class="w-52"
              />
            </div>
            <div class="ml-2">
              <AppButtonStatus
                text="Desligado"
                :active="isActive('not_accepted')"
                status="not_accepted"
                :number="String(amountInscriptionNotAccepted)"
                typeButton="danger"
                dispatch="Season/setFilterStatusInscriptions"
                class="w-52"
              />
            </div>
            <div class="ml-2">
              <AppButtonStatus
                text="Concludente"
                :active="isActive('graduate')"
                status="graduate"
                :number="String(amountInscriptionGraduate)"
                typeButton="primary"
                dispatch="Season/setFilterStatusInscriptions"
                class="w-52"
              />
            </div>
            <div class="ml-2">
              <AppButtonStatus
                text="Arquivado"
                :active="isActive('archived')"
                status="archived"
                :number="String(amountInscriptionArchived)"
                typeButton="danger"
                dispatch="Season/setFilterStatusInscriptions"
                class="w-52"
              />
            </div>
          </div>
        </FilterComponent>
        <div class="mt-5 flex items-end flex-wrap">
          <div class="mr-5">
            <AppButtonColorful
              v-if="permit"
              id="btn-consult-inscriptions"
              showIcon
              iconName="search"
              text="Consultar dados"
              dispatch="Season/loadDataSeason"
              typeButton="primary"
            />
          </div>
          <div class="mr-5">
            <a href="javascript:void(0)" @click="clearFilter()">
              <img
                class="inline-block"
                src="../assets/images/filter_alt_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Limpar"
                typeText="custom"
                weight="normal"
                :italic="false"
                size="xs"
                color="burnedYellow"
              />
            </a>
          </div>
          <div>
            <a href="javascript:void(0)" @click="exportToExcel('inscriptions_spreadsheet')">
              <img
                class="inline-block"
                src="../assets/images/logout_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Exportar Planilha de Inscritos"
                typeText="custom"
                :italic="false"
                size="xs"
                color="burnedYellow"
                style="cursor: pointer"
              />
            </a>
          </div>
        </div>
      </div>
      <div id="table-young" class="mx-12 my-6">
        <BaseTable
          :header="mountHeader()"
          :body="bodyTable"
          :total="inscriptionTotal"
          :total-per-page="totalPerPage"
          dispatch="Season/setOffset"
        />
      </div>
      <div class="mx-12 my-6">
        <br />
        <br />
      </div>
      <ModalInscription :open="modalInscriptionOpen" />
    </div>
    <div v-else>
      <Phase />
    </div>
    <div v-if="loading_data_season">
      <PreLoading />
    </div>
    <ModalSeason :open="showModal" />
    <EndSeasonConfirmation :open="endSeasonConfirmation" />
    <ReactivateSeasonConfirmation :open="reactivateSeasonConfirmation" />
    <ModalInscriptionStudent :open="modalInscriptionStudentOpen" />
    <ModalInscription :open="modalInscriptionOpen" />
    <ModalTransferStudentToClass :open="showModalTransferStudent" />
    <ModalRemoveStudentFromTeam :open="showModalRemoveStudentFromTeam" />
    <RemoveClassConfirmation :open="showModalConfirmationRemoveStudent" />
    <ModalExportSpreadsheets
      :dataSpreadsheet="dataSpreadsheet"
      :modalToggleExport="modalToggleExport"
      :titleModal="titleModal"
      :dispatchToCloseModal="dispatchToCloseModal"
      :json_data="json_data"
      :sheetTabName="sheetTabName"
      :fileName="fileName"
      :variablePreloading="variablePreloading"
      :json_fields="json_fields"
      :dispatchOpenModal="dispatchOpenModal"
    />
  </div>
</template>

<script>
import Multiselect from "../components/forms/Multiselect.vue";
import BaseTable from "../components/BaseTable.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonStatus from "../components/AppButtonStatus.vue";
import AppCardSeason from "../components/AppCardSeason.vue";
import ModalInscription from "./ModalInscription.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import Phase from "./partials/Phase.vue";
import ModalSeason from "./ModalSeason.vue";
import EndSeasonConfirmation from "./alerts/EndSeasonConfirmation.vue";
import ReactivateSeasonConfirmation from "@/views/alerts/ReactivateSeasonConfirmation.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import { mapState, mapActions } from "vuex";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import ModalTransferStudentToClass from "@/views/ModalTransferStudentToClass.vue";
import ModalRemoveStudentFromTeam from "@/views/ModalRemoveStudentFromTeam.vue";
import ModalInscriptionStudent from "@/views/ModalInscriptionStudent.vue";
import RemoveClassConfirmation from "@/views/alerts/RemoveClassConfirmation.vue";
import ModalExportSpreadsheets from "@/components/ModalExportSpreadsheets.vue";
import PreLoading from "@/components/PreLoading.vue";

export default {
  components: {
    PreLoading,
    ModalExportSpreadsheets,
    RemoveClassConfirmation,
    ModalInscriptionStudent,
    ModalRemoveStudentFromTeam,
    ModalTransferStudentToClass,
    FilterComponent,
    AppButtonColorful,
    EndSeasonConfirmation,
    ReactivateSeasonConfirmation,
    Multiselect,
    BaseTable,
    BaseInput,
    BaseLabel,
    AppButtonStatus,
    BaseText,
    AppCardSeason,
    ModalInscription,
    AppMaterialIcon,
    Phase,
    ModalSeason,
  },

  data: () => ({
    json_fields_inscriptions: {
      Área: "area",
      "Nome Completo": "name",
      RG: "rg",
      CPF: "cpf",
      "Data de Nascimento": "birthdate",
      Rua: "street",
      Número: "street_number",
      Complemento: "complement",
      Bairro: "neighborhood",
      Comunidade: "community",
      CEP: "cep",
      Telefone: "cellphone_number",
      Whatsapp: "whatsapp",
      "Status Escolar": "educational_status",
      Ocupação: "busy",
      Parentesco: "relative_degree_kinship",
      "Parentesco - Nome Completo": "relative_name",
      "Parentesco - RG": "relative_rg",
      "Parentesco - CPF": "relative_cpf",
      "Parentesco - Telefone": "relative_cellphone_number",
      "Parentesco - Nascimento": "relative_birth_date",
      "Profissional Responsável pela Inscrição": "responsible_professional",
      "Data da Inscrição": "created_at",
      Devolutiva: "feedback",
      "Status Cadastral": "status",
      Equipamento: "equipment",
      "Nome Social": "social_name",
      Turma: "team_code",
      Frequência: "presence_percent",
      "Última Presença": "last_presence",
      "Cidade onde nasceu": "city_of_birth",
      "Estado Civil": "civil_state",
      Nis: "nis",
      Email: "email",
      Gênero: "gender",
      "Quais deficiências": "deficience",
      "Cor/raça": "race",
      "Quantidade de filhos": "number_sons",
      "Está Grávida": "is_pregnant",
      "Mês de Gestação": "month_pregnant",
      "Frequenta escola": "studying",
      "Última série concluída": "completed_series",
      Altura: "height",
      Peso: "weight",
      Blusa: "shirt_size",
      Calça: "pants_size",
      Tênis: "shoe_size1",
      "Encaminhamento Institucional": "institutional_referral",
      "Dados bancários - Banco": "bank_name",
      "Dados bancários - Agência": "agency",
      "Dados bancários - Conta": "account",
      "Dados bancários - Dígito": "account_digit",
      "Dados bancários - Tipo de conta": "account_type",
      "Feito contato": "contacted",
      "Data atendimento presencial": "date_face_to_face_service",
      "Cód. Turma Atual/Mais recente": "last_team",
      "Histórico[Cód. Turmas]": "all_teams",
      "Curso de Qualificação": "last_team_course",
      "Status de Qualificação": "qualification_status",
      "Encaminhamentos da Gestão": "forwarding_productive_by_area_management",
      "Encaminhamentos da Equipe de Área": "forwarding_productive_by_area_teams",
      "Efetivado a partir de encaminhamento?": "forwarding_productive_insertion_by_feedback_type",
      "Inserção Espontânea": "spontaneous_productive_insertion_count",
    },
    arrayData: new Array(),
    permitCreate: false,
    permit: false,
    permitUp: false,
    permitShowPhase: false,
    permitListPhase: false,
    go_back_url: "",
    type_edition: "",
    type_export: "",
  }),

  created() {
    this.backToTop();
    this.canAcces("phase_show").then((resp) => {
      this.permitShowPhase = resp;
    });
    this.canAcces("phase_index").then((resp) => {
      this.permitListPhase = resp;
    });
    this.canAcces("season_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("inscription_search").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("season_update").then((resp) => {
      this.permitUp = resp;
    });
    const season = { id: this.$route.params.id };
    this.$store.dispatch("Season/setSeason", season);
    if (!this.phase) {
      this.$store.dispatch("Season/setPartial", "inscriptions");
    }
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Season/loadDataSeason");
    this.$store.dispatch("Season/loadInscriptionsForGeneralMenus");
    this.$store.dispatch("Season/loadPhasesSeason");

    if (localStorage.getItem("season_date_manual_execution_end") !== "null") {
      this.go_back_url = "/edicoes/finalizada";
      this.type_edition = "EDIÇÕES ENCERRADAS";
    } else {
      this.go_back_url = "/edicoes/ativa";
      this.type_edition = "EDIÇÕES ATIVAS";
    }
    const user = JSON.parse(localStorage.userData);
    if (user.equipments.length > 0) {
      this.$store.dispatch("Season/setUserEquipments", user.equipments);
    }
  },

  computed: {
    ...mapState({
      showModal: (state) => state.Seasons.modalSeasonOpen,
      statusInscription: (state) => state.Season.filter.statusInscriptions,
      modalInscriptionOpen: (state) => state.ModalInscription.modalOpen,
      season: (state) => state.Season.season,
      amountInscriptionOpen: (state) => state.Season.amountInscriptionOpen,
      amountInscriptionUnderAnalysis: (state) => state.Season.amountInscriptionUnderAnalysis,
      amountInscriptionAccepted: (state) => state.Season.amountInscriptionAccepted,
      amountInscriptionNotAccepted: (state) => state.Season.amountInscriptionNotAccepted,
      amountInscriptionGraduate: (state) => state.Season.amountInscriptionGraduate,
      amountInscriptionArchived: (state) => state.Season.amountInscriptionArchived,
      offset: (state) => state.Season.offset,
      totalPerPage: (state) => state.Season.totalPerPage,
      bodyTable: (state) => state.Season.bodyTable,
      endSeasonConfirmation: (state) => state.Season.toggleEndSeasonConfirmation,
      reactivateSeasonConfirmation: (state) => state.Season.toggleReactivateSeasonConfirmation,
      areas: (state) => state.Areas.areas,
      area: (state) => state.Season.filter.area,
      name: (state) => state.Season.filter.name,
      cpf: (state) => state.Season.filter.cpf,
      rg: (state) => state.Season.filter.rg,
      has_cpf: (state) => state.Season.filter.has_cpf,
      has_rg: (state) => state.Season.filter.has_rg,
      has_bank_account: (state) => state.Season.filter.has_bank_account,
      neighborhood: (state) => state.Season.filter.neighborhood,
      equipment: (state) => state.Season.filter.equipment,
      city_filtered: (state) => state.Season.filter.city,
      inscription_origin_filtered: (state) => state.Season.filter.inscription_origin,
      inscriptionsForGeneralMenus: (state) => state.Season.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.Season.filter.inscription,
      inscriptionTotal: (state) => state.Season.inscriptionTotal,
      inscriptionsForSpreadsheet: (state) => state.Season.inscriptionsForSpreadsheet,
      phases: (state) => state.Season.phases,
      phase: (state) => state.Season.phase,
      partial: (state) => state.Season.partial,
      loading_data_season: (state) => state.Season.loading.data_season,
      city: (state) => state.City.city,
      allInscriptions: (state) => state.Season.allInscriptions,
      inscriptionsToExport: (state) => state.Season.inscriptionsToExport,
      user_areas: (state) => state.UtilFunctions.user_areas,
      user_equipments_filtered: (state) => state.Season.user_equipments_filtered,
      equipments: (state) => state.Season.equipments,
      positive_negative_options: (state) => state.UtilFunctions.positive_negative_options,
      modalInscriptionStudentOpen: (state) => state.Default.modalPreInscriptionOpen,
      showModalTransferStudent: (state) => state.Season.modalTransferStudentOpen,
      showModalRemoveStudentFromTeam: (state) => state.Season.modalRemoveStudentFromTeamOpen,
      showModalConfirmationRemoveStudent: (state) => state.Season.modalConfirmationRemoveStudent,
      modalSuspendMeetingsByTeamAndDateConfirmationOpen: (state) =>
        state.Meeting.modalSuspendMeetingsByTeamAndDateConfirmationOpen,
      modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen: (state) =>
        state.Meeting.modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen,

      hasSpreadsheetResponse: (state) => state.Season.hasSpreadsheetResponse,
      modalToggleExportSeasonSpreadsheet: (state) => state.Season.modalToggleExportSeasonSpreadsheet,
      json_data_inscriptions: (state) => state.Season.json_data,

      cities: (state) => state.UtilFunctions.cities,
      inscription_origin_options: (state) => state.UtilFunctions.inscription_origin_options,

      dataSpreadsheet: (state) => state.SelectFieldsToExport.dataSpreadsheet,
      modalToggleExport: (state) => state.SelectFieldsToExport.modalToggleExport,
      titleModal: (state) => state.SelectFieldsToExport.titleModal,
      dispatchToCloseModal: (state) => state.SelectFieldsToExport.dispatchToCloseModal,
      json_data: (state) => state.SelectFieldsToExport.json_data,
      sheetTabName: (state) => state.SelectFieldsToExport.sheetTabName,
      fileName: (state) => state.SelectFieldsToExport.fileName,
      variablePreloading: (state) => state.SelectFieldsToExport.variablePreloading,
      json_fields: (state) => state.SelectFieldsToExport.json_fields,
      dispatchOpenModal: (state) => state.SelectFieldsToExport.dispatchOpenModal,
    }),
    classButtonAssignment() {
      return {
        border: true,
        "shadow-md": true,
        "m-2": true,
        "rounded-full": true,
        "h-24": true,
        "w-24": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-ashes-hover": this.partial !== "inscriptions",
        "text-burnedYellow": this.partial === "inscriptions",
      };
    },
  },

  methods: {
    ...mapActions({
      setArea: "Season/setArea",
      setPartial: "Season/setPartial",
      setPhase: "Season/setPhase",
      filterListByName: "Season/filterListByName",
      filterListByEquipment: "Season/filterListByEquipment",
      filterListByHasCpf: "Season/filterListByHasCpf",
      filterListByHasRg: "Season/filterListByHasRg",
      filterListByHasBankAccount: "Season/filterListByHasBankAccount",
      filterListByCity: "Season/filterListByCity",
      filterListByInscriptionOrigin: "Season/filterListByInscriptionOrigin",
      clearFilter: "Season/clearFilter",
      canAcces: "AccessControl/canAcces",
      closeExportSeasonSpreadsheetModal: "Season/closeExportSeasonSpreadsheetModal",
    }),
    mountHeader() {
      return [
        { key: "status", label: "status" },
        // { key: "area", label: "área [automática]" },
        { key: "manual_area", label: "área" },
        { key: "equipment", label: "equipamento" },
        { key: "student_name", label: "nome" },
        { key: "team_code", label: "código da última turma" },
        { key: "percent", label: "frequência" },
        { key: "last_presence", label: "última presença" },
        // { key: "phone_number", label: "telefone" },
        // { key: "age", label: "idade" },
        // { key: "neighborhood", label: "bairro" },
        { key: "actionButton" },
        { key: "actionTransfer" },
        { key: "actionRemove" },
      ];
    },
    isActive(status) {
      return this.statusInscription.some((item) => item === status);
    },
    getNamesDisciplines(phase) {
      return phase.disciplines
        .filter((discipline) => discipline.name !== "Esporte ou Cultura")
        .map((discipline) => discipline.name)
        .join(" & ");
    },
    classButtonPhases(name) {
      return {
        "shadow-md": true,
        border: true,
        "rounded-full": true,
        "m-2": true,
        "text-ashes-hover": this.partial !== name,
        "text-burnedYellow": this.partial === name,
      };
    },
    clearFilter() {
      this.$store.dispatch("Season/clearFilter");
    },
    getLabelEquipmentFilter() {
      return this.area ? "Filtrar por Equipamento" : "Selecione a área para listar os equipamentos";
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel(export_type) {
      this.type_export = export_type;
      let modalOptions = {};
      switch (export_type) {
        case "inscriptions_spreadsheet":
          modalOptions = {
            title: "Exportar Planilha de Inscritos",
            closeModalDispatch: "Season/closeExportSeasonSpreadsheetModal",
            sheetTabName: "inscritos",
            fileName: "inscritos.xls",
            jsonFields: this.json_fields_inscriptions,
            openModalDispatch: "Season/loadDataSeasonToExport",
          };
          break;
      }
      if (modalOptions) {
        await this.$store.dispatch("SelectFieldsToExport/setTitleModal", modalOptions.title);
        await this.$store.dispatch(
          "SelectFieldsToExport/setDispatchToCloseModal",
          modalOptions.closeModalDispatch
        );
        await this.$store.dispatch("SelectFieldsToExport/setSheetTabName", modalOptions.sheetTabName);
        await this.$store.dispatch("SelectFieldsToExport/setFileName", modalOptions.fileName);
        await this.$store.dispatch("SelectFieldsToExport/setJsonFields", modalOptions.jsonFields);
        await this.$store.dispatch(
          "SelectFieldsToExport/setDispatchOpenModal",
          modalOptions.openModalDispatch
        );
        await this.$store.dispatch("SelectFieldsToExport/openModalSelectFieldsToExport");
      }
    },
  },
  watch: {
    area: function () {
      this.$store.dispatch("Season/loadEquipments");
    },
    statusInscription: function () {
      this.$store.dispatch("Season/loadDataSeason");
    },
    offset: function () {
      this.$store.dispatch("Season/loadDataSeason");
    },
    inscriptionsForSpreadsheet: {
      async handler(newValue) {
        if (this.type_export === "inscriptions_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setDataSpreadsheet", newValue);
        }
      },
      deep: true,
    },
    json_data_inscriptions: {
      async handler(newValue) {
        if (this.type_export === "inscriptions_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setJsonData", newValue);
        }
      },
      deep: true,
    },
    modalToggleExportSeasonSpreadsheet: async function () {
      if (this.type_export === "inscriptions_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setModalToggleExport",
          this.modalToggleExportSeasonSpreadsheet
        );
      }
    },
    hasSpreadsheetResponse: async function () {
      if (this.type_export === "inscriptions_spreadsheet") {
        await this.$store.dispatch("SelectFieldsToExport/setVariablePreloading", this.hasSpreadsheetResponse);
      }
    },
  },
};
</script>
