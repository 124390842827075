<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <HeaderComponent
        :headerText="
          psychosocial_accompaniment?.id
            ? 'Realizar Atendimento - Visita Domiciliar'
            : 'Adicionar Visita Domiciliar'
        "
        headerClass="text-ashes-dark"
        closeDispatch="PsychosocialAccompaniments/closeEditModalPsychosocialHomeVisit"
      />
    </template>
    <template v-slot:body>
      <AccompanimentInfo :accompanimentOrigin="accompaniment_origin" />
      <StudentInfo :inscription="inscription" />
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Dados cadastrais do encontro"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="birthdate"
            input-type="date"
            :value="psychosocial_accompaniment.date_accompaniment"
            show-label
            label-text="Data do Encontro"
            required
            dispatch="PsychosocialAccompaniments/setDateAccompaniment"
            :readonly="true"
          />
        </div>
        <div class="col-span-1">
          <AppRadioButton
            :values="[
              {
                name: 'has_meeting_happened',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment.meeting_happened === true,
              },
              {
                name: 'has_meeting_happened',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment.meeting_happened === false,
              },
            ]"
            labelText="Encontro aconteceu?"
            showLabel
            dispatch="PsychosocialAccompaniments/setMeetingHappened"
          />
        </div>
        <div v-if="!psychosocial_accompaniment.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Motivo do Encontro Não ter Ocorrido"
            label-text="Motivo do Encontro Não ter Ocorrido"
            :multiple-option="false"
            :value="meeting_not_happened_reason"
            :options="meeting_not_happened_reasons"
            :dispatch="setMeetingNotHappenedReason"
            required
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <BaseInput
            active
            input-name="psychosocial_accompaniment_meeting_place"
            input-type="text"
            :value="psychosocial_accompaniment.meeting_place"
            show-label
            label-text="Local da Visita Domiciliar"
            dispatch="PsychosocialAccompaniments/setMeetingPlace"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <BaseTextArea
            active
            inputName="purpose_home_visit"
            labelText="Objetivo da Visita Domiciliar"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment.purpose_home_visit"
            dispatch="PsychosocialAccompaniments/setPurposeHomeVisit"
          />
        </div>
      </div>
      <AttendantTeams />
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment.meeting_happened">
          <Label
            text="Participantes Presentes na Visitia Domiciliar"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'register_participant_presence',
                value: true,
                description: 'Sim',
                checked: register_participant_presence === true,
              },
              {
                name: 'register_participant_presence',
                value: false,
                description: 'Não',
                checked: register_participant_presence === false,
              },
            ]"
            labelText="Informar pessoas presentes na visita domiciliar?"
            showLabel
            dispatch="PsychosocialAccompaniments/setRegisterParticipantPresence"
          />
        </div>
        <div class="col-span-2" v-if="register_participant_presence">
          <div
            class="grid grid-cols-11 gap-4 mt-2"
            v-for="(participant, index) in participant_presence_attributes"
            :key="index"
          >
            <div class="col-span-5" v-if="psychosocial_accompaniment.meeting_happened">
              <Multiselect
                :close-on-select="true"
                show-label
                clear-on-select
                select-placeholder="Selecione a opção"
                label-text="Graus de Parentesco"
                :multiple-option="false"
                :value="relationship_degree_attributes[index]"
                :options="mountRelationshipDegrees(index)"
                required
                :dispatch="setRelationshipDegree"
              />
            </div>
            <div class="col-span-5" v-if="psychosocial_accompaniment.meeting_happened">
              <BaseInput
                active
                input-name="name"
                input-type="text"
                :value="participant_presence_attributes[index].name"
                show-label
                required
                :payload="{ index }"
                label-text="Nome"
                dispatch="PsychosocialAccompaniments/setParticipantPresenceName"
              />
            </div>
            <div
              class="mt-5"
              v-if="participant_presence_attributes.length > 1 && psychosocial_accompaniment.meeting_happened"
            >
              <a
                href="javascript:void(0)"
                class="text-burnedYellow underline"
                @click="deleteParticipantPresence"
              >
                <AppMaterialIcon icon-name="delete" icon-size="36" class="align-middle" />
              </a>
            </div>
          </div>
          <div
            class="col-span-4 mt-5"
            v-if="participant_presence_attributes.length > 1 && psychosocial_accompaniment.meeting_happened"
          >
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </div>
        </div>
        <div class="mt-5" v-if="psychosocial_accompaniment.meeting_happened && register_participant_presence">
          <a href="javascript:void(0)" class="text-burnedYellow" @click="addParticipantPresence">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
            <BaseText
              text="Adicionar Participante Presente na Visita Domiciliar"
              type-text="custom"
              weight="medium"
              size="sm"
              class="align-middle mr-2"
            />
          </a>
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment.meeting_happened">
          <Label
            text="Contexto familiar, socioeconômico e comunitário"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <button
            ref="btnSocialFamilyRef"
            v-on:mouseenter="toggleTooltip('btnSocialFamilyRef')"
            v-on:mouseleave="toggleTooltip('btnSocialFamilyRef')"
            class="uppercase rounded outline-none ease-linear transition-all duration-150"
          >
            <span class="material-icons mt-6 text-burnedYellow">
              <span class="material-icons"> info </span>
            </span>
          </button>
          <div
            ref="tooltipSocialFamilyRef"
            v-bind:class="{ hidden: !tooltipSocialFamilyShow, block: tooltipSocialFamilyShow }"
            class="bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
          >
            <div>
              <div
                class="text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
              >
                Informar com quem mora, como quantos membros da famílias, se reside no mesmo ambiente que suas
                referências familiares, se for adolescente, existe responsável de legal, sinalizar se o
                adolescente/filhos já têm filhos, caso sim quantos, reside com parceiras/ companheiras ou
                parceiros/ companheiros, apresenta vínculos familiares fragilizados e/ou rompidos.
              </div>
            </div>
          </div>
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <BaseTextArea
            active
            inputName="socio_economic_context"
            labelText="Contexto familiar, socioeconômico e comunitário"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment.social_family_context"
            dispatch="PsychosocialAccompaniments/setSocialFamilyContext"
          />
        </div>
      </div>
      <HousingSituation />
      <DisciplinarySanctions />
      <SchoolSituation />
      <GenderIdentity />
      <HealthSituation />
      <VulnerabilitySituation :psychosocial_accompaniment="psychosocial_accompaniment" />
      <ForwardingDetails :psychosocial_accompaniment="psychosocial_accompaniment" />
      <AccompanimentPlan :psychosocial_accompaniment="psychosocial_accompaniment" />
      <MeetingConclusion :psychosocial_accompaniment="psychosocial_accompaniment" />
      <ModalMatrixRiskStratifications :open="modalToggleMatrixRiskStratifications" />
      <SavePsychosocialAccompanimentWithoutMatrixConfirmation
        :open="modalConfirmSavePsychosocialAccompanimentWithoutMatrix"
      />
    </template>
    <template v-slot:footer>
      <ModalFooterActions
        closeDispatch="PsychosocialAccompaniments/closeEditModalPsychosocialHomeVisit"
        updateDispatch="PsychosocialAccompaniments/update"
        saveDispatch="PsychosocialAccompaniments/save"
      />
    </template>
  </BaseModal>
</template>

<script>
import AppRadioButton from "../../components/AppRadioButton.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import BaseTextArea from "../../components/forms/BaseTextArea.vue";
import Label from "../../components/Label.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import ModalMatrixRiskStratifications from "../ModalMatrixRiskStratifications.vue";
import SavePsychosocialAccompanimentWithoutMatrixConfirmation from "../alerts/SavePsychosocialAccompanimentWithoutMatrixConfirmation.vue";
import HeaderComponent from "@/views/psychosocialaccompanimentpages/components/HeaderComponent.vue";
import AccompanimentInfo from "@/views/psychosocialaccompanimentpages/components/AccompanimentInfo.vue";
import StudentInfo from "@/views/psychosocialaccompanimentpages/components/StudentInfo.vue";
import AttendantTeams from "@/views/psychosocialaccompanimentpages/components/AttendantTeams.vue";
import HousingSituation from "@/views/psychosocialaccompanimentpages/components/HousingSituation.vue";
import DisciplinarySanctions from "@/views/psychosocialaccompanimentpages/components/DisciplinarySanctions.vue";
import SchoolSituation from "@/views/psychosocialaccompanimentpages/components/SchoolSituation.vue";
import GenderIdentity from "@/views/psychosocialaccompanimentpages/components/GenderIdentity.vue";
import HealthSituation from "@/views/psychosocialaccompanimentpages/components/HealthSituation.vue";
import VulnerabilitySituation from "@/views/psychosocialaccompanimentpages/components/VulnerabilitySituation.vue";
import ForwardingDetails from "@/views/psychosocialaccompanimentpages/components/ForwardingDetails.vue";
import AccompanimentPlan from "@/views/psychosocialaccompanimentpages/components/AccompanimentPlan.vue";
import MeetingConclusion from "@/views/psychosocialaccompanimentpages/components/MeetingConclusion.vue";
import ModalFooterActions from "@/views/psychosocialaccompanimentpages/components/ModalFooterActions.vue";
import { createPopper } from "@popperjs/core";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    SchoolSituation,
    HealthSituation,
    DisciplinarySanctions,
    ForwardingDetails,
    GenderIdentity,
    MeetingConclusion,
    AttendantTeams,
    VulnerabilitySituation,
    AccompanimentPlan,
    HousingSituation,
    ModalFooterActions,
    AccompanimentInfo,
    StudentInfo,
    HeaderComponent,
    AppRadioButton,
    AppMaterialIcon,
    ModalMatrixRiskStratifications,
    SavePsychosocialAccompanimentWithoutMatrixConfirmation,
    BaseModal,
    BaseInput,
    BaseText,
    BaseTextArea,
    Label,
    Multiselect,
  },
  data() {
    return {
      input: 1,
      tooltipSocialFamilyShow: false,
      tooltipSocialEconomicShow: false,
      tooltipCommunityCoexistenceShow: false,
      tooltipHealthSituationObservations: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      meeting_not_happened_reasons: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reasons,
      meeting_not_happened_reason: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reason,
      accompaniment_origin: (state) => state.PsychosocialAccompaniments.accompaniment_origin,
      modalToggleMatrixRiskStratifications: (state) =>
        state.MatrixRiskStratifications.modalToggleMatrixRiskStratifications,
      register_participant_presence: (state) =>
        state.PsychosocialAccompaniments.register_participant_presence,
      relationship_degrees: (state) => state.PsychosocialAccompaniments.relationship_degrees,
      participant_presence_attributes: (state) =>
        state.PsychosocialAccompaniments.participant_presence_attributes,
      relationship_degree_attributes: (state) =>
        state.PsychosocialAccompaniments.relationship_degree_attributes,
      modalConfirmSavePsychosocialAccompanimentWithoutMatrix: (state) =>
        state.PsychosocialAccompaniments.modalConfirmSavePsychosocialAccompanimentWithoutMatrix,
    }),
  },
  methods: {
    ...mapActions({
      setMeetingNotHappenedReason: "PsychosocialAccompaniments/setMeetingNotHappenedReason",
      setRelationshipDegree: "PsychosocialAccompaniments/setRelationshipDegree",
    }),
    addParticipantPresence() {
      this.$store.dispatch("PsychosocialAccompaniments/addParticipantPresence");
    },
    deleteParticipantPresence() {
      this.$store.dispatch("PsychosocialAccompaniments/deleteParticipantPresence");
    },
    mountRelationshipDegrees(index) {
      return this.relationship_degrees.map((relationship_degree) => {
        return { index, ...relationship_degree };
      });
    },
    toggleTooltip: function (type) {
      switch (type) {
        case "btnSocialFamilyRef":
          if (this.tooltipSocialFamilyShow) {
            this.tooltipSocialFamilyShow = false;
          } else {
            this.tooltipSocialFamilyShow = true;
            createPopper(this.$refs.btnSocialFamilyRef, this.$refs.tooltipSocialFamilyRef, {
              placement: "left",
            });
          }
          break;
        case "btnSocialEconomicRef":
          if (this.tooltipSocialEconomicShow) {
            this.tooltipSocialEconomicShow = false;
          } else {
            this.tooltipSocialEconomicShow = true;
            createPopper(this.$refs.btnSocialEconomicRef, this.$refs.tooltipSocialEconomicRef, {
              placement: "left",
            });
          }
          break;
        case "btnCommunityCoexistenceRef":
          if (this.tooltipCommunityCoexistenceShow) {
            this.tooltipCommunityCoexistenceShow = false;
          } else {
            this.tooltipCommunityCoexistenceShow = true;
            createPopper(this.$refs.btnCommunityCoexistenceRef, this.$refs.tooltipCommunityCoexistenceRef, {
              placement: "left",
            });
          }
          break;
        case "btnHealthSituationObservationsRef":
          if (this.tooltipHealthSituationObservations) {
            this.tooltipHealthSituationObservations = false;
          } else {
            this.tooltipHealthSituationObservations = true;
            createPopper(
              this.$refs.btnHealthSituationObservationsRef,
              this.$refs.tooltipHealthSituationObservationsRef,
              {
                placement: "left",
              }
            );
          }
          break;
      }
    },
  },
};
</script>
<style>
.tooltip-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  z-index: 1;
  transition: opacity 0.3s;
}

.opacity-100 {
  visibility: visible;
  opacity: 1;
}
</style>
