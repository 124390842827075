<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Encaminhamento"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <AppRadioButton
        :values="forwardingOptions"
        labelText="Cadastrar Encaminhamento?"
        showLabel
        dispatch="PsychosocialAccompaniments/setRegisterForwarding"
      />
    </div>
    <div class="col-span-2" v-if="register_forwarding">
      <div
        class="grid grid-cols-2 gap-4 mt-2"
        v-for="(forwarding, index) in forwarding_attributes"
        :key="index"
      >
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione as opções"
            label-text="Políticas de Encaminhamento"
            :multiple-option="false"
            :value="forwarding_policy_attributes[index]"
            :options="mountForwardingPolicies(index)"
            required
            :dispatch="setForwardingPolicy"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione as opções"
            label-text="Equipamentos"
            :multiple-option="false"
            :value="forwarding_facilities_attributes[index]"
            :options="mountForwardingFacilities(index)"
            required
            :dispatch="setForwardingFacility"
          />
        </div>
        <div class="col-span-2 text-burnedYellow-classic pb-2">
          <Label
            text="Informações de Data"
            :icon="null"
            :icon-type="null"
            type="text-xs"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <BaseInput
            active
            input-name="expedition"
            input-type="date"
            :value="forwarding_attributes[index].forwarding_date"
            show-label
            required
            label-text="Data do Encaminhamento"
            :payload="{ index }"
            dispatch="PsychosocialAccompaniments/setForwardingDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="expedition"
            input-type="date"
            :value="forwarding_attributes[index].forwarding_expiration_date"
            show-label
            required
            :payload="{ index }"
            label-text="Data de Expiração"
            dispatch="PsychosocialAccompaniments/setForwardingExpirationDate"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="dif"
            input-type="number"
            :value="forwarding_attributes[index].differenceBetweenDates"
            :payload="{ index }"
            show-label
            label-text="Total de Dias entre as Datas"
            dispatch="PsychosocialAccompaniments/setForwardingDifferenceBetweenDates"
          />
        </div>
        <div class="col-span-1">
          <AppTextField
            show-label
            label-text="Data nominal de Expiração"
            :text="getFullDate(forwarding_attributes[index].forwarding_expiration_date)"
            color="carmesim"
            type-text="custom"
            weight="semibold"
          />
        </div>
        <div class="col-span-2 text-burnedYellow-classic pb-2">
          <Label
            text="Descrição do Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-xs"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            inputName="description"
            labelText="Descrição"
            showLabel
            rows="4"
            :value="forwarding_attributes[index].forwarding_description"
            :payload="{ index }"
            dispatch="PsychosocialAccompaniments/setForwardingDescription"
          />
          <div class="mt-5" v-if="forwarding_attributes.length > 1 && register_forwarding">
            <a href="javascript:void(0)" class="text-burnedYellow underline" @click="deleteForwarding">
              <AppMaterialIcon icon-name="delete" icon-size="36" class="align-middle" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-span-4 mt-5" v-if="forwarding_attributes.length > 1 && register_forwarding">
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>
    <div class="mt-5" v-if="register_forwarding">
      <a href="javascript:void(0)" class="text-burnedYellow" @click="addForwarding">
        <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
        <BaseText
          text="Adicionar Encaminhamento"
          type-text="custom"
          weight="medium"
          size="sm"
          class="align-middle mr-2"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppRadioButton from "@/components/AppRadioButton.vue";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";
import AppTextField from "@/components/AppTextField.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseTextArea from "@/components/forms/BaseTextArea.vue";
import Label from "@/components/Label.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import BaseText from "@/components/BaseText.vue";

export default {
  name: "ForwardingDetails",
  components: {
    AppRadioButton,
    AppMaterialIcon,
    AppTextField,
    BaseInput,
    BaseTextArea,
    Label,
    Multiselect,
    BaseText,
  },
  props: {
    psychosocial_accompaniment: Object,
  },
  computed: {
    ...mapState({
      register_forwarding: (state) => state.PsychosocialAccompaniments.register_forwarding,
      forwarding_attributes: (state) => state.PsychosocialAccompaniments.forwarding_attributes,
      forwarding_policy_attributes: (state) => state.PsychosocialAccompaniments.forwarding_policy_attributes,
      forwarding_facilities_attributes: (state) =>
        state.PsychosocialAccompaniments.forwarding_facilities_attributes,
      facilities: (state) => state.PsychosocialAccompaniments.facilities,
      forwarding_policies: (state) => state.Forwardings.forwarding_policies,
    }),
    forwardingOptions() {
      return [
        {
          name: "register_forwarding",
          value: true,
          description: "Sim",
          checked: this.register_forwarding === true,
        },
        {
          name: "register_forwarding",
          value: false,
          description: "Não",
          checked: this.register_forwarding === false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setForwardingPolicy: "PsychosocialAccompaniments/setForwardingPolicy",
      setForwardingFacility: "PsychosocialAccompaniments/setForwardingFacility",
      addForwarding: "PsychosocialAccompaniments/addForwarding",
      deleteForwarding: "PsychosocialAccompaniments/deleteForwarding",
    }),
    mountForwardingPolicies(index) {
      return this.forwarding_policies.map((forwarding_policy) => {
        return { index, ...forwarding_policy };
      });
    },
    mountForwardingFacilities(index) {
      let response = [];
      this.facilities.forEach((item, i) => {
        if (index === i) {
          if (item.facilities) {
            item.facilities.map((facility) => {
              response.push({ index, ...facility });
            });
          }
        }
      });
      return response;
    },
    getFullDate(date) {
      return new Date(date).toLocaleDateString("pt-BR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>
