<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Situação de Saúde"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-1" v-for="(radio, index) in healthRadios" :key="index">
      <AppRadioButton
        :values="radio.values"
        :labelText="radio.labelText"
        showLabel
        :dispatch="radio.dispatch"
      />
    </div>
    <div class="col-span-1">
      <BaseInput
        active
        input-name="gestational_age"
        input-type="number"
        :value="psychosocial_accompaniment?.gestational_age"
        show-label
        label-text="Idade Gestacional"
        dispatch="PsychosocialAccompaniments/setGestationalAge"
        inputMin="0"
        :readonly="!psychosocial_accompaniment?.is_pregnant"
      />
    </div>
    <div class="col-span-2">
      <BaseInput
        active
        input-name="health_place_frequently"
        input-type="text"
        :value="psychosocial_accompaniment?.health_place_frequently"
        show-label
        label-text="Local de atendimento"
        dispatch="PsychosocialAccompaniments/setHealthPlaceFrequently"
        :readonly="!psychosocial_accompaniment?.frequently_uses_health_network"
      />
    </div>
    <div class="col-span-2">
      <BaseTextArea
        active
        inputName="motivation_uses_health_frequently"
        labelText="Motivo do atendimento"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.motivation_uses_health_frequently"
        dispatch="PsychosocialAccompaniments/setMotivationUsesHealthFrquently"
        :readonly="!psychosocial_accompaniment?.frequently_uses_health_network"
      />
    </div>
    <div class="col-span-2 mt-0">
      <div class="flex">
        <BaseLabel text="Observações" class="flex items-end" />
        <button
          ref="btnHealthSituationObservationsRef"
          v-on:mouseenter="toggleTooltip('btnHealthSituationObservationsRef')"
          v-on:mouseleave="toggleTooltip('btnHealthSituationObservationsRef')"
          class="uppercase rounded outline-none ease-linear transition-all duration-150"
        >
          <span class="material-icons mt-6 text-burnedYellow">
            <span class="material-icons">info</span>
          </span>
        </button>
      </div>
      <div
        ref="tooltipHealthSituationObservationsRef"
        v-bind:class="{
          hidden: !tooltipHealthSituationObservations,
          block: tooltipHealthSituationObservations,
        }"
        class="bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
      >
        <div>
          <div
            class="text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
          >
            Utilizar este espaço para detalhar a situação de saúde apreendida, se faz tratamento, uso de
            medicações, etc.
          </div>
        </div>
      </div>
      <BaseTextArea
        active
        inputName="health_situation_observations"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.health_situation_observations"
        dispatch="PsychosocialAccompaniments/setHealthSituationObservations"
      />
    </div>
  </div>
</template>

<script>
import AppRadioButton from "@/components/AppRadioButton.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseTextArea from "@/components/forms/BaseTextArea.vue";
import BaseLabel from "@/components/BaseLabel.vue";
import Label from "@/components/Label.vue";
import { mapState } from "vuex";
import { createPopper } from "@popperjs/core";

export default {
  components: {
    AppRadioButton,
    BaseInput,
    BaseTextArea,
    BaseLabel,
    Label,
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
    }),
    healthRadios() {
      return [
        {
          values: [
            {
              name: "is_pregnant",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.is_pregnant === true,
            },
            {
              name: "is_pregnant",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.is_pregnant === false,
            },
          ],
          labelText: "Apresenta-se gestante?",
          dispatch: "PsychosocialAccompaniments/setIsPregnant",
        },
        {
          values: [
            {
              name: "is_puerperium",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.is_puerperium === true,
            },
            {
              name: "is_puerperium",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.is_puerperium === false,
            },
          ],
          labelText: "Período Puerpério",
          dispatch: "PsychosocialAccompaniments/setIsPuerperium",
        },
        {
          values: [
            {
              name: "uses_psychoactive_substances",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.uses_psychoactive_substances === true,
            },
            {
              name: "uses_psychoactive_substances",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.uses_psychoactive_substances === false,
            },
          ],
          labelText: "Faz uso de substâncias psicoativas",
          dispatch: "PsychosocialAccompaniments/setUsesPsychoactiveSubstances",
        },
        {
          values: [
            {
              name: "uses_psychoactive_substances_recreationally",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.uses_psychoactive_substances_recreationally === true,
            },
            {
              name: "uses_psychoactive_substances_recreationally",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.uses_psychoactive_substances_recreationally === false,
            },
          ],
          labelText: "Faz uso recreativo de substâncias psicoativas",
          dispatch: "PsychosocialAccompaniments/setUsesPsychoactiveSubstancesRecreationally",
        },
        {
          values: [
            {
              name: "self_medicate",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.self_medicate === true,
            },
            {
              name: "self_medicate",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.self_medicate === false,
            },
          ],
          labelText: "Se automedica",
          dispatch: "PsychosocialAccompaniments/setSelfMedicate",
        },
        {
          values: [
            {
              name: "show_signs_of_self_harm",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.show_signs_of_self_harm === true,
            },
            {
              name: "show_signs_of_self_harm",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.show_signs_of_self_harm === false,
            },
          ],
          labelText: "Apresenta indícios de violência autoprovocada",
          dispatch: "PsychosocialAccompaniments/setShowSignsOfSelfHarm",
        },
        {
          values: [
            {
              name: "show_signs_of_compromised_physical_health",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.show_signs_of_compromised_physical_health === true,
            },
            {
              name: "show_signs_of_compromised_physical_health",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.show_signs_of_compromised_physical_health === false,
            },
          ],
          labelText: "Apresenta indícios de saúde física comprometida",
          dispatch: "PsychosocialAccompaniments/setShowSignsOfCompromisedPhysicalHealth",
        },
        {
          values: [
            {
              name: "show_signs_of_compromised_mental_health",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.show_signs_of_compromised_mental_health === true,
            },
            {
              name: "show_signs_of_compromised_mental_health",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.show_signs_of_compromised_mental_health === false,
            },
          ],
          labelText: "Apresenta indícios de saúde mental comprometida",
          dispatch: "PsychosocialAccompaniments/setShowSignsOfCompromisedMentalHealth",
        },
        {
          values: [
            {
              name: "frequently_uses_health_network",
              value: true,
              description: "Sim",
              checked: this.psychosocial_accompaniment?.frequently_uses_health_network === true,
            },
            {
              name: "frequently_uses_health_network",
              value: false,
              description: "Não",
              checked: this.psychosocial_accompaniment?.frequently_uses_health_network === false,
            },
          ],
          labelText: "Realiza com frequência atendimentos ou consultas na rede de saúde?",
          dispatch: "PsychosocialAccompaniments/setFrequentlyUsesHealthNetwork",
        },
      ];
    },
  },
  data() {
    return {
      tooltipHealthSituationObservations: false,
    };
  },
  methods: {
    toggleTooltip(type) {
      if (type === "btnHealthSituationObservationsRef") {
        this.tooltipHealthSituationObservations = !this.tooltipHealthSituationObservations;
        if (this.tooltipHealthSituationObservations) {
          createPopper(
            this.$refs.btnHealthSituationObservationsRef,
            this.$refs.tooltipHealthSituationObservationsRef,
            {
              placement: "left",
            }
          );
        }
      }
    },
  },
};
</script>
