<template>
  <div class="container mx-auto p-6">
    <!-- Student Data Section -->
    <section class="mb-12">
      <header class="mb-4">
        <BaseText
          text="Dados do Aluno(a)"
          type-text="custom"
          weight="semibold"
          :color="getStyles('headerColor')"
          size="lg"
        />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </header>
      <div class="grid grid-cols-5 gap-4">
        <div class="col-span-2" v-if="inscription">
          <AppTextField
            show-label
            label-text="Nome do Aluno(a)"
            :text="inscription?.student?.name"
            type-text="topic-details"
          />
        </div>
        <div v-if="inscription">
          <AppTextField
            show-label
            label-text="CPF"
            :text="inscription?.student?.cpf | VMask('###.###.###-##')"
            type-text="topic-details"
          />
        </div>
        <div v-if="inscription">
          <AppTextField
            show-label
            label-text="Data de Nascimento"
            :text="formattedDate(inscription?.student?.birthdate)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="inscription">
          <AppTextField
            show-label
            label-text="Email"
            :text="inscription?.student?.email"
            type-text="topic-details"
          />
        </div>
        <div v-if="inscription">
          <AppTextField
            show-label
            label-text="Telefone"
            :text="inscription?.student?.cellphone_number | VMask('(##) #####-####')"
            type-text="topic-details"
          />
        </div>
      </div>
    </section>

    <!-- Psychosocial Data Section -->
    <section class="mb-12">
      <header class="mb-4">
        <BaseText
          text="Dados do Acompanhamento"
          type-text="custom"
          weight="semibold"
          :color="getStyles('headerColor')"
          size="lg"
        />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </header>
      <div class="grid grid-cols-8 gap-4">
        <div :class="[{ active_new: generalData }, 'tab_new', 'col-span-1']">
          <a href="javascript:void(0)" @click="openTab('generalData')">
            <BaseText
              text="Dados gerais"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: socialContext }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened && accompaniments_type?.id === 2"
        >
          <a href="javascript:void(0)" @click="openTab('socialContext')">
            <BaseText
              text="Contextos Sociais"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: housingData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('housingData')">
            <BaseText
              text="Moradia"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: disciplinarySanctionData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('disciplinarySanctionData')">
            <BaseText
              text="Sanção Disciplinar"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: studyingData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('studyingData')">
            <BaseText
              text="Situação Escolar"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: genderIdentityData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('genderIdentityData')">
            <BaseText
              text="Identid. de Gênero"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: healthData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('healthData')">
            <BaseText
              text="Saúde"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: forwardingData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment?.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('forwardingData')">
            <BaseText
              text="Encaminhamento"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div
          :class="[{ active_new: accompanimentPlanData }, 'tab_new', 'col-span-1']"
          v-if="psychosocial_accompaniment.meeting_happened"
        >
          <a href="javascript:void(0)" @click="openTab('accompanimentPlanData')">
            <BaseText
              text="Acompanhamento"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
        <div :class="[{ active_new: conclusionData }, 'tab_new', 'col-span-1']">
          <a href="javascript:void(0)" @click="openTab('conclusionData')">
            <BaseText
              text="Conclusão"
              type-text="custom"
              weight="medium"
              :color="getStyles('colorText')"
              mode="uppercase"
              size="sm"
            />
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </a>
        </div>
      </div>
    </section>

    <!-- General Data Section -->
    <section v-if="generalData" class="mb-12">
      <div class="grid grid-cols-5 gap-4">
        <div v-if="generalData">
          <AppTextField
            show-label
            label-text="Data"
            :text="formattedDate(psychosocial_accompaniment.date_accompaniment)"
            type-text="topic-details"
          />
        </div>
        <div v-if="generalData">
          <AppTextField
            show-label
            label-text="Tipo do Atendimento"
            :text="accompaniments_type.name"
            type-text="topic-details"
          />
        </div>
        <div v-if="generalData">
          <AppTextField
            show-label
            label-text="Status do Acompanhamento"
            :text="accompaniment_status.name"
            type-text="topic-details"
          />
        </div>
        <div v-if="generalData">
          <AppTextField
            show-label
            label-text="Encontro aconteceu?"
            :text="verifyMeetingHappened()"
            type-text="topic-details"
          />
        </div>
        <div
          v-if="generalData && psychosocial_accompaniment.meeting_happened && accompaniments_type.id !== 2"
        >
          <AppTextField
            show-label
            label-text="Formato do Encontro"
            :text="psychosocial_accompaniment.meeting_format"
            type-text="topic-details"
          />
        </div>
        <div
          v-if="
            (generalData &&
              psychosocial_accompaniment.meeting_happened &&
              psychosocial_accompaniment.meeting_format === 'Presencial') ||
            accompaniments_type.id === 2
          "
        >
          <AppTextField
            show-label
            :label-text="getLocalName()"
            :text="getMeetingPlace()"
            type-text="topic-details"
          />
        </div>
        <div
          v-if="
            generalData &&
            psychosocial_accompaniment.meeting_happened &&
            psychosocial_accompaniment.meeting_format === 'Remoto' &&
            accompaniments_type.id !== 2
          "
        >
          <AppTextField
            show-label
            label-text="Recurso do Encontro Remoto"
            :text="getMeetingRemoteResource()"
            type-text="topic-details"
          />
        </div>
        <div
          class="col-span-5"
          v-if="generalData && psychosocial_accompaniment.meeting_happened && accompaniments_type.id === 2"
        >
          <AppTextField
            show-label
            label-text="Objetivo da Visita Familiar"
            :text="getPurposeHomeVisit()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
        <div class="col-span-5" v-if="generalData && psychosocial_accompaniment.meeting_happened">
          <BaseText
            text="Profissionais que acompanham"
            type-text="custom"
            weight="medium"
            :color="getStyles('colorText')"
            mode="uppercase"
            size="sm"
          />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
        <div
          class="col-span-5"
          id="attendant-team-data"
          v-if="generalData && psychosocial_accompaniment.meeting_happened && attendant_teams.length > 0"
        >
          <div
            :key="item.id"
            :data-index="index"
            v-for="(item, index) in attendant_teams"
            class="grid grid-cols-5 gap-x-1 mb-6"
          >
            <div class="col-span-2">
              <AppTextField
                show-label
                label-text="Nome do Profissional"
                :text="item.name"
                type-text="topic-details"
              />
            </div>
            <div>
              <AppTextField
                show-label
                label-text="Código CRESS ou CRP"
                :text="item.number_cress_crp"
                type-text="topic-details"
              />
            </div>
            <div class="col-span-5">
              <hr class="border-t-0 border-b-2 border-ashes opacity-50 col-span-5" />
            </div>
          </div>
        </div>
        <div v-if="generalData && psychosocial_accompaniment.meeting_happened && attendant_teams.length <= 0">
          <div class="col-span-5">
            <AppTextField show-label label-text="Não Informado." type-text="topic-details" />
          </div>
        </div>

        <div
          class="col-span-5"
          v-if="generalData && psychosocial_accompaniment.meeting_happened && accompaniments_type.id === 2"
        >
          <BaseText
            text="Participantes Presentes"
            type-text="custom"
            weight="medium"
            :color="getStyles('colorText')"
            mode="uppercase"
            size="sm"
          />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
        <div
          class="col-span-5"
          id="participant-presence-data"
          v-if="
            generalData &&
            psychosocial_accompaniment.meeting_happened &&
            participant_presences.length > 0 &&
            accompaniments_type.id === 2
          "
        >
          <div
            :key="item.id"
            :data-index="index"
            v-for="(item, index) in participant_presences"
            class="grid grid-cols-5 gap-x-1 mb-6"
          >
            <div class="col-span-2">
              <AppTextField
                show-label
                label-text="Nome da Pessoa"
                :text="item.name"
                type-text="topic-details"
              />
            </div>
            <div>
              <AppTextField
                show-label
                label-text="Grau de Parentesco"
                :text="item.relationship_degree.name"
                type-text="topic-details"
              />
            </div>
            <div class="col-span-5">
              <hr class="border-t-0 border-b-2 border-ashes opacity-50 col-span-5" />
            </div>
          </div>
        </div>
        <div
          v-if="
            generalData &&
            psychosocial_accompaniment.meeting_happened &&
            participant_presences.length <= 0 &&
            accompaniments_type.id === 2
          "
        >
          <div class="col-span-5">
            <AppTextField show-label label-text="Não Informado." type-text="topic-details" />
          </div>
        </div>

        <div class="col-span-5" v-if="generalData && !psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Motivo do encontro não ter ocorrido"
            :text="getMeetingNotHappenedReason()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
        <div class="col-span-5" v-if="generalData && accompaniment_origin">
          <BaseText
            text="Informações de Atendimento de Origem"
            type-text="custom"
            weight="medium"
            :color="getStyles('colorText')"
            mode="uppercase"
            size="sm"
          />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
        <div v-if="generalData && accompaniment_origin">
          <AppTextField
            show-label
            label-text="Data do Encontro"
            :text="formattedDate(accompaniment_origin.date_accompaniment)"
            type-text="topic-details"
          />
        </div>
        <div v-if="generalData && accompaniment_origin">
          <AppTextField
            show-label
            label-text="Tipo do Atendimento"
            :text="accompaniment_origin.accompaniment_type.name"
            type-text="topic-details"
          />
        </div>
        <div v-if="generalData && accompaniment_origin">
          <AppTextField
            show-label
            label-text="Status do Acompanhamento"
            :text="accompaniment_origin.accompaniment_status.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="generalData && accompaniment_origin">
          <AppTextField
            show-label
            label-text="Usuário responsável pelo cadastro"
            :text="accompaniment_origin.user.name"
            type-text="topic-details"
          />
        </div>
      </div>
    </section>

    <!-- Social Context Section -->
    <section id="social-context" class="mb-12" v-if="socialContext">
      <div class="grid grid-cols-5 gap-4">
        <div class="col-span-5" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Contexto familiar, socioeconômico e comunitário"
            :text="getSocialFamilyContext()"
            type-text="topic-details"
          />
        </div>
      </div>
    </section>

    <!-- Housing Data Section -->
    <section id="housing-data" class="mb-12" v-if="housingData">
      <div class="grid grid-cols-5 gap-4">
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Situação de Moradia"
            :text="getHousingSituationName()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Quantidade de Residentes"
            :text="getNumberOfResidents()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Tem saneamento básico?"
            :text="verifyIfHasLatrine()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Tem banheiro?"
            :text="verifyIfHasBathroom()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Tem coleta de lixo?"
            :text="verifyIfHasGarbageCollection()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Situação do Imóvel"
            :text="getHousingBuildingSituationName()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Estrutura do Imóvel"
            :text="getHousingBuildingMaterialName()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Fornecimento de Energia"
            :text="getHousingEletricityName()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Fornecimento de Água"
            :text="getHousingWaterSupplyName()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Fornecimento de Gás"
            :text="getHousingGasSupplyName()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-5" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Comentários sobre situação de moradia"
            :text="getHousingSituationComments()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
      </div>
    </section>

    <!-- Disciplinary Sanction Data Section -->
    <section
      id="disciplinary-sanction-data"
      v-if="disciplinarySanctionData && psychosocial_accompaniment.meeting_happened"
    >
      <div
        class="grid grid-cols-5 gap-4 mb-12"
        v-for="(item, index) in visibleDisciplinarySanctions"
        :key="index"
      >
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Sanção"
            :text="item.sanction_type?.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-4">
          <AppTextField
            show-label
            label-text="Status do Cumprimento"
            :text="item.sanction_status?.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="mustShowDisciplinaryPenaltys(index)">
          <AppTextField
            show-label
            label-text="Pena"
            :text="item.penalty_type?.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-4" v-if="mustShowDisciplinaryMeasures(index)">
          <AppTextField
            show-label
            label-text="Medida"
            :text="visibleDisciplinarySanctionsMeasures(index)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="mustShowDisciplinaryComplianceModes(index)">
          <AppTextField
            show-label
            label-text="Meio"
            :text="item.compliance_mode?.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="mustShowDisciplinaryCompletionTimes(index)">
          <AppTextField
            show-label
            label-text="Tempo desde a Finalização"
            :text="item.completion_time?.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="mustShowDisciplinaryCompletionForecasts(index)">
          <AppTextField
            show-label
            label-text="Previsão de Finalização"
            :text="item.completion_forecast?.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="mustShowDisciplinaryComplianceLocation(index)">
          <AppTextField
            show-label
            label-text="Local de Cumprimento"
            :text="item.compliance_location"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-5">
          <hr class="border-t-0 border-b-2 border-ashes opacity-50 col-span-5" />
        </div>
      </div>
    </section>

    <!-- Studying Data Section -->
    <section
      id="studying-data"
      class="mb-12"
      v-if="studyingData && psychosocial_accompaniment.meeting_happened"
    >
      <div class="grid grid-cols-5 gap-4">
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Está estudando?"
            :text="verifyIfIsStudying()"
            type-text="topic-details"
          />
        </div>
        <div
          class="col-span-5"
          v-if="psychosocial_accompaniment.meeting_happened && !psychosocial_accompaniment.is_studying"
        >
          <AppTextField
            show-label
            label-text="Razão para não estar estudando"
            :text="getReasonForNotStudying()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
        <div
          class="col-span-2"
          v-if="psychosocial_accompaniment.meeting_happened && !psychosocial_accompaniment.is_studying"
        >
          <AppTextField
            show-label
            label-text="Última série estudada"
            :text="getLastGradeStudied()"
            type-text="topic-details"
          />
        </div>
        <div
          class="col-span-2"
          v-if="psychosocial_accompaniment.meeting_happened && !psychosocial_accompaniment.is_studying"
        >
          <AppTextField
            show-label
            label-text="Última escola em que estudou"
            :text="getLastSchoolStudied()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Necessita de Reinserção Escolar?"
            :text="verifyIfNeedsSchoolReinsertion()"
            type-text="topic-details"
          />
        </div>
      </div>
    </section>

    <!-- Gender Identity Data Section -->
    <section
      id="gender-identity-data"
      class="mb-12"
      v-if="genderIdentityData && psychosocial_accompaniment.meeting_happened"
    >
      <div class="grid grid-cols-5 gap-4">
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField show-label label-text="Gênero" :text="getGender()" type-text="topic-details" />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Se identifica como LGBTQIAPN+"
            :text="verifyIfIdentifiesAsLgbtqiapn()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-5" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Observações"
            :text="getGenderIdentityObservations()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
      </div>
    </section>

    <!-- Health Data Section -->
    <section id="health-data" class="mb-12" v-if="healthData && psychosocial_accompaniment.meeting_happened">
      <div class="grid grid-cols-5 gap-4">
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Apresenta-se gestante?"
            :text="verifyIfIsPregnant()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened && psychosocial_accompaniment.is_pregnant">
          <AppTextField
            show-label
            label-text="Idade Gestacional"
            :text="psychosocial_accompaniment.gestational_age"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Período Puerpério"
            :text="verifyIfIsPuerperium()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Faz uso de substâncias psicoativas"
            :text="verifyIfUsesPsychoactiveSubstances()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Faz uso recreativo de substâncias psicoativas"
            :text="verifyIfUsesPsychoactiveSubstancesRecreationally()"
            type-text="topic-details"
          />
        </div>
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Se automedica"
            :text="verifyIfSelfMedicate()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Apresenta indícios de violência autoprovocada"
            :text="verifyIfShowSignsOfSelfHarm()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Apresenta indícios de saúde física comprometida"
            :text="verifyIfShowSignsOfCompromisedPhysicalHealth()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Apresenta indícios de saúde mental comprometida"
            :text="verifyIfShowSignsOfCompromisedMentalHealth()"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-3" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Realiza com frequência atendimentos ou consultas na rede de saúde?"
            :text="verifyIfIsFrequentlyUserHealthNetwork()"
            type-text="topic-details"
          />
        </div>
        <div
          v-if="
            psychosocial_accompaniment.meeting_happened &&
            psychosocial_accompaniment.frequently_uses_health_network
          "
        >
          <AppTextField
            show-label
            label-text="Local de atendimento"
            :text="getMotivationHealthPlaceFrequently()"
            type-text="topic-details"
          />
        </div>
        <div
          class="col-span-5"
          v-if="
            psychosocial_accompaniment.meeting_happened &&
            psychosocial_accompaniment.frequently_uses_health_network
          "
        >
          <AppTextField
            show-label
            label-text="Motivo do atendimento"
            :text="getMotivationUsesHealthFrequently()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
        <div class="col-span-5" v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Observações"
            :text="getHealthSituationObservations()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
      </div>
    </section>

    <!-- Forwarding Data Section -->
    <section id="forwarding-data" v-if="forwardingData && psychosocial_accompaniment.meeting_happened">
      <div
        :key="item.id"
        :data-index="index"
        v-for="(item, index) in forwarding"
        class="grid grid-cols-5 gap-4 mb-12"
      >
        <div>
          <AppTextField
            show-label
            label-text="Data do Encaminhamento"
            :text="formattedDate(item.forwarding_date)"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de Expiração"
            :text="formattedDate(item.forwarding_expiration_date)"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Política de Encaminhamento"
            :text="item.forwarding_policy.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-4">
          <AppTextField
            show-label
            label-text="Equipamento do Encaminhamento"
            :text="item.facility.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-4">
          <AppTextField
            show-label
            label-text="Endereço do Equipamento"
            :text="item.facility.address"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-5">
          <AppTextField
            show-label
            label-text="Descrição"
            :text="item.forwarding_description"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
        <div class="col-span-5">
          <hr class="border-t-0 border-b-2 border-ashes opacity-50 col-span-5" />
        </div>
      </div>
    </section>

    <!-- Accompaniment Plan Data Section -->
    <section
      id="accompaniment-plan-data"
      class="mb-12"
      v-if="accompanimentPlanData & psychosocial_accompaniment.meeting_happened"
    >
      <div class="grid grid-cols-5 gap-4">
        <div v-if="psychosocial_accompaniment.meeting_happened">
          <AppTextField
            show-label
            label-text="Acompanhamento vai continuar?"
            :text="verifyIfAccompanimentWillContinue()"
            type-text="topic-details"
          />
        </div>
      </div>
    </section>

    <!-- Conclusion Data Section -->
    <section id="conclusion-data" class="mb-12" v-if="conclusionData">
      <div class="grid grid-cols-5 gap-4">
        <div class="col-span-5">
          <AppTextField
            show-label
            label-text="Situação apresentada"
            :text="getMeetingConclusionDescription()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
        <div class="col-span-5">
          <AppTextField
            show-label
            label-text="Observações técnicas sobre o acompanhamento"
            :text="getAccompanimentComments()"
            type-text="topic-details"
            classe="break-words"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  data: () => ({
    status: "",
    generalData: true,
    housingData: false,
    disciplinarySanctionData: false,
    studyingData: false,
    genderIdentityData: false,
    healthData: false,
    forwardingData: false,
    accompanimentPlanData: false,
    conclusionData: false,
    socialContext: false,
  }),
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      disciplinary_sanctions_attributes: (state) =>
        state.PsychosocialAccompaniments.disciplinary_sanctions_attributes,
      forwarding: (state) => state.PsychosocialAccompaniments.forwardings,
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      accompaniments_type: (state) => state.PsychosocialAccompaniments.accompaniments_type,
      accompaniment_status: (state) => state.PsychosocialAccompaniments.accompaniment_status,
      housing_situation: (state) => state.PsychosocialAccompaniments.housing_situation,
      housing_building_situation: (state) => state.PsychosocialAccompaniments.housing_building_situation,
      housing_building_material: (state) => state.PsychosocialAccompaniments.housing_building_material,
      housing_has_electricity: (state) => state.PsychosocialAccompaniments.housing_has_electricity,
      housing_has_water_supply: (state) => state.PsychosocialAccompaniments.housing_has_water_supply,
      housing_has_gas_supply: (state) => state.PsychosocialAccompaniments.housing_has_gas_supply,
      housing_bathroom_drain_type: (state) => state.PsychosocialAccompaniments.housing_bathroom_drain_type,
      meeting_not_happened_reason: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reason,
      accompaniment_origin: (state) => state.PsychosocialAccompaniments.accompaniment_origin,
      socio_educational_measure_situation: (state) =>
        state.PsychosocialAccompaniments.socio_educational_measure_situation,
      sentence_serving_situation: (state) => state.PsychosocialAccompaniments.sentence_serving_situation,
      attendant_teams: (state) => state.PsychosocialAccompaniments.attendant_teams,
      participant_presences: (state) => state.PsychosocialAccompaniments.participant_presences,
      gender: (state) => state.PsychosocialAccompaniments.gender,
      identifies_as_lgbtqiapn: (state) => state.PsychosocialAccompaniments.identifies_as_lgbtqiapn,
    }),
    visibleDisciplinarySanctions() {
      return this.disciplinary_sanctions_attributes.filter(
        (disciplinary_sanction) => !disciplinary_sanction._destroy
      );
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyEmail() {
      return this.inscription.student.email ? this.inscription.student.email : "Email não informado";
    },
    verifyEquipment() {
      return this.inscription.student.equipment
        ? this.inscription.student.equipment.name
        : "Equipamento não cadastrado";
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyMeetingHappened() {
      return this.psychosocial_accompaniment.meeting_happened ? "Sim" : "Não";
    },
    getMeetingPlace() {
      return this.psychosocial_accompaniment.meeting_place
        ? this.psychosocial_accompaniment.meeting_place
        : "Local não informado";
    },
    getMeetingRemoteResource() {
      return this.psychosocial_accompaniment.meeting_remote_resource
        ? this.psychosocial_accompaniment.meeting_remote_resource
        : "Recurso não informado";
    },
    getMeetingNotHappenedReason() {
      return this.meeting_not_happened_reason
        ? this.meeting_not_happened_reason.name
        : "Motivo não informado";
    },
    getPurposeHomeVisit() {
      return this.psychosocial_accompaniment.purpose_home_visit
        ? this.psychosocial_accompaniment.purpose_home_visit
        : "Não informado";
    },
    getSocialFamilyContext() {
      return this.psychosocial_accompaniment.social_family_context
        ? this.psychosocial_accompaniment.social_family_context
        : "Não informado";
    },
    getSocialEconomicContext() {
      return this.psychosocial_accompaniment.social_economic_context
        ? this.psychosocial_accompaniment.social_economic_context
        : "Não informado";
    },
    getCommunityCoexistence() {
      return this.psychosocial_accompaniment.community_coexistence
        ? this.psychosocial_accompaniment.community_coexistence
        : "Não informado";
    },
    getHousingSituationName() {
      return this.housing_situation ? this.housing_situation.name : "Não informado";
    },
    getNumberOfResidents() {
      return this.psychosocial_accompaniment.number_of_residents
        ? this.psychosocial_accompaniment.number_of_residents
        : "Não informado";
    },
    getHousingBuildingSituationName() {
      return this.housing_building_situation ? this.housing_building_situation.name : "Não informado";
    },
    getHousingBuildingMaterialName() {
      return this.housing_building_material ? this.housing_building_material.name : "Não informado";
    },
    getHousingEletricityName() {
      return this.housing_has_electricity ? this.housing_has_electricity.name : "Não informado";
    },
    getHousingWaterSupplyName() {
      return this.housing_has_water_supply ? this.housing_has_water_supply.name : "Não informado";
    },
    getHousingGasSupplyName() {
      return this.housing_has_gas_supply ? this.housing_has_gas_supply.name : "Não informado";
    },
    getHousingDrainTypeName() {
      return this.housing_has_gas_supply ? this.housing_has_gas_supply.name : "Não informado";
    },
    verifyIfHasLatrine() {
      return this.psychosocial_accompaniment.housing_has_latrine ? "Sim" : "Não";
    },
    verifyIfHasBathroom() {
      return this.psychosocial_accompaniment.housing_has_bathroom ? "Sim" : "Não";
    },
    verifyIfHasGarbageCollection() {
      return this.psychosocial_accompaniment.housing_has_garbage_collection ? "Sim" : "Não";
    },
    getHousingSituationComments() {
      return this.psychosocial_accompaniment.housing_situation_comments
        ? this.psychosocial_accompaniment.housing_situation_comments
        : "Nenhum comentário realizado";
    },
    getSocioEducationalMeasureSituationName() {
      return this.socio_educational_measure_situation
        ? this.socio_educational_measure_situation.name
        : "Não Informado";
    },
    verifyIfIsHalfOpenSocioEducationalMeasure() {
      return this.psychosocial_accompaniment.is_half_open_socio_educational_measure ? "Sim" : "Não";
    },
    verifyIfIsHalfClosedSocioEducationalMeasure() {
      return this.psychosocial_accompaniment.is_half_closed_socio_educational_measure ? "Sim" : "Não";
    },
    getSentenceServingSituationName() {
      return this.sentence_serving_situation ? this.sentence_serving_situation.name : "Não Informado";
    },
    verifyIfIsHalfOpenSentenceServingSituation() {
      return this.psychosocial_accompaniment.is_half_open_sentence_serving_situation ? "Sim" : "Não";
    },
    verifyIfIsHalfClosedSentenceServingSituation() {
      return this.psychosocial_accompaniment.is_half_closed_sentence_serving_situation ? "Sim" : "Não";
    },
    verifyIfIsStudying() {
      return this.psychosocial_accompaniment.is_studying ? "Sim" : "Não";
    },
    getReasonForNotStudying() {
      return this.psychosocial_accompaniment.reason_for_not_studying
        ? this.psychosocial_accompaniment.reason_for_not_studying
        : "Razão não informada";
    },
    getReasonForDropoutStudy() {
      return this.psychosocial_accompaniment.reason_for_dropout_study
        ? this.psychosocial_accompaniment.reason_for_dropout_study
        : "Razão não informada";
    },
    getLastGradeStudied() {
      return this.psychosocial_accompaniment.last_grade_studied
        ? this.psychosocial_accompaniment.last_grade_studied
        : "Não informado";
    },
    getLastSchoolStudied() {
      return this.psychosocial_accompaniment.last_school_studied
        ? this.psychosocial_accompaniment.last_school_studied
        : "Não informado";
    },
    verifyIfNeedsSchoolReinsertion() {
      return this.psychosocial_accompaniment.need_school_reinsertion ? "Sim" : "Não";
    },
    getGender() {
      return this.gender ? this.gender.name : "Não informado";
    },
    verifyIfIdentifiesAsLgbtqiapn() {
      return this.identifies_as_lgbtqiapn ? this.identifies_as_lgbtqiapn.name : "Não informado na Inscrição";
    },
    getGenderIdentityObservations() {
      return this.psychosocial_accompaniment.gender_identity_observations
        ? this.psychosocial_accompaniment.gender_identity_observations
        : "Não informado";
    },
    verifyIfIsPregnant() {
      if (this.psychosocial_accompaniment.is_pregnant) {
        if (this.psychosocial_accompaniment.is_pregnant === true) {
          return "Sim";
        } else {
          return "Não";
        }
      } else {
        return "Não se aplica";
      }
    },
    verifyIfIsPuerperium() {
      if (this.psychosocial_accompaniment.is_puerperium) {
        if (this.psychosocial_accompaniment.is_puerperium === true) {
          return "Sim";
        } else {
          return "Não";
        }
      } else {
        return "Não se aplica";
      }
    },
    verifyIfUsesPsychoactiveSubstances() {
      return this.psychosocial_accompaniment.uses_psychoactive_substances ? "Sim" : "Não";
    },
    verifyIfUsesPsychoactiveSubstancesRecreationally() {
      return this.psychosocial_accompaniment.uses_psychoactive_substances_recreationally ? "Sim" : "Não";
    },
    verifyIfSelfMedicate() {
      return this.psychosocial_accompaniment.self_medicate ? "Sim" : "Não";
    },
    verifyIfShowSignsOfSelfHarm() {
      return this.psychosocial_accompaniment.show_signs_of_self_harm ? "Sim" : "Não";
    },
    verifyIfShowSignsOfCompromisedPhysicalHealth() {
      return this.psychosocial_accompaniment.show_signs_of_compromised_physical_health ? "Sim" : "Não";
    },
    verifyIfShowSignsOfCompromisedMentalHealth() {
      return this.psychosocial_accompaniment.show_signs_of_compromised_mental_health ? "Sim" : "Não";
    },
    getHealthSituationObservations() {
      return this.psychosocial_accompaniment.health_situation_observations
        ? this.psychosocial_accompaniment.health_situation_observations
        : "Nada informado";
    },
    verifyIfIsFrequentlyUserHealthNetwork() {
      return this.psychosocial_accompaniment.frequently_uses_health_network ? "Sim" : "Não";
    },
    verifyIfIsYoungFather() {
      return this.psychosocial_accompaniment.is_young_father ? "Sim" : "Não";
    },
    verifyIfIsYoungMother() {
      return this.psychosocial_accompaniment.is_young_mother ? "Sim" : "Não";
    },
    getMotivationUsesHealthFrequently() {
      return this.psychosocial_accompaniment.motivation_uses_health_frequently
        ? this.psychosocial_accompaniment.motivation_uses_health_frequently
        : "Motivação não informada";
    },
    getMotivationHealthPlaceFrequently() {
      return this.psychosocial_accompaniment.health_place_frequently
        ? this.psychosocial_accompaniment.health_place_frequently
        : "Nenhum local informado";
    },
    verifyIfAccompanimentWillContinue() {
      return this.psychosocial_accompaniment.accompaniment_will_continue ? "Sim" : "Não";
    },
    getAccompanimentGuideline() {
      return this.psychosocial_accompaniment.accompaniment_guideline
        ? this.psychosocial_accompaniment.accompaniment_guideline
        : "Diretriz não informada";
    },
    getMeetingConclusionDescription() {
      return this.psychosocial_accompaniment.meeting_conclusion_description
        ? this.psychosocial_accompaniment.meeting_conclusion_description
        : "Conclusão não informada";
    },
    getAccompanimentComments() {
      return this.psychosocial_accompaniment.accompaniment_comments
        ? this.psychosocial_accompaniment.accompaniment_comments
        : "Nenhum comentário informado";
    },
    visibleDisciplinarySanctionsMeasures(index) {
      const disciplinary_sanctions_attributes_names = [];
      this.disciplinary_sanctions_attributes[index]?.disciplinary_sanctions_measures_attributes?.map(
        (item) => {
          disciplinary_sanctions_attributes_names.push(item?.name);
        }
      );
      return disciplinary_sanctions_attributes_names;
    },
    mustShowDisciplinaryComplianceModes(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status, penalty_type } = disciplinarySanction;
      return (
        (this.psychosocial_accompaniment?.meeting_happened && sanction_type?.id === 0 && sanction_status) ||
        (this.psychosocial_accompaniment?.meeting_happened &&
          sanction_type?.id === 2 &&
          sanction_status &&
          penalty_type?.id === 0)
      );
    },
    mustShowDisciplinaryCompletionTimes(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return this.psychosocial_accompaniment?.meeting_happened && sanction_type && sanction_status?.id === 0;
    },
    mustShowDisciplinaryCompletionForecasts(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return this.psychosocial_accompaniment?.meeting_happened && sanction_type && sanction_status?.id === 1;
    },
    mustShowDisciplinaryMeasures(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return this.psychosocial_accompaniment?.meeting_happened && sanction_type?.id === 1 && sanction_status;
    },
    mustShowDisciplinaryPenaltys(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return this.psychosocial_accompaniment?.meeting_happened && sanction_type?.id === 2 && sanction_status;
    },
    mustShowDisciplinaryComplianceLocation(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { compliance_mode } = disciplinarySanction;
      return this.psychosocial_accompaniment?.meeting_happened && compliance_mode?.id === 2;
    },
    getStyles(type) {
      let headerColor = "";
      let colorText = "";
      switch (this.accompaniments_type.name) {
        case "Acolhimento Inicial":
          headerColor = "#475569";
          colorText = "ashes-dark";
          break;
        case "Atendimento Individual":
          headerColor = "herbal-lemon";
          colorText = "herbal";
          break;
        case "Visita Domiciliar":
          headerColor = "cyan";
          colorText = "cyan-clear";
          break;
      }
      if (type === "headerColor") return headerColor;
      if (type === "colorText") return colorText;
    },
    getLocalName() {
      switch (this.accompaniments_type.name) {
        case "Acolhimento Inicial":
          return "Local do Encontro";
        case "Atendimento Individual":
          return "Local do Encontro";
        case "Visita Domiciliar":
          return "Local da Visita Domiciliar";
      }
    },
    openTab(typeTab) {
      const tabMapping = {
        generalData: "generalData",
        housingData: "housingData",
        disciplinarySanctionData: "disciplinarySanctionData",
        studyingData: "studyingData",
        healthData: "healthData",
        genderIdentityData: "genderIdentityData",
        forwardingData: "forwardingData",
        accompanimentPlanData: "accompanimentPlanData",
        conclusionData: "conclusionData",
        socialContext: "socialContext",
      };

      for (const tab in tabMapping) {
        this[tabMapping[tab]] = tab === typeTab;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.active_new {
  background-color: #fff7ed;
  color: #fff;
  font-size: 1.1em;
}

.tab_new {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab_new:hover {
  background-color: #f3f4f6;
}
</style>
