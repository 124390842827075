<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2 items-center">
        <div class="text-ashes-hover font-medium text-3xl w-max mb-7">Transferência entre Edições</div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="SeasonPreviousInscriptions/closeModalTransferInscription" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
        <h3 class="text-lg font-semibold mb-3">Informações da Inscrição</h3>
        <p class="mb-1"><strong>Nome:</strong> {{ seasonPreviousInscription.student.name }}</p>
        <p class="mb-1"><strong>Nome Social:</strong> {{ seasonPreviousInscription.student.social_name }}</p>
        <p class="mb-1"><strong>Cidade:</strong> {{ seasonPreviousInscription.student.city.name }}</p>
        <p><strong>Edição Atual:</strong> {{ seasonPreviousInscription.season.name }}</p>
      </div>
      <div class="mb-4">
        <Label
          text="Selecione a Edição de Destino:"
          :icon="null"
          :icon-type="null"
          type="text-lg"
          weight="bold"
          normal
          mode="normal-case"
          class="text-ashes-dark mb-4"
        />
      </div>
      <div class="bg-white p-6 rounded-lg shadow-md">
        <AppRadioButton
          :values="radioButtonValues"
          :show-label="false"
          dispatch="SeasonPreviousInscriptions/setTargetSeason"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 mt-6">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="Descartar"
            typeButton="danger"
            dispatch="SeasonPreviousInscriptions/closeModalTransferInscription"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Salvar"
            type-button="success"
            dispatch="SeasonPreviousInscriptions/transferInscription"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppRadioButton from "../components/AppRadioButton.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    AppRadioButton,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      seasons_for_menus: (state) => state.SeasonPreviousInscriptions.seasons_for_menus,
      seasonPreviousInscription: (state) => state.SeasonPreviousInscriptions.seasonPreviousInscription,
    }),
    filteredSeasons() {
      return this.seasons_for_menus.filter(
        (season) => season.id !== this.seasonPreviousInscription.season.id
      );
    },
    radioButtonValues() {
      return this.filteredSeasons.map((season) => ({
        name: "season",
        value: season.id,
        description: season.name,
        checked: false,
      }));
    },
  },
  methods: {
    ...mapActions({
      setPhase: "Season/setPhase",
    }),
  },
};
</script>
