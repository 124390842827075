<template>
  <div v-if="open && season" class="mt-2">
    <div id="tabs-button" class="mx-4">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
            required
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Tipo da Vaga Solicitada"
            clear-on-select
            select-placeholder="Tipo da Vaga Solicitada"
            :multiple-option="false"
            :value="vacancy_type_filtered"
            :options="vacancy_types"
            :dispatch="setFilterVacancyType"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_articulator"
            input-type="text"
            :value="articulator_filtered"
            show-label
            label-text="Articulador Responsável"
            dispatch="VacancyRequests/setFilterArticulator"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div>
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="vacancy_requests.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
        <BaseModal
          v-if="vacancy_requests_spreadsheet.length > 0 && modalToggleExportVacancyRequestsSpreadsheet"
        >
          <template v-slot:header>
            <div class="grid grid-cols-3">
              <div class="justify-self-start col-span-2">
                <Label
                  text="Exportar Planilha de Solicitações de Vagas"
                  :icon="null"
                  :icon-type="null"
                  type="text-2xl"
                  weight="bold"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="justify-self-end">
                <AppButtonIcon
                  dispatch="VacancyRequests/closeExportVacancyRequestsSpreadsheetModal"
                  forClose
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="grid grid-rows-2">
              <div class="grid justify-center items-center">
                <Label
                  text="Planilha gerada com sucesso!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid grid-cols-2">
                <div class="justify-self-start">
                  <AppButtonEmpty
                    text="descartar"
                    typeButton="danger"
                    dispatch="VacancyRequests/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                  />
                </div>

                <div class="justify-self-end">
                  <download-excel
                    type="xls"
                    :meta="json_meta"
                    :fields="json_fields"
                    :data="json_data_vacancy_request"
                    :before-finish="closeExportVacancyRequestsSpreadsheetModal"
                    worksheet="Solicitacoes_vagas"
                    name="solicitacoes_vagas_por_inscricao.xls"
                    :escapeCsv="false"
                  >
                    <AppButtonEmpty text="Download da planilha" typeButton="success" />
                  </download-excel>
                </div>
              </div>
            </div>
          </template>
        </BaseModal>
        <div v-if="!hasSpreadsheetVacancyRequestsResponse">
          <PreLoading />
        </div>
      </div>
      <div class="justify-self-end" v-if="permitCreate">
        <AppButtonColorful
          text="Solicitação de Vaga"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="VacancyRequests/openModalAddVacancyRequest"
        />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-vacancy-requests"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="VacancyRequests/loadVacancyRequests"
          payload="loadData"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="vacancy_requests.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-school-reinsertions"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-school-reinsertions">
      <div class="mx-12">
        <SchoolReinsertionsNotReinsertedVacancyRequestCards :data="vacancy_requests" />
      </div>
      <div id="vacancy-request-pagination" class="mx-12 my-6 pb-3" v-if="vacancy_requests.length > 0">
        <BasePagination
          :total="vacancyRequestsTotal"
          :total-per-page="vacancyRequestsTotalPerPage"
          dispatch="VacancyRequests/setVacancyRequestsOffset"
        />
      </div>
    </div>
    <ModalAddVacancyRequest :open="modalToggleAddVacancyRequest" />
    <DeleteSchoolReinsertionNotReinsertedVacancyRequestConfirmation :open="modalToggleDeleteVacancyRequest" />
    <ModalViewSchoolReinsertionVacancyRequestData :open="modalToggleViewVacancyRequest" />
  </div>
</template>

<script>
import SchoolReinsertionsNotReinsertedVacancyRequestCards from "../list/SchoolReinsertionsNotReinsertedVacancyRequestCards.vue";
import ModalViewSchoolReinsertionVacancyRequestData from "../datas/ModalViewSchoolReinsertionVacancyRequestData.vue";
import ModalAddVacancyRequest from "./not_reinserteds/ModalAddVacancyRequest.vue";
import BasePagination from "../../components/BasePagination.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import DeleteSchoolReinsertionNotReinsertedVacancyRequestConfirmation from "../alerts/DeleteSchoolReinsertionNotReinsertedVacancyRequestConfirmation.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import BaseText from "../../components/BaseText.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import PreLoading from "../../components/PreLoading.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseModal,
    Label,
    BaseText,
    AppButtonColorful,
    DeleteSchoolReinsertionNotReinsertedVacancyRequestConfirmation,
    AppButtonEmpty,
    AppButtonIcon,
    BaseInput,
    SchoolReinsertionsNotReinsertedVacancyRequestCards,
    ModalAddVacancyRequest,
    ModalViewSchoolReinsertionVacancyRequestData,
    Multiselect,
    PreLoading,
  },
  data() {
    return {
      permitCreate: false,
      permitExportSpreadsheet: false,
      currentPage: 1,
      json_fields: {
        "Nome do Participante": "student_name",
        "Tipo da Vaga Solicitada": "vacancy_type",
        "Solicitação já foi atendida?": "accepted_request",
        "Articulador(a) Responsável": "articulator",
        "CPF da Mãe ou Responsável do Jovem": "cpf_responsible_young_person",
        "Endereço da Mãe ou Responsável do Jovem": "address_responsible_young_person",
        "Contato Atualizado do Jovem": "updated_contact_young_person",
        "Escola de Interesse": "interest_school",
        "Turno de Interesse": "interest_shift",
        "Série de Interesse": "interest_serie",
        "Rede a que pertence a Escola": "school_network",
        "Descrição do Caso": "case_description",
        "Intervenções já Realizadas": "interventions_performed",
        "Tipo do Encaminhamento": "forwarding_type",
        "Nome Completo do Responsável pela Criança": "full_name_responsible_child",
        "Nome Completo da Criança": "child_full_name",
        "CPF da Mãe ou Responsável pela Criança": "cpf_responsible_child",
        "Data de Nascimento da Criança": "child_birthdate",
        "Endereço do Responsável pela Criança": "address_responsible_child",
        "Contato do Responsável pela Criança": "contact_responsible_child",
        "Creche de Interesse que já foi articulada": "interest_kindergarten",
        Devolutiva: "feedback",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      closeExportVacancyRequestsSpreadsheetModal:
        "VacancyRequests/closeExportVacancyRequestsSpreadsheetModal",
      setFilterVacancyType: "VacancyRequests/setFilterVacancyType",
      setFilterArticulator: "VacancyRequests/setFilterArticulator",
      setFilterCity: "VacancyRequests/setFilterCity",
      setFilterState: "VacancyRequests/setFilterState",
    }),
    async clearFields() {
      await this.$store.dispatch("VacancyRequests/clearQuery");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch("VacancyRequests/loadVacancyRequestsByInscription", "loadDataSpreadsheet");
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
  },
  async created() {
    this.backToTop();
    // this.$store.dispatch("VacancyRequests/loadVacancyRequestsByInscription", "loadData");

    // const inscription_id = sessionStorage.inscription_id;
    // await this.$store.dispatch("VacancyRequests/loadInscriptionByIdForVacancyRequest", inscription_id);

    this.canAcces("school_reinsertion_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("psychosocial_filter_by_inscription_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    this.$store.dispatch("VacancyRequests/loadArticulators");
    this.$store.dispatch("VacancyRequests/loadVacancyTypes");
    this.$store.dispatch("VacancyRequests/loadInterestSeries");
    this.$store.dispatch("VacancyRequests/loadInterestShifts");
    this.$store.dispatch("VacancyRequests/loadSchoolNetworks");
    this.$store.dispatch("VacancyRequests/loadFacilities");
  },

  computed: {
    ...mapState({
      vacancy_requests: (state) => state.VacancyRequests.vacancy_requests,
      states: (state) => state.UtilFunctions.states,
      cities: (state) => state.UtilFunctions.cities,
      state: (state) => state.VacancyRequests.params.state,
      city: (state) => state.VacancyRequests.params.city,
      season: (state) => state.SchoolReinsertions.season,

      vacancyRequestsByInscriptionOffset: (state) => state.VacancyRequests.vacancyRequestsByInscriptionOffset,
      vacancyRequestsByInscriptionTotalPerPage: (state) =>
        state.VacancyRequests.vacancyRequestsByInscriptionTotalPerPage,
      vacancyRequestsByInscriptionTotal: (state) => state.VacancyRequests.vacancyRequestsByInscriptionTotal,
      vacancyRequestsOffset: (state) => state.VacancyRequests.vacancyRequestsOffset,
      vacancyRequestsTotalPerPage: (state) => state.VacancyRequests.vacancyRequestsTotalPerPage,
      vacancyRequestsTotal: (state) => state.VacancyRequests.vacancyRequestsTotal,

      inscription: (state) => state.VacancyRequests.inscription,
      articulators: (state) => state.VacancyRequests.articulators,
      articulator_filtered: (state) => state.VacancyRequests.params.articulator,
      vacancy_types: (state) => state.VacancyRequests.vacancy_types,
      vacancy_type_filtered: (state) => state.VacancyRequests.params.vacancy_type,
      vacancy_requests_spreadsheet: (state) => state.VacancyRequests.vacancy_requests_spreadsheet,
      hasSpreadsheetVacancyRequestsResponse: (state) =>
        state.VacancyRequests.hasSpreadsheetVacancyRequestsResponse,
      json_data_vacancy_request: (state) => state.VacancyRequests.json_data_vacancy_request,
      toggleSchoolReinsertionNotReinserted: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinserted,
      modalToggleAddVacancyRequest: (state) => state.VacancyRequests.modalToggleAddVacancyRequest,
      modalToggleDeleteVacancyRequest: (state) => state.VacancyRequests.modalToggleDeleteVacancyRequest,
      modalToggleViewVacancyRequest: (state) => state.VacancyRequests.modalToggleViewVacancyRequest,
      modalToggleExportVacancyRequestsSpreadsheet: (state) =>
        state.VacancyRequests.modalToggleExportVacancyRequestsSpreadsheet,
    }),
  },
  watch: {
    vacancyRequestsByInscriptionOffset: function () {
      this.$store.dispatch("VacancyRequests/loadVacancyRequests");
      this.backToTop();
    },
  },
};
</script>
