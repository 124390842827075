<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Plano de Acompanhamento"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <AppRadioButton
        :values="accompanimentOptions"
        labelText="Acompanhamento vai continuar?"
        showLabel
        dispatch="PsychosocialAccompaniments/setAccompanimentWillContinue"
      />
    </div>
    <div v-if="psychosocial_accompaniment?.accompaniment_will_continue">
      <BaseInput
        active
        input-name="birthdate"
        input-type="date"
        :value="continued_accompaniment.date_accompaniment"
        show-label
        label-text="Data do Próximo Encontro"
        required
        dispatch="PsychosocialAccompaniments/setDateAccompanimentContinuedAccompaniment"
      />
    </div>
    <div v-if="psychosocial_accompaniment?.accompaniment_will_continue">
      <Multiselect
        :close-on-select="true"
        show-label
        clear-on-select
        select-placeholder="Tipo do Atendimento"
        label-text="Tipo do Atendimento"
        :multiple-option="false"
        :value="continued_accompaniment_selected.accompaniments_type"
        :options="type_accompaniment_select"
        required
        :dispatch="setPsychosocialAccompanimentsTypeContinuedAccompaniment"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppRadioButton from "@/components/AppRadioButton.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import Label from "@/components/Label.vue";

export default {
  name: "AccompanimentPlan",
  components: {
    AppRadioButton,
    BaseInput,
    Multiselect,
    Label,
  },
  props: {
    psychosocial_accompaniment: Object,
  },
  computed: {
    ...mapState({
      continued_accompaniment: (state) => state.PsychosocialAccompaniments.continued_accompaniment,
      continued_accompaniment_selected: (state) =>
        state.PsychosocialAccompaniments.continued_accompaniment_selected,
    }),
    accompanimentOptions() {
      return [
        {
          name: "accompaniment_will_continue",
          value: true,
          description: "Sim",
          checked: this.psychosocial_accompaniment?.accompaniment_will_continue === true,
        },
        {
          name: "accompaniment_will_continue",
          value: false,
          description: "Não",
          checked: this.psychosocial_accompaniment?.accompaniment_will_continue === false,
        },
      ];
    },
  },
  data() {
    return {
      type_accompaniment_select: [
        {
          id: 1,
          name: "Atendimento Individual",
        },
        {
          id: 2,
          name: "Visita Domiciliar",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      setPsychosocialAccompanimentsTypeContinuedAccompaniment:
        "PsychosocialAccompaniments/setPsychosocialAccompanimentsTypeContinuedAccompaniment",
    }),
  },
};
</script>
