<template>
  <div class="grid grid-cols-2 gap-4 mt-2">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Dados cadastrais do encontro"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <BaseInput
        active
        input-name="birthdate"
        input-type="date"
        :value="psychosocial_accompaniment.date_accompaniment"
        show-label
        label-text="Data do Encontro"
        required
        dispatch="PsychosocialAccompaniments/setDateAccompaniment"
        :readonly="true"
      />
    </div>
    <div class="col-span-1">
      <AppRadioButton
        :values="meetingHappenedOptions"
        labelText="Encontro aconteceu?"
        showLabel
        dispatch="PsychosocialAccompaniments/setMeetingHappened"
      />
    </div>
    <div class="col-span-1" v-if="psychosocial_accompaniment.meeting_happened">
      <AppRadioButton
        :values="meetingFormatOptions"
        labelText="Formato do Encontro"
        showLabel
        dispatch="PsychosocialAccompaniments/setMeetingFormat"
      />
    </div>
    <div v-if="!psychosocial_accompaniment.meeting_happened">
      <Multiselect
        :close-on-select="true"
        show-label
        clear-on-select
        select-placeholder="Motivo do Encontro Não ter Ocorrido"
        label-text="Motivo do Encontro Não ter Ocorrido"
        :multiple-option="false"
        :value="meeting_not_happened_reason"
        :options="meeting_not_happened_reasons"
        :dispatch="setMeetingNotHappenedReason"
        required
      />
    </div>
    <div v-if="psychosocial_accompaniment.meeting_happened">
      <BaseInput
        active
        input-name="psychosocial_accompaniment_meeting_place"
        input-type="text"
        :value="psychosocial_accompaniment.meeting_place"
        show-label
        label-text="Local do Encontro"
        dispatch="PsychosocialAccompaniments/setMeetingPlace"
      />
    </div>
    <div v-if="psychosocial_accompaniment.meeting_happened">
      <BaseInput
        active
        input-name="psychosocial_accompaniment_meeting_remote_resource"
        input-type="text"
        :value="psychosocial_accompaniment.meeting_remote_resource"
        show-label
        label-text="Recurso do encontro remoto"
        dispatch="PsychosocialAccompaniments/setMeetingRemoteResource"
        :readonly="psychosocial_accompaniment.meeting_format === 'Presencial'"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/forms/BaseInput.vue";
import Label from "@/components/Label.vue";
import AppRadioButton from "@/components/AppRadioButton.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import { mapActions } from "vuex";

export default {
  name: "MeetingDetails",
  components: {
    AppRadioButton,
    Multiselect,
    BaseInput,
    Label,
  },
  props: {
    psychosocial_accompaniment: Object,
    meeting_not_happened_reason: String,
    meeting_not_happened_reasons: Array,
  },
  computed: {
    meetingHappenedOptions() {
      return [
        {
          name: "has_meeting_happened",
          value: true,
          description: "Sim",
          checked: this.psychosocial_accompaniment.meeting_happened === true,
        },
        {
          name: "has_meeting_happened",
          value: false,
          description: "Não",
          checked: this.psychosocial_accompaniment.meeting_happened === false,
        },
      ];
    },
    meetingFormatOptions() {
      return [
        {
          name: "meeting_format",
          value: "Presencial",
          description: "Presencial",
          checked: this.psychosocial_accompaniment.meeting_format === "Presencial",
        },
        {
          name: "meeting_format",
          value: "Remoto",
          description: "Remoto",
          checked: this.psychosocial_accompaniment.meeting_format === "Remoto",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setMeetingNotHappenedReason: "PsychosocialAccompaniments/setMeetingNotHappenedReason",
    }),
  },
};
</script>
