import { getAxios, baseURL } from "./Api";

export async function getInscriptionsForAdvantages(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/lista_inscritos_para_beneficios`;
  return api.get(url, { params: filters });
}

export async function getInscriptionsForExportAdvantages(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/inscricoes/lista_inscritos_para_exportar_beneficios`;
  return api.get(url, { params: filters });
}
