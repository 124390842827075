<template>
  <div class="pagination-container-light-orange">
    <div class="total-registers">
      <BaseText
        :text="getTotalRegisters()"
        typeText="custom"
        :italic="false"
        size="base"
        color="burnedYellow"
      />
    </div>
    <div class="pagination-controls">
      <div @click="goTo(1)" :class="controlFirstCss">
        <Label text="" icon="angle-double-left" />
      </div>
      <div @click="previous()" :class="controlsCss">
        <Label text="" icon="angle-left" />
      </div>
      <div :class="controlsCss" class="numbers-page">
        <div v-for="i in pages" :key="i" @click="goTo(i)" :class="numberPageClass(i)">
          {{ i }}
        </div>
      </div>
      <div class="ellipsis" v-if="currentPage != totalPage() && totalPage() >= 10">...</div>
      <div @click="next()" :class="controlsCss">
        <Label text="" icon="angle-right" />
      </div>
      <div @click="goTo(totalPage())" :class="controlsCss">
        <Label text="" icon="angle-double-right" />
      </div>
    </div>
  </div>
</template>

<script>
import Label from "./Label.vue";
import BaseText from "../components/BaseText.vue";

export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    totalPerPage: {
      type: Number,
    },
    dispatch: {
      type: String,
    },
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  components: {
    Label,
    BaseText,
  },

  methods: {
    next() {
      if (this.currentPage < this.totalPage()) {
        this.currentPage++;
      }
      this.sendDatasForFather();
      return this.currentPage;
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
      this.sendDatasForFather();
      return this.currentPage;
    },
    goTo(page) {
      this.currentPage = page;
      if (page < 1) {
        this.currentPage = 1;
      }

      if (page > this.totalPage()) {
        this.currentPage = this.totalPage();
      }
      this.sendDatasForFather();
      return this.currentPage;
    },
    totalPage() {
      return Math.ceil(this.total / this.totalPerPage);
    },
    numberPageClass(page) {
      return {
        "page-number-light-orange": true,
        "active-page-light-orange": page === this.currentPage,
      };
    },
    sendDatasForFather() {
      let params = {};

      let offset = this.currentPage * this.totalPerPage - this.totalPerPage;
      params.offset = offset;
      params.currentPage = this.currentPage;
      if (this.dispatch) {
        this.$store.dispatch(this.dispatch, params);
      }
    },
    getTotalRegisters() {
      return this.total < 1 ? "Nenhum registro encontrado" : `Total de registros encontrados: ${this.total}`;
    },
  },

  computed: {
    controlFirstCss() {
      return { "control-first-light-orange": true, "cursor-pointer": true };
    },
    controlsCss() {
      return { "control-light-orange": true, "cursor-pointer": true };
    },
    pages() {
      const current = this.currentPage;
      const range = 10;
      const offset = Math.ceil(range / 2);
      const total = this.totalPage();
      const pagesArray = [];
      for (let i = 1; i <= total; i++) {
        pagesArray.push(i);
      }
      pagesArray.splice(0, current - offset);
      pagesArray.splice(range, total);
      return pagesArray;
    },
  },
};
</script>

<style scoped>
.pagination-container-light-orange {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fffdf9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.total-registers {
  margin-bottom: 10px;
  font-weight: bold;
  color: #f5a623;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.control-light-orange,
.control-first-light-orange {
  margin: 0 5px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff7e6;
  transition: background-color 0.3s;
}

.control-light-orange:hover,
.control-first-light-orange:hover {
  background-color: #ffefd5;
}

.numbers-page {
  display: flex;
  margin: 0 10px;
}

.page-number-light-orange {
  margin: 0 5px;
  padding: 8px;
  border-radius: 50%;
  background-color: #fff7e6;
  transition: background-color 0.3s;
  cursor: pointer;
}

.page-number-light-orange:hover {
  background-color: #ffefd5;
}

.active-page-light-orange {
  background-color: #ffcc99;
  color: #fff;
}

.ellipsis {
  margin: 0 5px;
  color: #999;
}
</style>
