import {
  createPartner,
  destroyPartner,
  getPartnerById,
  getPartners,
  getPartnersForExport,
  updatePartner,
} from "../../service/PartnersApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

const state = {
  partner: {
    id: null,
    name: null,
    city_id: null,
  },

  partners: [],
  partners_spreadsheet: [],
  errorMessages: [],
  partnerId: null,
  city: null,
  state: null,

  total_opportunities_offered: null,
  total_vacancies_offered: null,
  total_registered_referrals: null,
  total_registered_referrals_made: null,

  modalToggleAddPartner: false,
  modalToggleDeletePartner: false,
  modalToggleViewPartner: false,
  modalToggleExportPartnersSpreadsheet: false,

  hasSpreadsheetPartnersResponse: true,
  json_data_partner: null,

  partnersTotal: 0,
  partnersTotalPerPage: 10,
  partnersOffset: 0,

  params: {
    name: null,
    city: null,
    state: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadPartners: (state, partners) => {
    state.partners = partners;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.partner.id = id;
  },
  setNameMutation: (state, name) => {
    state.partner.name = name;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.partner.city_id = city ? city.id : null;
  },
  setTotalOpportunitiesOfferedMutation: (state, total_opportunities_offered) => {
    state.total_opportunities_offered = total_opportunities_offered;
  },
  setTotalVacanciesOfferedMutation: (state, total_vacancies_offered) => {
    state.total_vacancies_offered = total_vacancies_offered;
  },
  setTotalRegisteredReferralsMutation: (state, total_registered_referrals) => {
    state.total_registered_referrals = total_registered_referrals;
  },
  setTotalRegisteredReferralsMadeMutation: (state, total_registered_referrals_made) => {
    state.total_registered_referrals_made = total_registered_referrals_made;
  },

  // --> MUTATIONS - DELETION <--
  partnerForDeletion: (state, payload) => {
    if (payload) {
      state.partnerId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddPartner: (state) => {
    state.modalToggleAddPartner = !state.modalToggleAddPartner;
  },
  toggleModalDeletePartner: (state) => {
    state.modalToggleDeletePartner = !state.modalToggleDeletePartner;
  },
  toggleModalViewPartner: (state) => {
    state.modalToggleViewPartner = !state.modalToggleViewPartner;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportPartnersSpreadsheet: (state) => {
    state.modalToggleExportPartnersSpreadsheet = !state.modalToggleExportPartnersSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetPartnersResponseMutation: (state, hasSpreadsheetPartnersResponse) => {
    state.hasSpreadsheetPartnersResponse = hasSpreadsheetPartnersResponse;
  },

  setPartnersPageToExportMutation: (state, partner) => {
    state.json_data_partner = null;
    const arrayData = [];
    partner.forEach((element, index, array) => {
      let name = array[index].name ? array[index].name : "-";
      let total_opportunities_offered =
        array[index].total_opportunities_offered !== null ? array[index].total_opportunities_offered : "-";
      let total_vacancies_offered =
        array[index].total_vacancies_offered !== null ? array[index].total_vacancies_offered : "-";
      let total_registered_referrals =
        array[index].total_registered_referrals !== null ? array[index].total_registered_referrals : "-";
      let total_registered_referrals_made =
        array[index].total_registered_referrals_made !== null
          ? array[index].total_registered_referrals_made
          : "-";

      const arrayItem = [];
      arrayItem["name"] = name;
      arrayItem["total_opportunities_offered"] = total_opportunities_offered;
      arrayItem["total_vacancies_offered"] = total_vacancies_offered;
      arrayItem["total_registered_referrals"] = total_registered_referrals;
      arrayItem["total_registered_referrals_made"] = total_registered_referrals_made;
      arrayData.push(arrayItem);
    });
    state.json_data_partner = arrayData;
    state.hasSpreadsheetPartnersResponse = true;
    state.modalToggleExportPartnersSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setPartnersTotalPerPage: (state, partnersTotalPerPage) => {
    state.partnersTotalPerPage = partnersTotalPerPage;
  },
  setPartnersOffset: (state, partnersOffset) => {
    state.partnersOffset = partnersOffset;
  },
  setPartnersTotal: (state, partnersTotal) => {
    state.partnersTotal = partnersTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.partner) {
      state.partner[prop] = null;
    }
    state.partner.city_id = null;
    state.city = null;
    state.state = null;
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.params.city = null;
    state.params.state = null;
    state.partners = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setPartnersSpreadsheetMutation: (state, partners_spreadsheet) => {
    state.partners_spreadsheet = partners_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams(rootGetters) {
  return {
    name: state.params.name,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    per_page: state.partnersTotalPerPage,
    offset: state.partnersOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadPartners: ({ commit, rootGetters }, from) => {
    const params = getFilterParams(rootGetters);
    getPartners(params)
      .then((response) => {
        commit("loadPartners", response.data.partners);
        commit("setPartnersTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPartnersForExport: ({ commit }) => {
    commit("hasSpreadsheetPartnersResponseMutation", false);
    const params = getFilterParams();
    getPartnersForExport(params)
      .then(async (response) => {
        commit("setPartnersSpreadsheetMutation", response.data.partners);
        if (state.partners_spreadsheet.length > 0) {
          await commit("setPartnersPageToExportMutation", response.data.partners);
        } else {
          commit("hasSpreadsheetPartnersResponseMutation", true);
          commit("toggleModalToggleExportPartnersSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getPartnerById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.partners.id);
        commit("setNameMutation", response.data.partners.name);
        commit("setCityMutation", response.data.partners.city);
        commit("setTotalOpportunitiesOfferedMutation", response.data.partners.total_opportunities_offered);
        commit("setTotalVacanciesOfferedMutation", response.data.partners.total_vacancies_offered);
        commit("setTotalRegisteredReferralsMutation", response.data.partners.total_registered_referrals);
        commit(
          "setTotalRegisteredReferralsMadeMutation",
          response.data.partners.total_registered_referrals_made
        );
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddPartner: ({ commit }) => {
    commit("toggleModalAddPartner");
  },
  closeModalAddPartner: ({ commit }) => {
    commit("toggleModalAddPartner");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddPartner: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddPartner");
  },
  closeEditModalAddPartner: ({ commit }) => {
    commit("toggleModalAddPartner");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewPartner");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewPartner");
    commit("clearFields");
  },
  toggleModalDeletePartner: ({ commit }, payload) => {
    commit("toggleModalDeletePartner");
    if (state.modalToggleDeletePartner && payload) {
      commit("partnerForDeletion", payload);
    } else {
      commit("partnerForDeletion", null);
    }
  },
  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportPartnersSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPartnersSpreadsheet");
  },
  closeExportPartnersSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPartnersSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },

  // --> ACTIONS - PAGINATIONS <--
  setPartnersTotalPerPage: ({ commit }, payload) => {
    commit("setPartnersTotalPerPage", payload.totalPerPage);
  },
  setPartnersOffset: ({ commit }, payload) => {
    commit("setPartnersOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createPartner(state.partner)
      .then(() => {
        Vue.$toast.success("Parceiro cadastrado com sucesso!");
        dispatch("closeModalAddPartner");
        dispatch("loadPartners");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o parceiro! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updatePartner(state.partner.id, state.partner)
      .then(() => {
        dispatch("loadPartners");
        dispatch("closeEditModalAddPartner");
        Vue.$toast.success("Parceiro atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o parceiro! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyPartner(state.partnerId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Parceiro deletado com sucesso!");
        commit("toggleModalDeletePartner");
        dispatch("loadPartners", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
