<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon for-close dispatch="Season/openCloseReactivateSeasonConfirmation" />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText text="Reativar Edição" typeText="custom" weight="semibold" size="2xl" color="ashes-dark" />
      </div>
    </template>
    <template v-slot:body>
      <div class="mt-5">
        <BaseText
          text="Tem certeza que deseja reativar esta edição?"
          typeText="custom"
          weight="extrabold"
          color="quantum"
          size="lg"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          dispatch="Season/openCloseReactivateSeasonConfirmation"
          class="mr-5"
        />
        <AppButtonEmpty
          v-if="permitReactivateSeason"
          text="Sim"
          type-button="success"
          dispatch="Season/reactivateSeason"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  data() {
    return {
      permitReactivateSeason: false,
    };
  },
  created() {
    this.permitReactivateSeasonAccess();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitReactivateSeasonAccess() {
      this.canAcces("season_reactivate_season").then((response) => {
        this.permitReactivateSeason = response;
      });
    },
  },
};
</script>
