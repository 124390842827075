// import { getTeachers } from "../../service/TeacherApi";
// import Vue from "vue";

const state = {
  teachers: [],
  filterTeachers: [],
  modalDeleteTeacherConfirmationOpen: false,
  modalTeacherOpen: false,
  teacherIdForDeletion: null,
  teachersTotal: 0,
  teachersTotalPerPage: 10,
  teachersOffset: 0,
  params: {
    name: null,
    email: null,
    cpf: null,
    state: null,
    city: null,
    user: null,
  },
};

const mutations = {
  setTeachers: (state, teachers) => {
    state.teachers = teachers;
    state.filterTeachers = teachers;
  },
  filterList: (state, input) => {
    let teachers = state.teachers;
    state.filterTeachers = teachers.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());

      return name > -1 || cpf > -1;
    });
  },
  toggleModalDeleteTeacherConfirmation: (state) => {
    state.modalDeleteTeacherConfirmationOpen = !state.modalDeleteTeacherConfirmationOpen;
  },

  toggleModalTeacher: (state) => {
    state.modalTeacherOpen = !state.modalTeacherOpen;
  },
  addTeacher: (state, teacher) => {
    state.teachers.push(teacher);
  },
  updateTeacher: (state, teacher) => {
    state.teachers = state.teachers.map((item) => {
      return item.id === teacher.id ? teacher : item;
    });
  },
  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterEmailMutation: (state, email) => {
    state.params.email = email;
  },
  setFilterCpfMutation: (state, cpf) => {
    state.params.cpf = cpf;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterUserMutation: (state, user) => {
    state.params.user = user;
  },

  teacherForDeletion: (state, document_id) => {
    state.teacherIdForDeletion = document_id;
  },
  // --> MUTATIONS - PAGINATION FIELDS <--
  setTeachersTotalPerPage: (state, teachersTotalPerPage) => {
    state.teachersTotalPerPage = teachersTotalPerPage;
  },
  setTeachersOffset: (state, teachersOffset) => {
    state.teachersOffset = teachersOffset;
  },
  setTeachersTotal: (state, teachersTotal) => {
    state.teachersTotal = teachersTotal;
  },
  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.name = null;
    state.params.email = null;
    state.params.cpf = null;
    state.params.city = null;
    state.params.state = null;
    state.params.user = null;
    state.teachers = [];
  },
};

// function getFilterParams(rootGetters) {
//   return {
//     name: state.params.name,
//     email: state.params.email,
//     cpf: state.params.cpf,
//     city_id:
//       state.params.city && state.params.city.id
//         ? state.params.city.id
//         : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
//         ? rootGetters["UtilFunctions/cityIds"]
//         : null,
//     user_id: state.params.user ? state.params.user.id : null,
//     per_page: state.teachersTotalPerPage,
//     offset: state.teachersOffset,
//   };
// }

const actions = {
  setTeachers: ({ commit }, teachers) => {
    commit("setTeachers", teachers);
  },
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  // loadTeachers: ({ commit, rootGetters }) => {
  //   const params = getFilterParams(rootGetters);
  //   getTeachers(params)
  //     .then((response) => {
  //       commit("setTeachers", response.data.teachers);
  //       commit("setTeachersTotal", response.data.total);
  //     })
  //     .catch((e) => {
  //       Vue.$toast.error(e.response.data.error);
  //       console.log("error", e);
  //     });
  // },
  addTeacher: ({ commit }, payload) => {
    commit("addTeacher", payload);
  },
  updateTeacher: ({ commit }, payload) => {
    commit("updateTeacher", payload);
  },
  openModalTeacher: ({ commit, dispatch }) => {
    dispatch("Teacher/clearTeacher", null, { root: true });
    commit("toggleModalTeacher");
  },
  closeModalTeacher: ({ commit, dispatch }) => {
    commit("toggleModalTeacher");
    dispatch("Teacher/clearErrorMessages", null, { root: true });
  },
  openCloseDeleteTeacherConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteTeacherConfirmation");
    if (state.modalDeleteTeacherConfirmationOpen) {
      commit("teacherForDeletion", payload.id);
    } else {
      commit("teacherForDeletion", null);
    }
  },
  openEditModalTeacher: ({ commit, dispatch }, teacher) => {
    dispatch("Teacher/load", teacher, { root: true });
    commit("toggleModalTeacher");
  },
  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterEmail: ({ commit }, payload) => {
    commit("setFilterEmailMutation", payload.value);
  },
  setFilterCpf: ({ commit }, payload) => {
    commit("setFilterCpfMutation", payload.value);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterUser: ({ commit }, payload) => {
    commit("setFilterUserMutation", payload);
  },
  // --> ACTIONS - PAGINATIONS <--
  setTeachersTotalPerPage: ({ commit }, payload) => {
    commit("setTeachersTotalPerPage", payload.totalPerPage);
  },
  setTeachersOffset: ({ commit }, payload) => {
    commit("setTeachersOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
