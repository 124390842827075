import { getAxios, baseURL } from "./Api";

export async function getEquipments(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/equipamentos`;
  return api.get(url, { params: filters });
}

export async function getEquipmentsForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/equipamentos/para_menus`;
  return api.get(url, { params: filters });
}

export async function getEquipmentByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/equipamentos`);
}

export async function getEquipmentByNeighborhoodId(neighborhood_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`bairros/${neighborhood_id}/equipamentos`);
}

export async function getEquipmentBySeasonId(season_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`edicoes/${season_id}/equipamentos`);
}

export async function getEquipmentByAreaId(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `equipamentos/equipamentos_por_area_e_cidade`;
  return api.get(url, { params: filters });
}

export async function getEquipmentByAreaIdForPsychosocial(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `equipamentos/equipamentos_por_area_e_cidade_para_psicossocial`;
  return api.get(url, { params: filters });
}

export async function destroyEquipment(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`equipamentos/${id}`);
}

export async function createEquipment(equipment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/equipamentos", { equipment });
}

export async function updateEquipment(id, equipment) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`/equipamentos/${id}`, { equipment });
}

export async function showEquipment(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/equipamentos/${id}`);
}
