<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon
          for-close
          dispatch="PsychosocialAccompaniments/toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix"
        />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText
          text="Confirmação de Cadastro"
          typeText="custom"
          weight="semibold"
          size="2xl"
          color="ashes-dark"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="mt-5">
        <BaseText
          text="Tem certeza de que deseja registrar o atendimento sem cadastrar/atualizar a matriz de vulnerabilidade?"
          typeText="custom"
          weight="extrabold"
          color="quantum"
          size="lg"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          dispatch="PsychosocialAccompaniments/toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix"
          class="mr-5"
        />
        <a @click="save()" href="javascript:void(0)">
          <AppButtonEmpty text="Sim" type-button="success" />
        </a>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      ignore_matrix_risk: (state) => state.PsychosocialAccompaniments.ignore_matrix_risk,
    }),
  },
  methods: {
    async save() {
      await this.$store.dispatch("PsychosocialAccompaniments/setIgnoreMatrixRisk", true);
      if (this.ignore_matrix_risk) {
        await this.$store.dispatch("PsychosocialAccompaniments/save");
      }
    },
  },
};
</script>
