<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.city ? inscription?.student?.city?.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            inscription?.student?.student_area_exceptions_attributes?.area
              ? inscription?.student?.student_area_exceptions_attributes?.area?.name
              : '-'
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.equipment ? inscription?.equipment?.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.team ? inscription?.team : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student ? inscription?.student?.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            inscription?.beneficiary?.payment_sheet?.reference
              ? inscription?.beneficiary?.payment_sheet?.reference
              : '-'
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.beneficiary?.value ? inscription?.beneficiary?.value : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.status?.name ? inscription?.status?.name : inscription?.status"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    BaseText,
  },
  props: {
    inscription: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 10% 10% 11% 12% 24% 10% 11% 11%;
}
</style>
