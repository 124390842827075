<template>
  <div class="font-system" v-if="permit && open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">Folhas de Pagamentos</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-payment_sheet"
          showIcon
          iconName="add"
          text="Cadastrar Folha de Pagamento"
          dispatch="PaymentSheets/openModalAddPaymentSheet"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status da Folha"
            select-placeholder="Selecione o status"
            :multiple-option="false"
            :value="payment_sheet_params.status"
            :options="payment_sheet_statuses_options"
            :dispatch="setFilterStatus"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="payment_sheet_params.process_number"
            label-text="Pesquisar por Nª do Processo"
            input-placeholder="Pesquisar por Nª do Processo"
            dispatch="PaymentSheets/setFilterProcessNumber"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="payment_sheet_params.reference"
            label-text="Pesquisar por Referência"
            input-placeholder="Pesquisar por Referência"
            dispatch="PaymentSheets/setFilterReference"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-payment_sheets"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="PaymentSheets/loadPaymentSheets"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="payment_sheets.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-payment_sheets"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-payment_sheets">
      <div class="mx-12">
        <TransportationVoucherPaymentSheetsCards :data="payment_sheets" />
      </div>
      <div id="payment_sheets-pagination" class="mx-12 my-6 pb-3" v-if="payment_sheets.length > 0">
        <BasePagination
          :total="totalPaymentSheets"
          :total-per-page="totalPerPage"
          dispatch="PaymentSheets/setPaymentSheetsOffset"
        />
      </div>
      <ModalPaymentSheet :open="modalToggleAddPaymentSheet" />
      <DeletePaymentSheetConfirmation :open="modalToggleDeletePaymentSheet" />
    </div>
  </div>
</template>

<script>
import TransportationVoucherPaymentSheetsCards from "@/views/list/TransportationVoucherPaymentSheetsCards.vue";
import BasePagination from "@/components/BasePagination.vue";
import ModalPaymentSheet from "@/views/benefits/gerenal_modals/ModalPaymentSheet.vue";
import DeletePaymentSheetConfirmation from "@/views/alerts/DeletePaymentSheetConfirmation.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    TransportationVoucherPaymentSheetsCards,
    DeletePaymentSheetConfirmation,
    BaseInput,
    ModalPaymentSheet,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterStatus: "PaymentSheets/setFilterStatus",
      setFilterScholarshipMatrix: "PaymentSheets/setFilterScholarshipMatrix",
      setFilterInstallment: "PaymentSheets/setFilterInstallment",
      setFilterRemittance: "PaymentSheets/setFilterRemittance",
      clearFields() {
        this.$store.dispatch("PaymentSheets/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    async setPaymentSheetType() {
      await this.$store.dispatch("UtilFunctions/loadPaymentSheetTypesOptions");
      if (this.payment_sheet_types_options && this.payment_sheet_types_options.length > 0) {
        const secondOption = this.payment_sheet_types_options[1];
        await this.$store.dispatch("PaymentSheets/setPaymentSheetType", secondOption);
        await this.$store.dispatch("PaymentSheets/setFilterPaymentSheetType", secondOption);
      }
    },
  },

  async created() {
    this.backToTop();
    this.canAcces("payment_sheet_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("payment_sheet_create").then((response) => {
      this.permitCreate = response;
    });
    const season = JSON.parse(localStorage.getItem("season"));
    if (season) {
      await this.$store.dispatch("Advantages/setSeason", season);
    }
    this.$store.dispatch("UtilFunctions/loadScholarshipMatricesForMenus");
    this.$store.dispatch("UtilFunctions/loadPaymentSheetInstallmentOptions");
    this.$store.dispatch("UtilFunctions/loadPaymentSheetRemittanceOptions");
    this.$store.dispatch("UtilFunctions/loadPaymentSheetStatusesOptions");
  },
  async mounted() {
    await this.$store.dispatch("PaymentSheets/clearFields");
    await this.setPaymentSheetType();
    await this.$store.dispatch("PaymentSheets/loadPaymentSheets");
  },
  computed: {
    ...mapState({
      payment_sheets: (state) => state.PaymentSheets.payment_sheets,
      modalToggleAddPaymentSheet: (state) => state.PaymentSheets.modalToggleAddPaymentSheet,
      modalToggleDeletePaymentSheet: (state) => state.PaymentSheets.modalToggleDeletePaymentSheet,
      modalToggleViewPaymentSheet: (state) => state.PaymentSheets.modalToggleViewPaymentSheet,
      offset: (state) => state.PaymentSheets.payment_sheetsOffset,
      totalPerPage: (state) => state.PaymentSheets.payment_sheetsTotalPerPage,
      totalPaymentSheets: (state) => state.PaymentSheets.payment_sheetsTotal,
      payment_sheet: (state) => state.PaymentSheets.payment_sheet,
      payment_sheet_params: (state) => state.PaymentSheets.params,
      scholarship_matrices_for_menus: (state) => state.UtilFunctions.scholarship_matrices_for_menus,
      payment_sheet_installment_options: (state) => state.UtilFunctions.payment_sheet_installment_options,
      payment_sheet_remittance_options: (state) => state.UtilFunctions.payment_sheet_remittance_options,
      payment_sheet_statuses_options: (state) => state.UtilFunctions.payment_sheet_statuses_options,
      payment_sheet_types_options: (state) => state.UtilFunctions.payment_sheet_types_options,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("PaymentSheets/loadPaymentSheets");
      this.backToTop();
    },
  },
};
</script>
<style scoped>
.filters-container {
  margin-top: 10px;
}
</style>
