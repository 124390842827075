<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="verifyStatus()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.season?.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.cpf | VMask('###.###.###-##')"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getSituation()"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.last_psychosocial_accompaniment?.accompaniment_status?.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(inscription?.last_psychosocial_accompaniment?.date_accompaniment)"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.city?.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.neighborhood?.area?.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getEquipmentName()"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex justify-center">
        <router-link to="/psicossocial/pagina_principal">
          <a href="javascript:void(0)" @click="setInscription()" class="group">
            <div
              :class="classButtonAssignment"
              class="transition duration-200 ease-in-out transform hover:scale-105 hover:bg-green-600 bg-green-500 rounded-full p-2 shadow-md flex items-center justify-center"
            >
              <AppMaterialIcon iconName="badge" iconType="round" iconSize="24" class="text-white" />
            </div>
          </a>
        </router-link>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppMaterialIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    inscription: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getEquipmentName() {
      if (this.inscription?.student?.equipment) {
        return this.inscription?.student?.equipment?.name;
      } else {
        return "Sem equipamento cadastrado";
      }
    },
    getSituation() {
      if (this.inscription?.situation_risk) {
        return this.inscription?.situation_risk;
      } else {
        return "-";
      }
    },
    async setInscription() {
      await this.$store.dispatch("PsychosocialAccompaniments/setInscription", this.inscription);
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
  },
  computed: {
    classButtonAssignment() {
      return {
        border: true,
        "shadow-md": true,
        "m-2": true,
        "rounded-full": true,
        "h-15": true,
        "w-20": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-herbal": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 6% 16% 7% 10% 8% 9% 10% 7% 9% 11% 5%;
}
</style>
