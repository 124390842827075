<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="season_previous_inscription?.student?.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="season_previous_inscription?.student?.social_name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="season_previous_inscription.season?.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="season_previous_inscription.is_active_inscription ? 'Sim' : 'Não'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="season_previous_inscription?.student?.city?.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="season_previous_inscription?.neighborhood?.area?.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="verifyStatus(season_previous_inscription.status)"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex gap-4">
        <AppButtonMinimal
          v-if="!season_previous_inscription.is_active_inscription"
          id="btn-activate_inscription"
          text="Ativar Inscrição"
          iconName="check"
          size="small"
          variant="filled"
          color="success"
          :payload="season_previous_inscription"
          dispatch="SeasonPreviousInscriptions/activateInscriptionLoad"
          compact
        />
        <AppButtonMinimal
          v-if="season_previous_inscription.is_active_inscription"
          id="btn-inactivate_inscription"
          text="Inativar Inscrição"
          iconName="cancel"
          size="small"
          variant="filled"
          color="danger"
          :payload="season_previous_inscription"
          dispatch="SeasonPreviousInscriptions/deactivateInscriptionLoad"
          compact
        />
        <AppButtonMinimal
          v-if="season_previous_inscription.is_active_inscription"
          id="btn-transfer_inscription"
          text="Transferir Inscrito(a)"
          iconName="transfer_within_a_station"
          size="small"
          variant="filled"
          color="info"
          :payload="season_previous_inscription"
          dispatch="SeasonPreviousInscriptions/transferInscriptionLoad"
          compact
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseText from "./BaseText.vue";
import AppButtonMinimal from "@/components/AppButtonMinimal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    AppButtonMinimal,
    BaseCard,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    season_previous_inscription: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    verifyStatus(status) {
      return this.$util_function.processInscriptionStatuses(status);
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 22% 15% 13% 6% 10% 11% 7% 16%;
}
</style>
