<template>
  <div class="font-system" v-if="open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">Controle de Pagamento</div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Município"
            select-placeholder="Selecione o município"
            :multiple-option="false"
            :value="payment_control_params.city"
            :options="cities"
            :dispatch="setFilterPaymentControlCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Área"
            select-placeholder="Selecione a área"
            :multiple-option="false"
            :value="payment_control_params.area"
            :options="user_areas"
            :dispatch="setFilterPaymentControlArea"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="equipments"
            select-placeholder="Selecione as opções"
            :label-text="getLabelEquipment()"
            close-on-select
            :multiple-option="false"
            clear-on-select
            show-label
            :value="payment_control_params.equipment"
            :options="equipments_by_area"
            :dispatch="setFilterPaymentControlEquipment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label="code"
            label-text="Turma"
            select-placeholder="Selecione a turma"
            :multiple-option="false"
            :value="payment_control_params.team"
            :options="teams_for_menus"
            :dispatch="setFilterPaymentControlTeam"
          />
        </div>
        <div class="col-span-4">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="payment_control_params.name"
            label-text="Pesquisar por Nome"
            input-placeholder="Pesquisar por Nome"
            dispatch="Advantages/setFilterPaymentControlName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Parcela"
            select-placeholder="Selecione a parcela"
            :multiple-option="false"
            :value="payment_control_params.installment"
            :options="payment_sheet_installment_options"
            :dispatch="setFilterPaymentControlInstallment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Remessa"
            select-placeholder="Selecione a remessa"
            :multiple-option="false"
            :value="payment_control_params.remittance"
            :options="payment_sheet_remittance_options"
            :dispatch="setFilterPaymentControlRemittance"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status"
            select-placeholder="Selecione o status"
            :multiple-option="false"
            :value="payment_control_params.beneficiary_payment_status"
            :options="beneficiaries_inscriptions_status_options"
            :dispatch="setFilterPaymentControlStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Bolsas Pendentes?"
            select-placeholder="Selecione a opção"
            :multiple-option="false"
            :value="payment_control_params.pending_scholarship"
            :options="positive_negative_options"
            :dispatch="setFilterPaymentControlPendingScholarship"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-payment_control"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Advantages/loadInscriptionsForPaymentControl"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-inscriptions"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
      <div class="mt-3 ml-4" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="exportToExcel('inscriptions_spreadsheet')">
          <img
            class="inline-block"
            src="../../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar Planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
    </div>
    <div id="list-inscriptions">
      <div class="mx-12">
        <ScholarshipPaymentControlCards :data="inscriptions" />
      </div>
      <div id="inscriptions -pagination" class="mx-12 my-6 pb-3" v-if="inscriptions.length > 0">
        <BasePagination
          :total="totalInscriptions"
          :total-per-page="totalPerPage"
          dispatch="Advantages/setInscriptionsOffset"
        />
      </div>
    </div>
    <ModalExportSpreadsheets
      :dataSpreadsheet="dataSpreadsheet"
      :modalToggleExport="modalToggleExport"
      :titleModal="titleModal"
      :dispatchToCloseModal="dispatchToCloseModal"
      :json_data="json_data"
      :sheetTabName="sheetTabName"
      :fileName="fileName"
      :variablePreloading="variablePreloading"
      :json_fields="json_fields"
      :dispatchOpenModal="dispatchOpenModal"
    />
  </div>
</template>

<script>
import ScholarshipPaymentControlCards from "@/views/list/ScholarshipPaymentControlCards.vue";
import BasePagination from "@/components/BasePagination.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import BaseText from "@/components/BaseText.vue";
import ModalExportSpreadsheets from "@/components/ModalExportSpreadsheets.vue";

export default {
  components: {
    ModalExportSpreadsheets,
    BaseText,
    FilterComponent,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    ScholarshipPaymentControlCards,
    BaseInput,
  },
  data() {
    return {
      json_fields_inscriptions: {
        Município: "city",
        Área: "area",
        Equipamento: "equipment",
        Turma: "team",
        Nome: "name",
        "Parcela (R$)": "value",
        Parcela: "installment",
        Remessa: "remittance",
        Status: "status",
      },
      type_export: null,
      permit: false,
      permitCreate: false,
      permit_export_spreadsheet: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterPaymentControlCity: "Advantages/setFilterPaymentControlCity",
      setFilterPaymentControlArea: "Advantages/setFilterPaymentControlArea",
      setFilterPaymentControlEquipment: "Advantages/setFilterPaymentControlEquipment",
      setFilterPaymentControlTeam: "Advantages/setFilterPaymentControlTeam",
      setFilterPaymentControlInstallment: "Advantages/setFilterPaymentControlInstallment",
      setFilterPaymentControlRemittance: "Advantages/setFilterPaymentControlRemittance",
      setFilterPaymentControlStatus: "Advantages/setFilterPaymentControlStatus",
      setFilterPaymentControlPendingScholarship: "Advantages/setFilterPaymentControlPendingScholarship",
      clearFields() {
        this.$store.dispatch("Advantages/clearInscriptions");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipment() {
      return this.area?.length > 0
        ? "Selecionar equipamento"
        : "Selecione a(s) área(s) para listar os equipamentos";
    },
    async exportToExcel(export_type) {
      this.type_export = export_type;
      let modalOptions = {};
      switch (export_type) {
        case "inscriptions_spreadsheet":
          modalOptions = {
            title: "Exportar Planilha de Inscritos - Benefícios",
            closeModalDispatch: "Advantages/closeExportInscriptionsSpreadsheetModal",
            sheetTabName: "inscritos_beneficios",
            fileName: "inscritos_beneficios.xls",
            jsonFields: this.json_fields_inscriptions,
            openModalDispatch: "Advantages/loadInscriptionsForExportPaymentControl",
          };
          break;
      }
      await this.$store.dispatch("UtilFunctions/setSelectFieldsToExportFields", modalOptions);
    },
  },

  async created() {
    this.$store.dispatch("Advantages/clearInscriptions");
    const season = JSON.parse(localStorage.getItem("season"));
    if (season) {
      await this.$store.dispatch("Advantages/setSeason", season);
    }
    this.canAcces("inscription_load_for_export_benefits").then((resp) => {
      this.permit_export_spreadsheet = resp;
    });
    this.$store.dispatch("UtilFunctions/loadPaymentSheetInstallmentOptions");
    this.$store.dispatch("UtilFunctions/loadPaymentSheetRemittanceOptions");
    this.$store.dispatch("UtilFunctions/loadTeamsForMenus");
  },
  computed: {
    ...mapState({
      inscriptions: (state) => state.Advantages.inscriptions,
      offset: (state) => state.Advantages.inscriptionsOffset,
      totalPerPage: (state) => state.Advantages.inscriptionsTotalPerPage,
      totalInscriptions: (state) => state.Advantages.inscriptionsTotal,
      payment_control_params: (state) => state.Advantages.params.payment_control,
      area: (state) => state.Advantages.params.payment_control.area,
      beneficiaries_inscriptions_status_options: (state) =>
        state.Advantages.beneficiaries_inscriptions_status_options,
      scholarship_matrices_for_menus: (state) => state.UtilFunctions.scholarship_matrices_for_menus,
      payment_sheet_installment_options: (state) => state.UtilFunctions.payment_sheet_installment_options,
      payment_sheet_remittance_options: (state) => state.UtilFunctions.payment_sheet_remittance_options,
      payment_sheet_statuses_options: (state) => state.UtilFunctions.payment_sheet_statuses_options,
      payment_sheet_types_options: (state) => state.UtilFunctions.payment_sheet_types_options,
      teams_for_menus: (state) => state.UtilFunctions.teams_for_menus,
      user_areas: (state) => state.UtilFunctions.user_areas,
      user_equipments: (state) => state.UtilFunctions.user_equipments,
      cities: (state) => state.UtilFunctions.cities,
      positive_negative_options: (state) => state.UtilFunctions.positive_negative_options,
      equipments_by_area: (state) => state.UtilFunctions.equipments_by_area,
      inscriptions_spreadsheet: (state) => state.Advantages.inscriptions_spreadsheet,
      hasSpreadsheetInscriptionsResponse: (state) => state.Advantages.hasSpreadsheetInscriptionsResponse,
      modalToggleExportInscriptionsSpreadsheet: (state) =>
        state.Advantages.modalToggleExportInscriptionsSpreadsheet,
      json_data_inscription: (state) => state.Advantages.json_data_inscription,
      dataSpreadsheet: (state) => state.SelectFieldsToExport.dataSpreadsheet,
      modalToggleExport: (state) => state.SelectFieldsToExport.modalToggleExport,
      titleModal: (state) => state.SelectFieldsToExport.titleModal,
      dispatchToCloseModal: (state) => state.SelectFieldsToExport.dispatchToCloseModal,
      json_data: (state) => state.SelectFieldsToExport.json_data,
      sheetTabName: (state) => state.SelectFieldsToExport.sheetTabName,
      fileName: (state) => state.SelectFieldsToExport.fileName,
      variablePreloading: (state) => state.SelectFieldsToExport.variablePreloading,
      json_fields: (state) => state.SelectFieldsToExport.json_fields,
      dispatchOpenModal: (state) => state.SelectFieldsToExport.dispatchOpenModal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Advantages/loadInscriptionsForPaymentControl");
      this.backToTop();
    },
    area: function () {
      this.$store.dispatch("UtilFunctions/loadEquipments", this.area);
    },
    inscriptions_spreadsheet: {
      async handler(newValue) {
        if (this.type_export === "inscriptions_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setDataSpreadsheet", newValue);
        }
      },
      deep: true,
    },
    json_data_inscription: {
      async handler(newValue) {
        if (this.type_export === "inscriptions_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setJsonData", newValue);
        }
      },
      deep: true,
    },
    modalToggleExportInscriptionsSpreadsheet: async function () {
      if (this.type_export === "inscriptions_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setModalToggleExport",
          this.modalToggleExportInscriptionsSpreadsheet
        );
      }
    },
    hasSpreadsheetInscriptionsResponse: async function () {
      if (this.type_export === "inscriptions_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setVariablePreloading",
          this.hasSpreadsheetInscriptionsResponse
        );
      }
    },
  },
};
</script>
<style scoped>
.filters-container {
  margin-top: 10px;
}
</style>
