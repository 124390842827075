<template>
  <div class="grid grid-cols-2 gap-4 mt-2">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Comentários e Conclusão do Encontro"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <BaseTextArea
        active
        inputName="Situação apresentada"
        labelText="Situação apresentada"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.meeting_conclusion_description"
        dispatch="PsychosocialAccompaniments/setMeetingConclusionDescription"
      />
    </div>
    <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
      <BaseTextArea
        active
        inputName="accompaniment_comments"
        labelText="Observações técnicas sobre o acompanhamento"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.accompaniment_comments"
        dispatch="PsychosocialAccompaniments/setAccompanimentComments"
      />
    </div>
  </div>
</template>

<script>
import BaseTextArea from "@/components/forms/BaseTextArea.vue";
import Label from "@/components/Label.vue";

export default {
  name: "MeetingConclusion",
  components: {
    BaseTextArea,
    Label,
  },
  props: {
    psychosocial_accompaniment: Object,
  },
};
</script>
