<template>
  <div class="font-system">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Suporte</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-support"
          showIcon
          iconName="add"
          text="Cadastrar Email de Suporte"
          dispatch="Supports/openModalAddSupport"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseLabel text="Filtrar por Email" typeText="custom" weight="normal" :italic="false" size="xs" />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Filtrar poe Email"
            dispatch="Supports/setFilterEmail"
            :value="filter_params.email"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-supports"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Supports/loadSupports"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="supports.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-supports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-supports">
      <div class="mx-12">
        <SupportsCards :data="supports" />
      </div>
      <div id="supports-pagination" class="mx-12 my-6 pb-3" v-if="supports.length > 0">
        <BasePagination
          :total="totalSupports"
          :total-per-page="totalPerPage"
          dispatch="Supports/setSupportsOffset"
        />
      </div>
      <ModalSupport :open="modalToggleAddSupport" />
      <DeleteSupportConfirmation :open="modalToggleDeleteSupport" />
    </div>
  </div>
</template>

<script>
import SupportsCards from "@/views/list/SupportsCards.vue";
import BasePagination from "@/components/BasePagination.vue";
import ModalSupport from "@/views/ModalSupport.vue";
import DeleteSupportConfirmation from "@/views/alerts/DeleteSupportConfirmation.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseLabel from "@/components/BaseLabel.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    SupportsCards,
    DeleteSupportConfirmation,
    BaseInput,
    ModalSupport,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Nome: "name",
        "Nº de Seleções Ofertadas": "total_opportunities_offered",
        "Nº Total de Vagas Ofertadas": "total_vacancies_offered",
        "Nº de Encaminhamentos Registrados": "total_registered_referrals",
        "Nº de Efetivados": "total_registered_referrals_made",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterEmail: "Supports/setFilterEmail",
      clearFields() {
        this.$store.dispatch("Supports/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch("Supports/loadSupportsForExport");
    },
  },

  created() {
    this.canAcces("support_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("support_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      supports: (state) => state.Supports.supports,
      filter_params: (state) => state.Supports.params,
      modalToggleAddSupport: (state) => state.Supports.modalToggleAddSupport,
      modalToggleDeleteSupport: (state) => state.Supports.modalToggleDeleteSupport,
      offset: (state) => state.Supports.supportsOffset,
      totalPerPage: (state) => state.Supports.supportsTotalPerPage,
      totalSupports: (state) => state.Supports.supportsTotal,
      modalToggleExportSupportsSpreadsheet: (state) => state.Supports.modalToggleExportSupportsSpreadsheet,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Supports/loadSupports");
      this.backToTop();
    },
  },
};
</script>
