<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div v-if="student.equipment">
        <AppTextField
          show-label
          label-text="Equipamento"
          :text="student.equipment.name"
          type-text="topic-details"
        />
      </div>
      <div v-else>
        <AppTextField show-label label-text="Equipamento" text="NÃO INFORMADO" type-text="topic-details" />
      </div>
      <div class="col-span-5">
        <BaseText text="dados de inscrição" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Profissional Responsável"
          :text="
            responsible_professional?.userName !== null
              ? responsible_professional?.userName
              : 'Demanda Espontânea'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="data da inscrição"
          :text="inscription.created_at"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="devolutiva"
          :text="inscription.feedback ? inscription.feedback : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-5">
        <BaseText text="dados pessoais" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>

      <div class="col-span-3">
        <AppTextField show-label label-text="nome completo" :text="student.name" type-text="topic-details" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="nome social"
          :text="student.social_name"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="rg" :text="student.rg" type-text="topic-details" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Orgão Expedidor"
          :text="student.dispatching_agency"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Expedição"
          :text="student.expedition"
          type-text="topic-details"
        />
      </div>

      <div>
        <AppTextField
          show-label
          label-text="CPF"
          v-bind:text="student.cpf | VMask('###.###.###-##')"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="cidade onde nasceu"
          :text="student.city_of_birth"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="certidão de nascimento"
          :text="student.birth_certificate | VMask('###### ## ## #### # ##### ### ####### ##')"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          id="book"
          show-label
          label-text="livro"
          :text="student.book"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField id="" show-label label-text="folha" :text="student.page" type-text="topic-details" />
      </div>

      <div class="col-span-2">
        <AppTextField show-label label-text="email" :text="student.email" type-text="topic-details" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="telefone"
          :text="student.cellphone_number | VMask('(##) #####-####')"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="whatsapp de contato"
          :text="student.whatszap | VMask('(##) #####-####')"
          type-text="topic-details"
        />
      </div>

      <div>
        <AppTextField
          show-label
          label-text="data de nascimento"
          :text="formattedBirthdate(student.birthdate)"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="gênero" :text="student.gender" type-text="topic-details" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Se identifica como LGBTQIAPN+"
          :text="student.identifies_as_lgbtqiapn"
          type-text="topic-details"
        />
      </div>
      <div class="">
        <AppTextField
          show-label
          label-text="estado civil"
          :text="getCivilStateName()"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="NIS"
          :text="student.nis ? student.nis : ''"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Carteira de Trabalho"
          :text="student.document_attributes.work_permit"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Carteira de Reservista"
          :text="student.document_attributes.reserve_card"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Título de Eleitor"
          :text="student.document_attributes.voter_title"
          type-text="topic-details"
        />
      </div>

      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Deficiência"
          :text="getDeficiencies()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Pertence a povos originários"
          :text="student.belongs_to_indigenous_peoples"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Possui Filhos?"
          :text="student.has_sons === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Número de Filhos"
          :text="getNumberSons()"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Está gestante?"
          :text="student.is_pregnant === true ? 'Sim' : 'Não'"
          type-text="topic-details"
        />
      </div>
      <div v-if="student.is_pregnant === true">
        <AppTextField
          show-label
          label-text="Mês de gestação"
          :text="student.month_pregnant ? student.month_pregnant.name : '-'"
          type-text="topic-details"
        />
      </div>
    </div>

    <div id="address" class="grid grid-cols-4 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-4">
        <BaseText text="endereço" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="cep"
          :text="student.cep | VMask('#####-###')"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="rua" :text="student.street" type-text="topic-details" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="número"
          :text="student.street_number"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="bairro"
          :text="getNeighborhoodName()"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="comunidade"
          :text="student.community"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="município" :text="getCityName()" type-text="topic-details" />
      </div>
      <div class="col-span-2">
        <AppTextField show-label label-text="estado" :text="getStateName()" type-text="topic-details" />
      </div>
      <div class="col-span-4">
        <AppTextField
          show-label
          label-text="complemento (opcional)"
          :text="student.complement"
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="address" class="grid grid-cols-4 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-4">
        <BaseText text="dados de matrícula" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-5">
        <AppTextField
          show-label
          label-text="Cód. turma atual / mais recente"
          :text="student.last_team_informations ? student.last_team_informations.code : '-'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-3">
        <AppTextField
          show-label
          label-text="Histórico de Turmas"
          :text="student.allCodesTeams && student.allCodesTeams.length > 0 ? student.allCodesTeams : '-'"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Última presença"
          :text="getLastPresence()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Curso de Qualificação"
          :text="
            student.last_team_informations && student.last_team_informations.course
              ? student.last_team_informations.course.name
              : '-'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Status Qualificação"
          :text="student.qualification_status ? student.qualification_status.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="address" class="grid grid-cols-4 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-4">
        <BaseText text="dados de área" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Área [Cadastrada Automaticamente]"
          :text="student.automatic_area ? student.automatic_area.name : '-'"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Área [Cadastrada Manualmente]"
          :text="student.manual_area ? student.manual_area.name : '-'"
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="address" class="grid grid-cols-4 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-4">
        <BaseText text="inserção produtiva" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Encaminhamentos da Equipe de Área"
          :text="
            student.productive_insertion.forwarding_productive_by_area_teams === 0
              ? '0'
              : student.productive_insertion.forwarding_productive_by_area_teams
          "
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Encaminhamentos da Gestão"
          :text="
            student.productive_insertion.forwarding_productive_by_area_management === 0
              ? '0'
              : student.productive_insertion.forwarding_productive_by_area_management
          "
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Inserção Espontânea"
          :text="
            student.productive_insertion.spontaneous_productive_insertion_count === 0
              ? '0'
              : student.productive_insertion.spontaneous_productive_insertion_count
          "
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Efetivado a partir de Encaminhamento?"
          :text="
            student.productive_insertion.forwarding_productive_insertion_by_feedback_type > 0 ? 'Sim' : 'Não'
          "
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="educational-data" class="grid grid-cols-8 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-8">
        <BaseText text="dados escolares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="frequenta a escola?"
          :text="studying()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-3">
        <AppTextField
          show-label
          label-text="se não frequenta a escola qual a sua última série concluída?"
          :text="getCompletedSeriesName()"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Última escola em que estudou?"
          :text="student.last_school_studied ? student.last_school_studied.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Ano da última matrícula"
          :text="student.year_of_last_enrollment ? student.year_of_last_enrollment.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Status Escolar"
          :text="student.educational_status ? student.educational_status.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="complements-data" class="grid grid-cols-8 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-8">
        <BaseText text="dados complementares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-4">
        <AppTextField show-label label-text="tem ocupação" :text="busy()" type-text="topic-details" />
      </div>
      <div class="col-span-4">
        <AppTextField
          show-label
          label-text="Em qual Área tem interesse na sua qualificação profissional? Escolha uma ou mais opções."
          :text="professionalQualificationNames()"
          type-text="topic-details"
        />
      </div>
      <!--      <div class="col-span-4">-->
      <!--        <AppTextField-->
      <!--          show-label-->
      <!--          label-text="qual modalidade esportiva você prefere? Escolha uma ou mais opções."-->
      <!--          :text="sportNames()"-->
      <!--          type-text="topic-details"-->
      <!--        />-->
      <!--      </div>-->
      <div class="col-span-4">
        <AppTextField
          show-label
          label-text="ESCOLHA QUAIS OS TURNOS VOCÊ TEM DISPONÍVEIS PARA PARTICIPAR DO PROJETO"
          :text="shift()"
          type-text="topic-details"
        />
      </div>
      <!-- <div>
        <AppTextField show-label label-text="altura (m)" :text="student.height" type-text="topic-details" />
      </div>
      <div>
        <AppTextField show-label label-text="peso (Kg)" :text="getWeight()" type-text="topic-details" />
      </div> -->
      <!--      <div>-->
      <!--        <AppTextField-->
      <!--          show-label-->
      <!--          label-text="tam. do tênis"-->
      <!--          :text="getShoeSize1()"-->
      <!--          type-text="topic-details"-->
      <!--        />-->
      <!--      </div>-->
      <!-- <div>
        <AppTextField
          show-label
          label-text="tam. do tênis (outro)"
          :text="getShoeSize2()"
          type-text="topic-details"
        />
      </div> -->
      <!--      <div>-->
      <!--        <AppTextField-->
      <!--          show-label-->
      <!--          label-text="tamanho da blusa"-->
      <!--          :text="getShirtSize()"-->
      <!--          type-text="topic-details"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div class="col-span-1">-->
      <!--        <AppTextField-->
      <!--          show-label-->
      <!--          label-text="tamanho da calça"-->
      <!--          :text="getPantsSize()"-->
      <!--          type-text="topic-details"-->
      <!--        />-->
      <!--      </div>-->
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="encaminhamento institucional"
          :text="student.institutional_referral ? student.institutional_referral.name : 'Não informado'"
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="area-interest-data" class="grid grid-cols-8 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-8">
        <BaseText text="área profissional de interesse" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="1ª Opção"
          :text="
            student.first_option_area_of_interest
              ? student.first_option_area_of_interest.name
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="2ª Opção"
          :text="
            student.second_option_area_of_interest
              ? student.second_option_area_of_interest.name
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="3ª Opção"
          :text="
            student.third_option_area_of_interest
              ? student.third_option_area_of_interest.name
              : 'Não informado'
          "
          type-text="topic-details"
        />
      </div>
    </div>
    <div id="bank_account" class="grid grid-cols-4 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-4">
        <BaseText text="Dados de conta bancária" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-3">
        <AppTextField
          show-label
          label-text="Nome e Código do Banco"
          :text="getBankName()"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="Agência" :text="student.agency" type-text="topic-details" />
      </div>
      <div>
        <AppTextField show-label label-text="Conta" :text="student.account" type-text="topic-details" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Dígito"
          :text="student.account_digit"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Tipo de Conta"
          :text="getAccountType()"
          type-text="topic-details"
        />
      </div>
    </div>

    <div id="responsible-data" class="grid grid-cols-1 gap-x-1 gap-y-6 mb-6">
      <div>
        <BaseText text="dados do responsável e familiares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>
    <div
      :key="index"
      v-for="(relative, index) in student.relatives_attributes"
      id="responsible-data"
      class="grid grid-cols-4 gap-x-1 gap-y-6 mb-12"
    >
      <div class="col-span-4">
        <div class="flex items-end">
          <AppTextField
            show-label
            label-text="grau de parentesco"
            :text="getDegreeKinshipName(relative.degree_kinship)"
            type-text="topic-details"
          />
          <div class="text-container" v-if="relative.responsible || relative.reference_person">
            <BaseText
              v-if="relative.responsible"
              color="burnedYellow"
              text="Responsável Atual"
              type-text="custom"
            />
            <BaseText
              v-if="relative.reference_person"
              color="burnedYellow"
              text="Pessoa de referência"
              type-text="custom"
            />
          </div>
        </div>
      </div>
      <div class="col-span-5" v-if="relative.degree_kinship?.id === 16">
        <AppTextField
          show-label
          label-text="especificação do grau de parentesco"
          :text="relative.specification"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-2">
        <AppTextField show-label label-text="nome completo" :text="relative.name" type-text="topic-details" />
      </div>
      <div>
        <AppTextField show-label label-text="rg" :text="relative.rg" type-text="topic-details" />
      </div>
      <div>
        <AppTextField show-label label-text="cpf" :text="relative.cpf | VMask('###.###.###-##')" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="telefone"
          :text="relative.cellphone_number | VMask('(##) #####-####')"
        />
      </div>
      <div class="col-span-2">
        <AppTextField show-label label-text="e-mail" :text="relative.email" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="data de nascimento"
          :text="formattedBirthdate(relative.birth_date)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppTextField from "../components/AppTextField.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseText,
    AppTextField,
  },
  computed: {
    ...mapState({
      student: (state) => state.Student,
      responsible_professional: (state) => state.Inscription.responsible_professional,
      inscription: (state) => state.Inscription,
    }),
  },
  methods: {
    formattedBirthdate: (birthdate) => {
      if (birthdate) {
        return birthdate.split("-").reverse().join("/");
      }
    },
    studying() {
      return this.student.studying ? "Sim" : "Não";
    },
    busy() {
      return this.student.busy ? "Sim" : "Não";
    },
    getLastPresence() {
      let array_dates = [];
      if (this.student?.teams_attributes) {
        this.student.teams_attributes.forEach((team) => {
          if (team.last_presence.updated_at) {
            array_dates.push(team.last_presence.updated_at);
          }
        });
      }
      return array_dates.length > 0 ? this.$util_function.getLastPresence(array_dates) : "-";
    },
    professionalQualificationNames() {
      return this.student.professional_qualifications_attributes
        .map((professional_qualification) => professional_qualification.description)
        .join(", ");
    },
    sportNames() {
      return this.student.sports_attributes.map((sport) => sport.description).join(", ");
    },
    shift() {
      let shifts = [];

      if (this.student.morning_shift) {
        shifts.push("Manhã");
      }

      if (this.student.afternoon_shift) {
        shifts.push("Tarde");
      }

      if (this.student.night_shift) {
        shifts.push("Noite");
      }

      return shifts.join(", ");
    },
    getDeficiencies() {
      let txt;

      txt = this.student.deficiencies_attributes.map((obj) => {
        return obj.name;
      });

      return txt.join(", ");
    },
    getStateName() {
      if (this.student.state) {
        return this.student.state.name;
      } else {
        return "";
      }
    },
    getCivilStateName() {
      if (this.student.civil_state) {
        return this.student.civil_state.name;
      } else {
        return "";
      }
    },
    getCityName() {
      if (this.student.city) {
        return this.student.city.name;
      } else {
        return "";
      }
    },
    getNeighborhoodName() {
      if (this.student.neighborhood) {
        return this.student.neighborhood.name;
      } else {
        return "";
      }
    },
    getCompletedSeriesName() {
      if (this.student.completed_series) {
        return this.student.completed_series.name;
      } else {
        return "";
      }
    },
    getShoeSize1() {
      if (this.student.shoe_size1) {
        return String(this.student.shoe_size1.name);
      } else {
        return "";
      }
    },
    getShoeSize2() {
      if (this.student.shoe_size2) {
        return String(this.student.shoe_size2.name);
      } else {
        return "";
      }
    },
    getShirtSize() {
      if (this.student.shirt_size) {
        return this.student.shirt_size.name;
      } else {
        return "";
      }
    },
    getPantsSize() {
      if (this.student.pants_size) {
        return this.student.pants_size.name;
      } else {
        return "";
      }
    },
    getBankName() {
      if (this.student.bank_name) {
        return this.student.bank_name.name;
      } else {
        return "";
      }
    },
    getAccountType() {
      if (this.student.account_type) {
        return this.student.account_type.name;
      } else {
        return "";
      }
    },
    getWeight() {
      if (this.student.weight) {
        return String(this.student.weight);
      } else {
        return "";
      }
    },
    getDegreeKinshipName(degreeKinship) {
      if (degreeKinship) {
        return degreeKinship.name;
      } else {
        return "";
      }
    },
    getNumberSons() {
      let nr_sons = 0;
      this.student.relatives_attributes.forEach((std) => {
        if (std.degree_kinship.id === 5 || std.degree_kinship.id === 6) {
          nr_sons++;
        }
      });
      return String(nr_sons);
    },
  },
};
</script>
<style>
.text-container {
  display: flex;
  gap: 16px;
}
</style>
