<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon for-close dispatch="Default/toggleModalForgotPassword" />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText
          text="Esqueci minha senha"
          typeText="custom"
          weight="semibold"
          size="2xl"
          color="ashes-dark"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="mt-5">
        <BaseText :text="getSupportsList()" typeText="custom" weight="extrabold" color="quantum" size="lg" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty text="OK" type-button="success" dispatch="Default/toggleModalForgotPassword" />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },
  created() {
    this.$store.dispatch("Supports/loadSupports");
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      supports: (state) => state.Supports.supports,
    }),
  },
  methods: {
    getSupportsList() {
      const supports = this.supports.map((support) => support.email);
      return `Entre em contato com o administrador do sistema para que possa recuperar sua senha.
              Contato(s): ${supports}`;
    },
  },
};
</script>
