<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Sanções Disciplinares"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <div
        class="grid grid-cols-2 gap-4 mt-2 border-b-2 border-gray-300"
        v-for="(disciplinary_sanction, index) in visibleDisciplinarySanctions"
        :key="index"
      >
        <div class="col-span-1">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Sanção"
            :multiple-option="false"
            :value="disciplinary_sanction.sanction_type"
            :options="mountDisciplinarySanctionTypes(index, 'sanction_type')"
            :dispatch="updateDisciplinarySanction"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Status do Cumprimento"
            :multiple-option="false"
            :value="disciplinary_sanction.sanction_status"
            :options="mountDisciplinarySanctionStatus(index, 'sanction_status')"
            :dispatch="updateDisciplinarySanction"
          />
        </div>
        <div v-if="mustShowDisciplinaryPenaltys(index)">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Pena"
            :multiple-option="false"
            :value="disciplinary_sanction.penalty_type"
            :options="mountDisciplinaryPenaltyTypes(index, 'penalty_type')"
            :dispatch="updateDisciplinarySanction"
          />
        </div>
        <div v-if="mustShowDisciplinaryMeasures(index)" class="col-span-2">
          <Multiselect
            :close-on-select="false"
            show-label
            clear-on-select
            select-placeholder="Selecione as opções desejadas"
            label-text="Medida"
            trackBy="measure_id"
            :multiple-option="true"
            :value="visibleDisciplinarySanctionsMeasures(index)"
            :options="mountMeasures(index, 'disciplinary_sanctions_measures_attributes')"
            :dispatch="setDisciplinarySanctionMeasure"
          />
        </div>
        <div v-if="mustShowDisciplinaryComplianceModes(index)">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Meio"
            :multiple-option="false"
            :value="disciplinary_sanction.compliance_mode"
            :options="mountDisciplinaryComplianceModes(index, 'compliance_mode')"
            :dispatch="updateDisciplinarySanction"
          />
        </div>
        <div v-if="mustShowDisciplinaryCompletionTimes(index)">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Tempo desde a Finalização"
            :multiple-option="false"
            :value="disciplinary_sanction.completion_time"
            :options="mountDisciplinaryCompletionTimes(index, 'completion_time')"
            :dispatch="updateDisciplinarySanction"
          />
        </div>
        <div v-if="mustShowDisciplinaryCompletionForecasts(index)">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Previsão de Finalização"
            :multiple-option="false"
            :value="disciplinary_sanction.completion_forecast"
            :options="mountDisciplinaryCompletionForecasts(index, 'completion_forecast')"
            :dispatch="updateDisciplinarySanction"
          />
        </div>
        <div v-if="mustShowDisciplinaryComplianceLocation(index)">
          <BaseInput
            input-name="compliance_location"
            input-type="text"
            show-label
            required
            label-text="Local de Cumprimento"
            :value="disciplinary_sanction.compliance_location"
            :payload="{ index, field: 'compliance_location', input: 'input' }"
            dispatch="PsychosocialAccompaniments/updateDisciplinarySanction"
          />
        </div>
        <div>
          <a
            v-if="index >= 1"
            href="javascript:void(0)"
            @click="removeDisciplinarySanction(index)"
            class="ml-2 text-burnedYellow underline"
          >
            <AppMaterialIcon icon-name="delete" icon-size="36" />
          </a>
        </div>
      </div>
    </div>
    <div class="text-left mt-4">
      <a href="javascript:void(0)" class="text-burnedYellow underline" @click="addDisciplinarySanction">
        <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-bottom" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Multiselect from "@/components/forms/Multiselect.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import Label from "@/components/Label.vue";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";

export default {
  name: "DisciplinarySanctions",
  components: {
    Multiselect,
    BaseInput,
    Label,
    AppMaterialIcon,
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      disciplinary_sanctions_attributes: (state) =>
        state.PsychosocialAccompaniments.disciplinary_sanctions_attributes,
    }),
    visibleDisciplinarySanctions() {
      return this.disciplinary_sanctions_attributes.filter(
        (disciplinary_sanction) => !disciplinary_sanction._destroy
      );
    },
  },
  methods: {
    ...mapActions({
      updateDisciplinarySanction: "PsychosocialAccompaniments/updateDisciplinarySanction",
      setDisciplinarySanctionMeasure: "PsychosocialAccompaniments/setDisciplinarySanctionMeasure",
      removeDisciplinarySanction: "PsychosocialAccompaniments/removeDisciplinarySanction",
      addDisciplinarySanction: "PsychosocialAccompaniments/addDisciplinarySanction",
    }),
    mountDisciplinarySanctionTypes(index, field) {
      return this.$store.state.UtilFunctions.disciplinary_sanction_types.map((sanction_type) => {
        return { index, field, ...sanction_type };
      });
    },
    mountDisciplinarySanctionStatus(index, field) {
      return this.$store.state.UtilFunctions.disciplinary_sanction_status.map((sanction_status) => {
        return { index, field, ...sanction_status };
      });
    },
    mountDisciplinaryPenaltyTypes(index, field) {
      return this.$store.state.UtilFunctions.disciplinary_sanction_penalty_types.map((penalty_type) => {
        return { index, field, ...penalty_type };
      });
    },
    mountMeasures(index, field) {
      return this.$store.state.UtilFunctions.measures_for_menus.map((measure) => {
        return { index, field, measure_id: measure.id, name: measure.name };
      });
    },
    mountDisciplinaryComplianceModes(index, field) {
      return this.$store.state.UtilFunctions.disciplinary_sanction_compliance_modes.map((compliance_mode) => {
        return { index, field, ...compliance_mode };
      });
    },
    mountDisciplinaryCompletionTimes(index, field) {
      return this.$store.state.UtilFunctions.disciplinary_sanction_completion_times.map((completion_time) => {
        return { index, field, ...completion_time };
      });
    },
    mountDisciplinaryCompletionForecasts(index, field) {
      return this.$store.state.UtilFunctions.disciplinary_sanction_completion_forecasts.map(
        (completion_forecast) => {
          return { index, field, ...completion_forecast };
        }
      );
    },
    mustShowDisciplinaryPenaltys(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return sanction_type?.id === 2 && sanction_status;
    },
    mustShowDisciplinaryMeasures(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return sanction_type?.id === 1 && sanction_status;
    },
    mustShowDisciplinaryComplianceModes(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status, penalty_type } = disciplinarySanction;
      return (
        (sanction_type?.id === 0 && sanction_status) ||
        (sanction_type?.id === 2 && sanction_status && penalty_type?.id === 0)
      );
    },
    mustShowDisciplinaryCompletionTimes(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return sanction_type && sanction_status?.id === 0;
    },
    mustShowDisciplinaryCompletionForecasts(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { sanction_type, sanction_status } = disciplinarySanction;
      return sanction_type && sanction_status?.id === 1;
    },
    mustShowDisciplinaryComplianceLocation(index) {
      const disciplinarySanction = this.visibleDisciplinarySanctions[index];
      const { compliance_mode } = disciplinarySanction;
      return compliance_mode?.id === 2;
    },
    visibleDisciplinarySanctionsMeasures(index) {
      return this.disciplinary_sanctions_attributes[index].disciplinary_sanctions_measures_attributes.filter(
        (disciplinary_sanctions_measure) => !disciplinary_sanctions_measure._destroy
      );
    },
  },
};
</script>
