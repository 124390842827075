import Vue from "vue";
import {
  createUser,
  updateUser,
  deleteUSer,
  resetPassword,
  updatePassword,
  createUserArea,
  getUser,
  destroyUserArea,
} from "../../service/UserApi";
import { createAtendent, updateAtendent, destroyAtendent } from "../../service/AtendentPsychosocialApi";
import { getProfile } from "../../service/AccessControlApi";
import { createTeacher, updateTeacher, destroyTeacher } from "../../service/TeacherApi";
import { createArticulator, updateArticulator, destroyArticulator } from "../../service/ArticulatorsApi";
import router from "@/router";

const state = {
  user: {
    id: null,
    name: null,
    email: null,
    cpf: null,
    phone_number: null,
    password: null,
    password_confirmation: null,
    confirmed_at: null,
    active: true,
    number_cress_crp: null,
    area: [],
    roles_attributes: [],
    cities_attributes: [],
    equipments_attributes: [],
  },
  emptyUser: {
    id: null,
    name: null,
    email: null,
    cpf: null,
    phone_number: null,
    password: null,
    password_confirmation: null,
    active: true,
    number_cress_crp: null,
    area: [],
    roles_attributes: [],
    cities_attributes: [],
    equipments_attributes: [],
  },
  modalToggle: false,
  modalConfirmDeleteUser: false,
  modalResetPassword: false,
  modalActivation: false,
  errorMessages: [],
  areasData: [],
  user_areas_toEdit: [],
  userId: 0,
};

const mutations = {
  setUserAreasToEdit: (state, item) => {
    state.user_areas_toEdit.push(item);
  },
  setAreaMutation: (state, area) => {
    state.user.area = area;
  },
  clearAreaToEdit: (state) => {
    state.user_areas_toEdit = [];
  },
  toggleModalDeleteConfirmation: (state) => {
    state.modalConfirmDeleteUser = !state.modalConfirmDeleteUser;
  },
  userForDeletion: (state, payload) => {
    state.userId = payload;
  },
  setIdMutation: (state, id) => {
    state.user.id = id;
  },
  setNameMutation: (state, name) => {
    state.user.name = name;
  },
  setEmailMutation: (state, email) => {
    state.user.email = email;
  },
  setPasswordMutation: (state, password) => {
    state.user.password = password;
  },
  setPasswordConfirmationMutation: (state, password_confirmation) => {
    state.user.password_confirmation = password_confirmation;
  },
  setCpfMutation: (state, cpf) => {
    state.user.cpf = cpf;
  },
  setPhoneNumberMutation: (state, phone_number) => {
    state.user.phone_number = phone_number;
  },
  clearUserMutation: (state) => {
    state.user = Object.assign(state.user, state.emptyUser);
  },
  toggleModalMutation: (state) => {
    state.modalToggle = !state.modalToggle;
  },
  toggleModalResetPasswordMutation: (state) => {
    state.modalResetPassword = !state.modalResetPassword;
  },
  toggleModalActivationMutation: (state) => {
    state.modalActivation = !state.modalActivation;
  },
  setUserMutation: (state, user) => {
    state.user = Object.assign(state.user, user);
    state.user.roles_attributes = user.ability_profile;
  },
  setRoleMutation: (state, role) => {
    state.user.roles_attributes = [role];
  },
  setActiveMutation: (state, active) => {
    state.user.active = active;
  },
  setNumberCressCrpMutation: (state, number_cress_crp) => {
    state.user.number_cress_crp = number_cress_crp;
  },
  setCitiesAttributesMutation: (state, cities) => {
    state.user.cities_attributes = cities;
  },
  setEquipmentsAttributesMutation: (state, equipments) => {
    state.user.equipments_attributes = equipments;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function createOtherProfiles(response, responseCreateUser, dispatch, commit, objUser) {
  getProfile(response.data.data.ability_profile_id)
    .then((response) => {
      const city = localStorage.city_id;
      if (response.data.description === "Instrutor") {
        const objTeacher = {
          user_id: responseCreateUser.id,
          name: responseCreateUser.name,
          cpf: responseCreateUser.cpf,
          cellphone_number: responseCreateUser.phone_number,
          city_id: city,
          email: responseCreateUser.email,
        };
        createTeacher(objTeacher)
          .then(() => {})
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o instrutor! " +
                "Por favor verifique se todos os campos com ( * ) foram preenchidos"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      } else if (response.data.description === "Psicossocial") {
        const objAttendant = {
          user_id: responseCreateUser.id,
          name: responseCreateUser.name,
          cpf: responseCreateUser.cpf,
          cellphone_number: responseCreateUser.phone_number,
          email: responseCreateUser.email,
          city_id: city,
          number_cress_crp: objUser.number_cress_crp,
        };
        createAtendent(objAttendant)
          .then(() => {})
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o Técnico! " +
                "Por favor verifique se todos os campos com ( * ) foram preenchidos"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      } else if (response.data.description === "Articulador") {
        const objArticulator = {
          name: responseCreateUser.name,
          cpf: responseCreateUser.cpf,
          cellphone_number: responseCreateUser.phone_number,
          email: responseCreateUser.email,
          city_id: city,
          user_id: responseCreateUser.id,
        };
        createArticulator(objArticulator)
          .then(() => {
            dispatch("Articulators/loadArticulators", null, { root: true });
          })
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o Articulador! " +
                "Por favor verifique se todos os campos com ( * ) foram preenchidos"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      }
    })
    .catch((e) => {
      console.log(e.response);
    });
}

function updateOtherProfiles(response, responseUpdateUser, dispatch, commit, objUser) {
  getProfile(response.data.ability_profile_id)
    .then((response) => {
      const city = localStorage.city_id;
      if (response.data.description === "Instrutor") {
        const objTeacher = {
          user_id: responseUpdateUser.id,
          name: responseUpdateUser.name,
          cpf: responseUpdateUser.cpf,
          cellphone_number: responseUpdateUser.phone_number,
          city_id: city,
          email: responseUpdateUser.email,
        };
        updateTeacher(responseUpdateUser.teacher.id, objTeacher)
          .then(() => {})
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o instrutor! " +
                "Por favor verifique se todos os campos com ( * ) foram preenchidos"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      } else if (response.data.description === "Psicossocial") {
        const objAttendant = {
          user_id: responseUpdateUser.id,
          name: responseUpdateUser.name,
          cpf: responseUpdateUser.cpf,
          cellphone_number: responseUpdateUser.phone_number ? responseUpdateUser.phone_number : "",
          email: responseUpdateUser.email,
          city_id: city,
          number_cress_crp: objUser.number_cress_crp,
        };
        updateAtendent(responseUpdateUser.psychosocial_attendant.id, objAttendant)
          .then(() => {})
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o Técnico! " +
                "Por favor verifique se todos os campos com ( * ) foram preenchidos"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      } else if (response.data.description === "Articulador") {
        const objtArticulator = {
          user_id: responseUpdateUser.id,
          name: responseUpdateUser.name,
          cpf: responseUpdateUser.cpf,
          cellphone_number: responseUpdateUser.phone_number ? responseUpdateUser.phone_number : "",
          email: responseUpdateUser.email,
          city_id: city,
        };
        updateArticulator(responseUpdateUser.articulator.id, objtArticulator)
          .then(() => {
            dispatch("Articulators/loadArticulators", null, { root: true });
          })
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel atualizar as informações do Articulador(a)! " +
                "Por favor verifique se todos os campos com ( * ) foram preenchidos"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      }
    })
    .catch((e) => {
      console.log(e.response);
    });
}

function detroyTeacher(resp, rootState, dispatch, commit) {
  destroyTeacher(resp.data.teacher.id)
    .then(() => {
      const teachers = rootState.Teachers.teachers.filter((e) => {
        return e.id !== resp.data.teacher.id;
      });
      dispatch("Teachers/setTeachers", teachers, { root: true });
    })
    .catch((e) => {
      Vue.$toast.error("Não foi possivel deletar o Instrutor! ");
      console.log(e);
      commit("setErrorMessagesMutation", e.response.data.errors);
    });
}

function deleteAtendent(resp, dispatch, commit) {
  destroyAtendent(resp.data.psychosocial_attendant.id)
    .then(() => {
      dispatch("Atendents/loadAtendents", null, {
        root: true,
      });
    })
    .catch((e) => {
      Vue.$toast.error("Não foi possivel deletar o Técnico!");
      console.log(e);
      commit("setErrorMessagesMutation", e.response.data.errors);
    });
}

function deleteArticulator(resp, dispatch, commit) {
  destroyArticulator(resp.data.articulator.id)
    .then(() => {
      dispatch("Articulators/loadArticulators", null, {
        root: true,
      });
    })
    .catch((e) => {
      Vue.$toast.error("Não foi possivel deletar o Articulador(a)!");
      console.log(e);
      commit("setErrorMessagesMutation", e.response.data.errors);
    });
}

const actions = {
  openCloseDeleteUserConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteConfirmation");
    if (state.modalConfirmDeleteUser) {
      commit("userForDeletion", payload.id);
    } else {
      commit("userForDeletion", null);
    }
  },
  setArea: ({ commit }, payload) => {
    commit("setAreaMutation", payload);
    commit("setEquipmentsAttributesMutation", []);
  },
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },
  setPassword: ({ commit }, payload) => {
    commit("setPasswordMutation", payload.value);
  },
  setPasswordConfirmation: ({ commit }, payload) => {
    commit("setPasswordConfirmationMutation", payload.value);
  },
  setCpf: ({ commit }, payload) => {
    commit("setCpfMutation", payload.value);
  },
  setPhoneNumber: ({ commit }, payload) => {
    commit("setPhoneNumberMutation", payload.value);
  },
  setActive: ({ commit }, payload) => {
    commit("setActiveMutation", payload.value);
  },
  setNumberCressCrp: ({ commit }, payload) => {
    commit("setNumberCressCrpMutation", payload.value);
  },
  setCitiesAttributes: ({ commit }, payload) => {
    commit("setCitiesAttributesMutation", payload);
  },
  setEquipmentsAttributes: ({ commit }, payload) => {
    commit("setEquipmentsAttributesMutation", payload);
  },
  openModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("clearAreaToEdit");
    commit("toggleModalMutation");
    commit("clearUserMutation");
    commit("setErrorMessagesMutation", []);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
    if (payload.user_areas && payload.user_areas.length > 0) {
      payload.user_areas.forEach((item) => {
        commit("setUserAreasToEdit", item);
      });
    }
  },
  openEditModal: ({ dispatch }, payload) => {
    dispatch("loadUser", payload);
    dispatch("openModal", payload);
  },
  openModalResetPassword: ({ dispatch, commit }, payload) => {
    dispatch("setUser", payload);
    commit("toggleModalResetPasswordMutation");
  },
  openModalActivation: ({ dispatch, commit }, payload) => {
    dispatch("setUser", payload);
    commit("toggleModalActivationMutation");
  },
  closeModalActivation: ({ commit }) => {
    commit("clearUserMutation");
    commit("toggleModalActivationMutation");
  },
  closeModalResetPassword: ({ commit }) => {
    commit("toggleModalResetPasswordMutation");
    commit("setPasswordMutation", null);
    commit("setPasswordConfirmationMutation", null);
    commit("setErrorMessagesMutation", []);
  },
  setRole: ({ commit }, payload) => {
    commit("setRoleMutation", { ability_profile_id: payload.id, name: payload.name });
    commit("setNumberCressCrpMutation", null);
  },
  loadUser: ({ dispatch }, payload) => {
    getUser(payload.id ? payload.id : payload.userId).then((response) => {
      dispatch("setUser", response.data.user);
    });
  },
  save: ({ commit, dispatch }) => {
    const user = state.user;
    const objUser = {
      id: user.id,
      name: user.name,
      email: user.email,
      active: user.active,
      cpf: user.cpf,
      phone_number: user.phone_number,
      number_cress_crp: user.number_cress_crp,
      confirmed_at: new Date(),
      ability_profile_id:
        user.roles_attributes.length > 0
          ? user.roles_attributes[0].ability_profile_id
          : user.ability_profile.id,
      cities_attributes: user.cities_attributes,
      equipments_attributes: user.equipments_attributes,
    };
    createUser(objUser)
      .then((response) => {
        commit("setIdMutation", response.data.data.id);
        state.user.area.forEach((area) => {
          const areaObj = {
            user_id: state.user.id,
            area_id: area.id,
          };
          createUserArea(areaObj).then(() => {});
        });

        let responseCreateUser = response.data.data;
        createOtherProfiles(response, responseCreateUser, dispatch, commit, objUser);

        Vue.$toast.success("Criado com sucesso");
        dispatch("Users/addUser", response.data.user, { root: true });
        dispatch("closeModal");
        dispatch("Users/loadUsers", null, { root: true });
      })
      .catch((e) => {
        console.log(e.response);
        Vue.$toast.error(
          "Não foi possivel cadastrar o usuário! " + e.response.data.errors.full_messages + "!"
        );
        console.log(e);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  update: ({ commit, dispatch, state }) => {
    const user = state.user;
    let confirmed_at_toSend;
    if (user.active) {
      confirmed_at_toSend = new Date();
    } else {
      confirmed_at_toSend = null;
    }
    const objUser = {
      id: user.id,
      name: user.name,
      email: user.email,
      active: user.active,
      cpf: user.cpf,
      phone_number: user.phone_number,
      number_cress_crp: user.number_cress_crp,
      password: user.password,
      password_confirmation: user.password_confirmation,
      confirmation_sent_at: confirmed_at_toSend,
      confirmed_at: confirmed_at_toSend,
      ability_profile_id:
        user.roles_attributes.length > 0
          ? user.roles_attributes[0].ability_profile_id
          : user.ability_profile.id,
      cities_attributes: user.cities_attributes,
      equipments_attributes: user.equipments_attributes,
    };
    updateUser(objUser.id, objUser)
      .then((response) => {
        let user_areas = [];
        state.user_areas_toEdit.forEach((item) => {
          let array = {
            id: item.id,
            user_id: item.user_id,
            area_id: item.area_id,
          };
          user_areas.push(array);
        });
        let areas_data = [];

        state.user.area.forEach((item) => {
          let array = {
            area_id: item.id,
            user_id: objUser.id,
          };
          areas_data.push(array);
        });

        const objCreate = areas_data.filter(
          (edit) => !user_areas.find((item) => item.area_id === edit.area_id)
        );

        const objDestroy = user_areas.filter(
          (edit) => !areas_data.find((item) => item.area_id === edit.area_id)
        );

        if (objCreate.length > 0) {
          let objToSend = [];
          objCreate.forEach((item) => {
            let array = {
              user_id: item.user_id,
              area_id: item.area_id,
            };
            objToSend.push(array);
          });
          objToSend.forEach((item) => {
            createUserArea(item)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        if (objDestroy.length > 0) {
          objDestroy.forEach((item) => {
            destroyUserArea(item.id)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        user_areas = [];
        areas_data = [];

        let responseUpdateUser = response.data;
        updateOtherProfiles(response, responseUpdateUser, dispatch, commit, objUser);

        Vue.$toast.success("Atualizado com sucesso");
        dispatch("Users/loadUsers", null, { root: true });
        if (state.modalActivation) {
          dispatch("User/closeModalActivation", null, { root: true });
        }
        if (state.modalToggle) {
          dispatch("closeModal");
        }
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar o usuário! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
        console.log(e);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  deleteUSer: ({ commit, dispatch, state, rootState }) => {
    const userId = state.userId;
    deleteUSer(userId).then((resp) => {
      if (resp.data.teacher) {
        detroyTeacher(resp, rootState, dispatch, commit);
      }
      if (resp.data.psychosocial_attendant) {
        deleteAtendent(resp, dispatch, commit);
      }
      if (resp.data.articulator) {
        deleteArticulator(resp, dispatch, commit);
      }
      if (resp.status === 201) {
        Vue.$toast.success("Usuário deletado com sucesso");
        dispatch("Users/loadUsers", null, { root: true });
        commit("toggleModalDeleteConfirmation");
      }
    });
  },
  resetPassword: ({ commit, dispatch }, payload) => {
    resetPassword(payload?.id)
      .then(() => {
        Vue.$toast.success("Senha resetada com sucesso!");
        dispatch("Users/loadUsers", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possível resetar a senha!");
        console.log(e);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  updatePassword: ({ commit, dispatch }) => {
    updatePassword(state.user.id, state.user.password, state.user.password_confirmation)
      .then(() => {
        Vue.$toast.success("Senha atualizada com sucesso!");
        localStorage.clear();

        commit("setPasswordMutation", null);
        commit("setPasswordConfirmationMutation", null);

        router.push({ name: "sign_in_admin" }).then(() => {
          window.location.reload();
        });

        dispatch("Users/loadUsers", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possível atualizar a senha!");
        console.log(e);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
            return error.message;
          } else {
            return null;
          }

        case "invalid_cpf":
          return error.message;

        case "invalid":
          return error.message;

        case "taken":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
