// import { getAreasByCity } from "../../service/AreaApi";
import { getEquipmentByAreaId } from "../../service/EquipmentApi";
import { getFacilitiesByForwardingPolicy } from "../../service/FacilitiesApi";
import { getSeasonsForMenus } from "../../service/SeasonApi";
import { getArticulators } from "../../service/ArticulatorsApi";
import { getInscriptionByIdAndResource, getInscriptionForGeneralMenus } from "../../service/InscriptionApi";
import {
  createVacancyRequest,
  destroyVacancyRequest,
  getVacancyRequestById,
  getVacancyRequestByInscription,
  getVacancyRequests,
  getVacancyRequestsInterestSeries,
  getVacancyRequestsInterestShifts,
  getVacancyRequestsSchoolsNetwork,
  getVacancyRequestsTypes,
  updateVacancyRequest,
} from "../../service/VacancyRequestsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";

Vue.component("downloadExcel", JsonExcel);

const state = {
  vacancy_request: {
    id: null,
    inscription_id: null,
    articulator: null,
    city_id: null,
    user_id: null,
    vacancy_type: null,
    cpf_responsible_young_person: null,
    address_responsible_young_person: null,
    updated_contact_young_person: null,
    interest_school_id: null,
    interest_serie: null,
    interest_shift: null,
    school_network: null,
    case_description: null,
    interventions_performed: null,
    forwarding_type: null,
    full_name_responsible_child: null,
    cpf_responsible_child: null,
    child_full_name: null,
    child_birthdate: null,
    address_responsible_child: null,
    contact_responsible_child: null,
    interest_kindergarten: null,
    feedback: null,
    accepted_request: false,
  },
  vacancy_requests: [],
  vacancy_types: [],
  interest_series: [],
  interest_shifts: [],
  school_networks: [],
  user_areas: [],
  user_equipments: [],
  inscriptions: [],
  seasons: [],
  equipments_vacancy_requests: [],
  articulators: [],
  facilities: [],
  vacancy_requests_spreadsheet: [],
  inscriptionsForGeneralMenus: [],

  vacancyRequestId: null,
  vacancy_type: null,
  school_network: null,
  season: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,
  user: null,
  inscription: null,
  interest_serie: null,
  interest_shift: null,
  articulator: null,
  facility: null,
  city: null,
  state: null,

  modalToggleAddVacancyRequest: false,
  modalToggleDeleteVacancyRequest: false,
  modalToggleViewVacancyRequest: false,
  modalToggleExportVacancyRequestsSpreadsheet: false,

  hasSpreadsheetVacancyRequestsResponse: true,
  json_data_vacancy_request: null,

  inscriptionsTotal: 0,
  totalPerPage: 10,
  offset: 0,

  vacancyRequestsTotal: 0,
  vacancyRequestsTotalPerPage: 10,
  vacancyRequestsOffset: 0,

  vacancyRequestsByInscriptionTotal: 0,
  vacancyRequestsByInscriptionTotalPerPage: 10,
  vacancyRequestsByInscriptionOffset: 0,

  params: {
    articulator: null,
    vacancy_type: null,
    city: null,
    state: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadVacancyRequests: (state, vacancy_requests) => {
    state.vacancy_requests = vacancy_requests;
  },
  loadArticulators: (state, articulators) => {
    state.articulators = articulators;
  },
  loadVacancyTypes: (state, vacancy_types) => {
    state.vacancy_types = vacancy_types;
  },
  loadInterestSeries: (state, interest_series) => {
    state.interest_series = interest_series;
  },
  loadInterestShifts: (state, interest_shifts) => {
    state.interest_shifts = interest_shifts;
  },
  loadSchoolNetworks: (state, school_networks) => {
    state.school_networks = school_networks;
  },
  loadFacilities: (state, facilities) => {
    state.facilities = facilities;
  },
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
    state.offset = 0;
  },
  setEquipmentsForSchoolReinsertionMutation: (state, equipments_school_reinsertions) => {
    state.equipments_school_reinsertions = equipments_school_reinsertions;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.vacancy_request.id = id;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
    state.vacancy_request.inscription_id = inscription.id;
    // sessionStorage.setItem("inscription_id", inscription.id);
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setInscriptionsMutation: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setArticulatorMutation: (state, articulator) => {
    state.vacancy_request.articulator = articulator;
  },
  setInterestSerieMutation: (state, interest_serie) => {
    state.interest_serie = interest_serie;
    state.vacancy_request.interest_serie = interest_serie ? interest_serie.id : null;
  },
  setInterestShiftMutation: (state, interest_shift) => {
    state.interest_shift = interest_shift;
    state.vacancy_request.interest_shift = interest_shift ? interest_shift.id : null;
  },
  setFacilityMutation: (state, facility) => {
    state.facility = facility;
    state.vacancy_request.interest_school_id = facility ? facility.id : null;
  },
  setVacancyTypeMutation: (state, vacancy_type) => {
    state.vacancy_type = vacancy_type;
    state.vacancy_request.vacancy_type = vacancy_type ? vacancy_type.id : null;
  },
  setCpfResponsibleYoungPersonMutation: (state, cpf_responsible_young_person) => {
    state.vacancy_request.cpf_responsible_young_person = cpf_responsible_young_person;
  },
  setAddressResponsibleYoungPersonMutation: (state, address_responsible_young_person) => {
    state.vacancy_request.address_responsible_young_person = address_responsible_young_person;
  },
  setUpdatedContactYoungPersonMutation: (state, updated_contact_young_person) => {
    state.vacancy_request.updated_contact_young_person = updated_contact_young_person;
  },
  setSchoolNetworkMutation: (state, school_network) => {
    state.school_network = school_network;
    state.vacancy_request.school_network = school_network ? school_network.id : null;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.vacancy_request.city_id = city ? city.id : null;
  },
  setCaseDescriptionMutation: (state, case_description) => {
    state.vacancy_request.case_description = case_description;
  },
  setInterventionsPerformedMutation: (state, interventions_performed) => {
    state.vacancy_request.interventions_performed = interventions_performed;
  },
  setChildFullNameMutation: (state, child_full_name) => {
    state.vacancy_request.child_full_name = child_full_name;
  },
  setChildBirthdateMutation: (state, child_birthdate) => {
    state.vacancy_request.child_birthdate = child_birthdate;
  },
  setCpfResponsibleChildMutation: (state, cpf_responsible_child) => {
    state.vacancy_request.cpf_responsible_child = cpf_responsible_child;
  },
  setFullNameResponsibleChildMutation: (state, full_name_responsible_child) => {
    state.vacancy_request.full_name_responsible_child = full_name_responsible_child;
  },
  setAddressResponsibleChildMutation: (state, address_responsible_child) => {
    state.vacancy_request.address_responsible_child = address_responsible_child;
  },
  setContactResponsibleChildMutation: (state, contact_responsible_child) => {
    state.vacancy_request.contact_responsible_child = contact_responsible_child;
  },
  setInterestKindergartenMutation: (state, interest_kindergarten) => {
    state.vacancy_request.interest_kindergarten = interest_kindergarten;
  },
  setFeedbackMutation: (state, feedback) => {
    state.vacancy_request.feedback = feedback;
  },
  setForwardingTypeMutation: (state, forwarding_type) => {
    state.vacancy_request.forwarding_type = forwarding_type;
  },
  setAcceptedRequestMutation: (state, accepted_request) => {
    state.vacancy_request.accepted_request = accepted_request;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.vacancy_request.user_id = user.id;
      } else {
        state.vacancy_request.user_id = user.userId;
      }
    }
  },

  // --> MUTATIONS - DELETION <--
  vacancyRequestForDeletion: (state, payload) => {
    if (payload) {
      state.vacancyRequestId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterVacancyTypeMutation: (state, vacancy_type) => {
    state.params.vacancy_type = vacancy_type;
  },
  setFilterArticulatorMutation: (state, articulator) => {
    state.params.articulator = articulator;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddVacancyRequest: (state) => {
    state.modalToggleAddVacancyRequest = !state.modalToggleAddVacancyRequest;
  },
  toggleModalDeleteVacancyRequest: (state) => {
    state.modalToggleDeleteVacancyRequest = !state.modalToggleDeleteVacancyRequest;
  },
  toggleModalViewVacancyRequest: (state) => {
    state.modalToggleViewVacancyRequest = !state.modalToggleViewVacancyRequest;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportVacancyRequestsSpreadsheet: (state) => {
    state.modalToggleExportVacancyRequestsSpreadsheet = !state.modalToggleExportVacancyRequestsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetVacancyRequestsResponseMutation: (state, hasSpreadsheetVacancyRequestsResponse) => {
    state.hasSpreadsheetVacancyRequestsResponse = hasSpreadsheetVacancyRequestsResponse;
  },
  setVacancyRequestsPageToExportMutation: (state, vacancy_request) => {
    state.json_data_vacancy_request = null;
    const arrayData = new Array();
    vacancy_request.forEach((element, index, array) => {
      let student_name = array[index].student_name ? array[index].student_name : "-";
      let accepted_request = array[index].accepted_request ? "Sim" : "Não";
      let articulator = array[index].articulator ? array[index].articulator : "";
      let vacancy_type = array[index].vacancy_type ? array[index].vacancy_type.name : "";
      let cpf_responsible_young_person = array[index].cpf_responsible_young_person
        ? array[index].cpf_responsible_young_person
        : "";
      let address_responsible_young_person = array[index].address_responsible_young_person
        ? array[index].address_responsible_young_person
        : "";
      let updated_contact_young_person = array[index].updated_contact_young_person
        ? array[index].updated_contact_young_person
        : "";
      let interest_school = array[index].interest_school ? array[index].interest_school.name : "";
      let interest_shift = array[index].interest_shift ? array[index].interest_shift.name : "";
      let interest_serie = array[index].interest_serie ? array[index].interest_serie.name : "";
      let school_network = array[index].school_network ? array[index].school_network.name : "";
      let case_description = array[index].case_description ? array[index].case_description : "";
      let interventions_performed = array[index].interventions_performed
        ? array[index].interventions_performed
        : "";
      let forwarding_type = array[index].forwarding_type ? array[index].forwarding_type : "";
      let full_name_responsible_child = array[index].full_name_responsible_child
        ? array[index].full_name_responsible_child
        : "";
      let child_full_name = array[index].child_full_name ? array[index].child_full_name : "";
      let cpf_responsible_child = array[index].cpf_responsible_child
        ? array[index].cpf_responsible_child
        : "";
      let child_birthdate = array[index].child_birthdate
        ? moment(array[index].child_birthdate).format("DD/MM/YYYY")
        : "";
      let address_responsible_child = array[index].address_responsible_child
        ? array[index].address_responsible_child
        : "";
      let contact_responsible_child = array[index].contact_responsible_child
        ? array[index].contact_responsible_child
        : "";
      let interest_kindergarten = array[index].interest_kindergarten
        ? array[index].interest_kindergarten
        : "";
      let feedback = array[index].feedback ? array[index].feedback : "";

      const arrayItem = new Array();
      arrayItem["student_name"] = student_name;
      arrayItem["vacancy_type"] = vacancy_type;
      arrayItem["accepted_request"] = accepted_request;
      arrayItem["articulator"] = articulator;
      arrayItem["cpf_responsible_young_person"] = cpf_responsible_young_person;
      arrayItem["address_responsible_young_person"] = address_responsible_young_person;
      arrayItem["updated_contact_young_person"] = updated_contact_young_person;
      arrayItem["interest_school"] = interest_school;
      arrayItem["interest_shift"] = interest_shift;
      arrayItem["interest_serie"] = interest_serie;
      arrayItem["school_network"] = school_network;
      arrayItem["case_description"] = case_description;
      arrayItem["interventions_performed"] = interventions_performed;
      arrayItem["forwarding_type"] = forwarding_type;
      arrayItem["full_name_responsible_child"] = full_name_responsible_child;
      arrayItem["child_full_name"] = child_full_name;
      arrayItem["cpf_responsible_child"] = cpf_responsible_child;
      arrayItem["child_birthdate"] = child_birthdate;
      arrayItem["address_responsible_child"] = address_responsible_child;
      arrayItem["contact_responsible_child"] = contact_responsible_child;
      arrayItem["interest_kindergarten"] = interest_kindergarten;
      arrayItem["feedback"] = feedback;
      arrayData.push(arrayItem);
    });
    state.json_data_vacancy_request = arrayData;
    state.hasSpreadsheetVacancyRequestsResponse = true;
    state.modalToggleExportVacancyRequestsSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setVacancyRequestsTotalPerPage: (state, vacancyRequestsTotalPerPage) => {
    state.vacancyRequestsTotalPerPage = vacancyRequestsTotalPerPage;
  },
  setVacancyRequestsOffset: (state, vacancyRequestsOffset) => {
    state.vacancyRequestsOffset = vacancyRequestsOffset;
  },
  setVacancyRequestsTotal: (state, vacancyRequestsTotal) => {
    state.vacancyRequestsTotal = vacancyRequestsTotal;
  },
  setVacancyRequestsByInscriptionTotalPerPage: (state, vacancyRequestsByInscriptionTotalPerPage) => {
    state.vacancyRequestsByInscriptionTotalPerPage = vacancyRequestsByInscriptionTotalPerPage;
  },
  setVacancyRequestsByInscriptionOffset: (state, vacancyRequestsByInscriptionOffset) => {
    state.vacancyRequestsByInscriptionOffset = vacancyRequestsByInscriptionOffset;
  },
  setVacancyRequestsByInscriptionTotal: (state, vacancyRequestsByInscriptionTotal) => {
    state.vacancyRequestsByInscriptionTotal = vacancyRequestsByInscriptionTotal;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setSeasonMutation: (state, season) => {
    state.season = season;
    let season_information = {
      id: null,
      name: null,
    };
    if (season) {
      (season_information.id = season.id), (season_information.name = season.name);
    }
    sessionStorage.setItem("season_information", JSON.stringify(season_information));
  },
  setVacancyRequestsSpreadsheetMutation: (state, vacancy_requests_spreadsheet) => {
    state.vacancy_requests_spreadsheet = vacancy_requests_spreadsheet;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.vacancy_request) {
      if (prop !== "user_id") {
        if (prop === "accepted_request") {
          state.vacancy_request[prop] = false;
        }
        state.vacancy_request[prop] = null;
      }
    }
    state.vacancy_type = null;
    state.school_network = null;
    state.interest_serie = null;
    state.interest_shift = null;
    state.city = null;
    state.state = null;
    state.articulator = null;
    state.facility = null;
    state.inscription = null;
  },
  clearYoungFields: (state) => {
    state.vacancy_request.cpf_responsible_young_person = null;
    state.vacancy_request.address_responsible_young_person = null;
    state.vacancy_request.updated_contact_young_person = null;
    state.vacancy_request.interest_school_id = null;
    state.vacancy_request.interest_serie = null;
    state.vacancy_request.interest_shift = null;
    state.vacancy_request.school_network = null;
    state.vacancy_request.case_description = null;
    state.vacancy_request.interventions_performed = null;
    state.vacancy_request.forwarding_type = null;
    state.school_network = null;
    state.interest_serie = null;
    state.interest_shift = null;
    state.articulator = null;
    state.facility = null;
  },
  clearChildFields: (state) => {
    state.vacancy_request.full_name_responsible_child = null;
    state.vacancy_request.cpf_responsible_child = null;
    state.vacancy_request.child_full_name = null;
    state.vacancy_request.child_birthdate = null;
    state.vacancy_request.address_responsible_child = null;
    state.vacancy_request.contact_responsible_child = null;
    state.vacancy_request.interest_kindergarten = null;
  },
  clearQuery: (state) => {
    state.params.articulator = null;
    state.params.vacancy_type = null;
    state.params.city = null;
    state.params.state = null;
    state.vacancy_requests = [];
  },
};

function getUserAreas(state) {
  return state.user_areas && state.user_areas?.length > 0 ? state.user_areas.map((item) => item.id) : null;
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadVacancyRequests: ({ commit, rootGetters, rootState }) => {
    const params = {
      vacancy_type: state.params.vacancy_type ? state.params.vacancy_type.id : null,
      season_id: rootState.SchoolReinsertions.season ? rootState.SchoolReinsertions.season.id : null,
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      articulator: state.params.articulator,
      perPage: state.vacancyRequestsByInscriptionTotalPerPage,
      offset: state.vacancyRequestsByInscriptionOffset,
    };
    getVacancyRequests(params)
      .then((response) => {
        commit("loadVacancyRequests", response.data.vacancy_requests);
        commit("setVacancyRequestsTotal", response.data.total);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadVacancyRequestsByInscription: async ({ commit, rootState }, type) => {
    if (type === "loadDataSpreadsheet" || type === "loadAllDataSpreadsheet") {
      commit("hasSpreadsheetVacancyRequestsResponseMutation", false);
    }
    const params = {
      // inscription_id: type === "loadAllDataSpreadsheet" ? null : sessionStorage.inscription_id,
      vacancy_type: state.params.vacancy_type ? state.params.vacancy_type.id : null,
      season_id: rootState.SchoolReinsertions.season ? rootState.SchoolReinsertions.season.id : null,
      articulator: state.params.articulator,
      perPage: state.vacancyRequestsByInscriptionTotalPerPage,
      offset: state.vacancyRequestsByInscriptionOffset,
      type: type,
    };
    await getVacancyRequestByInscription(params)
      .then(async (response) => {
        if (type === "loadData") {
          if (response.data.vacancy_requests_total < 1) {
            Vue.$toast.warning("Nenhum resultado para o filtro informado!");
          }
          await commit("loadVacancyRequests", response.data.vacancy_requests);
          await commit("setVacancyRequestsByInscriptionTotal", response.data.vacancy_requests_total);
        } else {
          commit("setVacancyRequestsSpreadsheetMutation", response.data.vacancy_requests);
          if (state.vacancy_requests_spreadsheet.length > 0) {
            await commit("setVacancyRequestsPageToExportMutation", response.data.vacancy_requests);
          } else {
            commit("hasSpreadsheetVacancyRequestsResponseMutation", true);
            commit("toggleModalToggleExportVacancyRequestsSpreadsheet");
          }
        }
      })
      .catch((e) => {
        console.log(e);
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadArticulators: ({ commit }) => {
    getArticulators()
      .then((response) => {
        commit("loadArticulators", response.data.articulators);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadVacancyTypes: ({ commit }) => {
    getVacancyRequestsTypes()
      .then((response) => {
        commit("loadVacancyTypes", response.data.vacancy_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSchoolNetworks: ({ commit }) => {
    getVacancyRequestsSchoolsNetwork()
      .then((response) => {
        commit("loadSchoolNetworks", response.data.school_network);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInterestSeries: ({ commit }) => {
    getVacancyRequestsInterestSeries()
      .then((response) => {
        commit("loadInterestSeries", response.data.interest_serie);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInterestShifts: ({ commit }) => {
    getVacancyRequestsInterestShifts()
      .then((response) => {
        commit("loadInterestShifts", response.data.interest_shift);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadFacilities: ({ commit }) => {
    const params = {
      forwarding_policy_id: 2,
      by: "facilities.name",
      order: "ASC",
    };
    getFacilitiesByForwardingPolicy(params)
      .then((response) => {
        commit("loadFacilities", response.data.facilities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      area_id: state.user_areas_filtered.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsForSchoolReinsertionMutation", equipments);
        commit("setFilterEquipmentNotReinsertedMainPageMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadSeasons: ({ commit }) => {
    getSeasonsForMenus()
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptionByIdForVacancyRequest: async ({ commit }, inscription_id) => {
    const params = {
      id: inscription_id,
      resource: "school_reinsertion",
    };
    await getInscriptionByIdAndResource(params)
      .then(async (response) => {
        await commit("setInscriptionMutation", response.data.inscription);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptionsForGeneralMenus: ({ commit, rootState }, value) => {
    const params = {
      participant_name: value,
      area_id: getUserAreas(state),
      season_id: rootState.SchoolReinsertions.season ? rootState.SchoolReinsertions.season.id : null,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForGeneralMenus(params)
      .then((response) => {
        commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getVacancyRequestById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.vacancy_request.id);
        commit("setVacancyTypeMutation", response.data.vacancy_request.vacancy_type);
        commit("setAddressResponsibleChildMutation", response.data.vacancy_request.address_responsible_child);
        commit(
          "setAddressResponsibleYoungPersonMutation",
          response.data.vacancy_request.address_responsible_young_person
        );
        commit("setArticulatorMutation", response.data.vacancy_request.articulator);
        commit("setCaseDescriptionMutation", response.data.vacancy_request.case_description);
        commit("setChildBirthdateMutation", response.data.vacancy_request.child_birthdate);
        commit("setChildFullNameMutation", response.data.vacancy_request.child_full_name);
        commit("setCityMutation", response.data.vacancy_request.city);
        commit("setContactResponsibleChildMutation", response.data.vacancy_request.contact_responsible_child);
        commit(
          "setCpfResponsibleYoungPersonMutation",
          response.data.vacancy_request.cpf_responsible_young_person
        );
        commit("setCpfResponsibleChildMutation", response.data.vacancy_request.cpf_responsible_child);
        commit("setFeedbackMutation", response.data.vacancy_request.feedback);
        commit("setForwardingTypeMutation", response.data.vacancy_request.forwarding_type);
        commit(
          "setFullNameResponsibleChildMutation",
          response.data.vacancy_request.full_name_responsible_child
        );
        commit("setInscriptionMutation", response.data.vacancy_request.inscription);
        commit("setInterestKindergartenMutation", response.data.vacancy_request.interest_kindergarten);
        commit("setFacilityMutation", response.data.vacancy_request.interest_school);
        commit("setInterestSerieMutation", response.data.vacancy_request.interest_serie);
        commit("setInterestShiftMutation", response.data.vacancy_request.interest_shift);
        commit("setInterventionsPerformedMutation", response.data.vacancy_request.interventions_performed);
        commit("setSchoolNetworkMutation", response.data.vacancy_request.school_network);
        commit(
          "setUpdatedContactYoungPersonMutation",
          response.data.vacancy_request.updated_contact_young_person
        );
        commit("setAcceptedRequestMutation", response.data.vacancy_request.accepted_request);
        commit("setUserMutation", response.data.vacancy_request.user);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setArticulator: ({ commit }, payload) => {
    commit("setArticulatorMutation", payload.value);
  },
  setInterestSerie: ({ commit }, payload) => {
    commit("setInterestSerieMutation", payload);
  },
  setInterestShift: ({ commit }, payload) => {
    commit("setInterestShiftMutation", payload);
  },
  setVacancyType: ({ commit }, payload) => {
    commit("setVacancyTypeMutation", payload);
    if (payload.id === 0) {
      commit("clearChildFields");
    } else if (payload.id === 1) {
      commit("clearYoungFields");
    }
  },
  setSchoolNetwork: ({ commit }, payload) => {
    commit("setSchoolNetworkMutation", payload);
  },
  setCpfResponsibleYoungPerson: ({ commit }, payload) => {
    commit("setCpfResponsibleYoungPersonMutation", payload.value);
  },
  setAddressResponsibleYoungPerson: ({ commit }, payload) => {
    commit("setAddressResponsibleYoungPersonMutation", payload.value);
  },
  setUpdatedContactYoungPerson: ({ commit }, payload) => {
    commit("setUpdatedContactYoungPersonMutation", payload.value);
  },
  setCaseDescription: ({ commit }, payload) => {
    commit("setCaseDescriptionMutation", payload.value);
  },
  setInterventionsPerformed: ({ commit }, payload) => {
    commit("setInterventionsPerformedMutation", payload.value);
  },
  setChildFullName: ({ commit }, payload) => {
    commit("setChildFullNameMutation", payload.value);
  },
  setChildBirthdate: ({ commit }, payload) => {
    commit("setChildBirthdateMutation", payload.value);
  },
  setFullNameResponsibleChild: ({ commit }, payload) => {
    commit("setFullNameResponsibleChildMutation", payload.value);
  },
  setCpfResponsibleChild: ({ commit }, payload) => {
    commit("setCpfResponsibleChildMutation", payload.value);
  },
  setAddressResponsibleChild: ({ commit }, payload) => {
    commit("setAddressResponsibleChildMutation", payload.value);
  },
  setInterestKindergarten: ({ commit }, payload) => {
    commit("setInterestKindergartenMutation", payload.value);
  },
  setContactResponsibleChild: ({ commit }, payload) => {
    commit("setContactResponsibleChildMutation", payload.value);
  },
  setFeedback: ({ commit }, payload) => {
    commit("setFeedbackMutation", payload.value);
  },
  setForwardingType: ({ commit }, payload) => {
    commit("setForwardingTypeMutation", payload.value);
  },
  setAcceptedRequest: ({ commit }, payload) => {
    commit("setAcceptedRequestMutation", payload.value);
  },
  setFacility: ({ commit }, payload) => {
    commit("setFacilityMutation", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddVacancyRequest: async ({ commit }) => {
    commit("toggleModalAddVacancyRequest");
  },
  closeModalAddVacancyRequest: ({ commit }) => {
    commit("toggleModalAddVacancyRequest");
    commit("clearFields");
  },
  openEditModalAddVacancyRequest: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddVacancyRequest");
  },
  closeEditModalAddVacancyRequest: ({ commit }) => {
    commit("toggleModalAddVacancyRequest");
    commit("clearFields");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewVacancyRequest");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewVacancyRequest");
    commit("clearFields");
  },
  toggleModalDeleteVacancyRequest: ({ commit }, payload) => {
    commit("toggleModalDeleteVacancyRequest");
    if (state.modalToggleDeleteVacancyRequest && payload) {
      commit("vacancyRequestForDeletion", payload);
    } else {
      commit("vacancyRequestForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportVacancyRequestsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportVacancyRequestsSpreadsheet");
  },
  closeExportVacancyRequestsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportVacancyRequestsSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsForSchoolReinsertionMutation", []);
      commit("setFilterEquipmentNotReinsertedMainPageMutation", null);
    }
  },
  setFilterVacancyType: ({ commit }, payload) => {
    commit("setFilterVacancyTypeMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterArticulator: ({ commit }, payload) => {
    commit("setFilterArticulatorMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setVacancyRequestsByInscriptionTotalPerPage: ({ commit }, payload) => {
    commit("setVacancyRequestsByInscriptionTotalPerPage", payload.totalPerPage);
  },
  setVacancyRequestsByInscriptionOffset: ({ commit }, payload) => {
    commit("setVacancyRequestsByInscriptionOffset", payload.offset);
  },
  setVacancyRequestsTotalPerPage: ({ commit }, payload) => {
    commit("setVacancyRequestsTotalPerPage", payload.totalPerPage);
  },
  setVacancyRequestsOffset: ({ commit }, payload) => {
    commit("setVacancyRequestsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
  setInscription: async ({ commit }, payload) => {
    await commit("setInscriptionMutation", payload);
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ dispatch }) => {
    createVacancyRequest(state.vacancy_request)
      .then(() => {
        Vue.$toast.success("Solicitação de Vaga cadastrada com sucesso!");
        dispatch("closeModalAddVacancyRequest");
        dispatch("loadVacancyRequests");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a solicitação! " + e.response.data.error);
        console.log(e.response);
      });
  },
  update: ({ state, dispatch }) => {
    updateVacancyRequest(state.vacancy_request.id, state.vacancy_request)
      .then(() => {
        dispatch("loadVacancyRequests");
        dispatch("closeEditModalAddVacancyRequest");
        Vue.$toast.success("Solicitação de vaga atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a solicitação de vaga! ");
        console.log(e.response.data.errors);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyVacancyRequest(state.vacancyRequestId)
      .then(() => {
        Vue.$toast.success("Solicitação de Vaga deletada com sucesso!");
        commit("toggleModalDeleteVacancyRequest");
        dispatch("loadVacancyRequests");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
