import { isEmpty } from "lodash";

const state = {
  id: null,
  status: null,
  contacted: null,
  date_face_to_face_service: null,
  justification: null,
  season: null,
  equipment: null,
  active: null,
  responsible_professional: null,
  responsible_professional_id: null,
  created_at: null,
  feedback: null,
  is_active_inscription: true,
  documents: [],
  students: [],
};

const mutations = {
  setIdMutation: (state, id) => {
    state.id = id;
  },
  setStatusMutation: (state, status) => {
    state.status = status;
  },
  setContactedMutation: (state, contacted) => {
    state.contacted = contacted;
  },
  setDateFaceToSaceServiceMutation: (state, dateFaceToSaceServiceMutation) => {
    state.date_face_to_face_service = dateFaceToSaceServiceMutation;
  },
  setJustificationMutation: (state, justification) => {
    state.justification = justification;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
  },
  setEquipmentMutation: (state, equipment) => {
    state.equipment = equipment;
  },
  setCreatedAtMutation: (state, created_at) => {
    state.created_at = created_at;
  },
  setFeedbackMutation: (state, feedback) => {
    state.feedback = feedback;
  },
  setIsActiveInscriptionMutation: (state, is_active_inscription) => {
    state.is_active_inscription = is_active_inscription;
  },
  setResponsibleProfessionalMutation: (state, responsible_professional) => {
    state.responsible_professional = responsible_professional;
    if (responsible_professional != null) {
      if (responsible_professional.id) {
        state.responsible_professional_id = responsible_professional.id;
      } else {
        state.responsible_professional_id = responsible_professional.userId;
      }
    }
  },
  setActiveMutation: (state, active) => {
    state.active = active;
  },
  setDocumentInInscription: (state, document) => {
    // remover o código abaixo quando a API estiver retornando os documentos junto com a inscrição;
    state.documents.push(document);
  },
  setStudents: (state, students) => {
    state.students = students;
  },
};

const actions = {
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setStatus: ({ commit }, payload) => {
    commit("setStatusMutation", payload);
  },
  setContacted: ({ commit }, payload) => {
    commit("setContactedMutation", payload.value);
  },
  setDateFaceToFaceService: ({ commit }, payload) => {
    commit("setDateFaceToSaceServiceMutation", payload.value);
  },
  setJustification: ({ commit }, payload) => {
    commit("setJustificationMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipmentMutation", payload);
  },
  setResponsibleProfessional: ({ commit }, payload) => {
    commit("setResponsibleProfessionalMutation", payload);
  },
  setActive: ({ commit }, payload) => {
    commit("setActiveMutation", payload);
  },
  setDocumentInInscription: ({ commit }, payload) => {
    commit("setDocumentInInscription", payload);
  },
  setCreatedAt: ({ commit }, payload) => {
    commit("setCreatedAtMutation", payload);
  },
  setFeedback: ({ commit }, payload) => {
    commit("setFeedbackMutation", payload.value);
  },
  load: ({ commit, dispatch }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setIdMutation", payload.id);
      commit("setStatusMutation", payload.status);
      commit("setActiveMutation", payload.active);
      commit("setContactedMutation", payload.contacted);
      commit("setDateFaceToSaceServiceMutation", payload.date_face_to_face_service);
      commit("setJustificationMutation", payload.justification);
      dispatch("Student/load", payload.student, { root: true });
      commit("setSeasonMutation", payload.season);
      commit("setEquipmentMutation", payload.equipment);
      commit("setResponsibleProfessionalMutation", payload.responsible_professional);
      commit("setCreatedAtMutation", payload.created_at);
      commit("setFeedbackMutation", payload.feedback);
      commit("setIsActiveInscriptionMutation", payload.is_active_inscription);
    } else {
      commit("setIdMutation", null);
      commit("setStatusMutation", null);
      commit("setActiveMutation", null);
      commit("setContactedMutation", null);
      commit("setDateFaceToSaceServiceMutation", null);
      commit("setJustificationMutation", null);
      dispatch("Student/load", null, { root: true });
      commit("setSeasonMutation", null);
      commit("setEquipmentMutation", null);
      commit("setResponsibleProfessionalMutation", null);
      commit("setCreatedAtMutation", null);
      commit("setFeedbackMutation", null);
      commit("setIsActiveInscriptionMutation", true);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
