<template>
  <div class="grid grid-cols-4">
    <div class="justify-self-start col-span-3">
      <Label
        :text="headerText"
        :icon="null"
        :icon-type="null"
        type="text-2xl"
        weight="bold"
        normal
        mode="normal-case"
        :class="headerClass"
      />
    </div>
    <div class="justify-self-end">
      <AppButtonIcon :dispatch="closeDispatch" forClose />
    </div>
  </div>
</template>

<script>
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    AppButtonIcon,
    Label,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    headerClass: {
      type: String,
      required: true,
    },
    closeDispatch: {
      type: String,
      required: true,
    },
  },
};
</script>
