<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Selecionar campos para Exportação"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="SelectFieldsToExport/closeModalSelectFieldsToExport" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Campos"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="flex items-center border-b pb-2 col-span-3 mb-4">
        <label
          for="select-all"
          class="flex items-center cursor-pointer mr-6 p-2 bg-gray-100 rounded hover:bg-gray-200 transition duration-200"
        >
          <input
            id="select-all"
            type="checkbox"
            @click="toggleSelectAll($event)"
            :checked="allSelected"
            class="mr-2"
          />
          <BaseText
            class="flex"
            text="Marcar/Desmarcar Todos"
            typeText="custom"
            weight="semibold"
            :italic="false"
            mode="capitalize"
            size="lg"
            target="select-all"
          />
        </label>
      </div>
      <draggable v-model="fieldsWithCheck" @end="onDragEnd" class="grid grid-cols-2 gap-4">
        <div
          v-for="(field, index) in fieldsWithCheck"
          :key="index"
          class="flex items-center border-b pb-2 p-2 bg-gray-100 rounded hover:bg-gray-200 transition duration-200"
        >
          <label :for="checkboxId(field.name, index)" class="flex items-center cursor-pointer w-full">
            <input
              :id="checkboxId(field.name, index)"
              type="checkbox"
              name="fields"
              :value="field.name"
              @click="onCheck(field, index, $event)"
              :checked="field.checked"
              class="mr-2"
            />
            <BaseText
              class="flex"
              :text="field.name"
              typeText="custom"
              weight="semibold"
              :italic="false"
              mode="capitalize"
              size="lg"
              :target="checkboxId(field.name, index)"
            />
          </label>
        </div>
      </draggable>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="SelectFieldsToExport/closeModalSelectFieldsToExport"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Avançar"
            typeButton="success"
            dispatch="SelectFieldsToExport/move_on"
            :payload="openModalDispatch"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import draggable from "vuedraggable";
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BaseText from "@/components/BaseText.vue";

export default {
  components: {
    BaseText,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    draggable,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    openModalDispatch: {
      type: String,
    },
  },
  data() {
    return {
      fieldsWithCheck: [],
    };
  },
  computed: {
    allSelected() {
      return this.fieldsWithCheck.length > 0 && this.fieldsWithCheck.every((field) => field.checked);
    },
  },
  methods: {
    initializeFields(fields) {
      this.fieldsWithCheck = Object.keys(fields).map((key) => ({
        name: key,
        value: fields[key],
        checked: false,
      }));
    },
    checkboxId(name, index) {
      return `${name}-${index}`;
    },
    onCheck(field, index, event) {
      field.checked = event.target.checked;
      const dispatchData = {
        [field.name]: field.value,
      };
      const dispatch = event.target.checked
        ? "SelectFieldsToExport/addFields"
        : "SelectFieldsToExport/removeFields";
      this.$store.dispatch(dispatch, dispatchData);
    },
    toggleSelectAll(event) {
      const isChecked = event.target.checked;
      this.fieldsWithCheck.forEach((field) => {
        field.checked = isChecked;
        const dispatchData = {
          [field.name]: field.value,
        };
        const dispatch = isChecked ? "SelectFieldsToExport/addFields" : "SelectFieldsToExport/removeFields";
        this.$store.dispatch(dispatch, dispatchData);
      });
    },
  },
  watch: {
    fields: {
      handler(newFields) {
        if (newFields) {
          this.initializeFields(newFields);
        }
      },
      immediate: true,
    },
  },
};
</script>
