import { getAxios, baseURL } from "./Api";

export async function getProfessionalQualificationByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/qualificacoes_profissionais`);
}

export async function getProfessionalQualificationsWithName() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/qualificacoes_profissionais/nomeadas`);
}
