<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Situação de Moradia"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Situação de Moradia"
      label-text="Situação de Moradia"
      :multiple-option="false"
      :value="housing_situation"
      :options="housing_situations"
      :dispatch="setHousingSituation"
    />
    <div class="col-span-1">
      <BaseInput
        active
        input-name="number_of_residents"
        input-type="number"
        :value="psychosocial_accompaniment?.number_of_residents"
        show-label
        label-text="Quantas pessoas residem com você?"
        inputMin="0"
        dispatch="PsychosocialAccompaniments/setNumberOfResidents"
      />
    </div>
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Selecione uma opção"
      label-text="Situação do Imóvel"
      :multiple-option="false"
      :value="housing_building_situation"
      :options="housing_building_situations"
      :dispatch="setHousingBuildingSituation"
    />
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Selecione uma opção"
      label-text="Estrutura do Imóvel"
      :multiple-option="false"
      :value="housing_building_material"
      :options="housing_building_materials"
      :dispatch="setHousingBuildingMaterial"
    />
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Selecione uma opção"
      label-text="Qual a forma do abastecimento de energia elétrica?"
      :multiple-option="false"
      :value="housing_has_electricity"
      :options="housing_has_electricities"
      :dispatch="setHousingHasElectricity"
    />
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Selecione uma opção"
      label-text="Qual a forma do abastecimento de água?"
      :multiple-option="false"
      :value="housing_has_water_supply"
      :options="housing_has_water_supplies"
      :dispatch="setHousingHasWaterSupply"
    />
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Selecione uma opção"
      label-text="Fornecimento de Gás"
      :multiple-option="false"
      :value="housing_has_gas_supply"
      :options="housing_has_gas_supplies"
      :dispatch="setHousingHasGasSupply"
    />
    <Multiselect
      :close-on-select="true"
      show-label
      clear-on-select
      select-placeholder="Selecione uma opção"
      label-text="De que forma é feito o escoamento do banheiro ou sanitário?"
      :multiple-option="false"
      :value="housing_bathroom_drain_type"
      :options="housing_bathroom_drain_types"
      :dispatch="setHousingBathroomDrainType"
    />
    <AppRadioButton
      :values="[
        {
          name: 'housing_has_latrine',
          value: true,
          description: 'Sim',
          checked: psychosocial_accompaniment?.housing_has_latrine === true,
        },
        {
          name: 'housing_has_latrine',
          value: false,
          description: 'Não',
          checked: psychosocial_accompaniment?.housing_has_latrine === false,
        },
      ]"
      labelText="Tem saneamento básico?"
      showLabel
      dispatch="PsychosocialAccompaniments/setHousingHasLatrine"
    />
    <AppRadioButton
      :values="[
        {
          name: 'housing_has_bathroom',
          value: true,
          description: 'Sim',
          checked: psychosocial_accompaniment?.housing_has_bathroom === true,
        },
        {
          name: 'housing_has_bathroom',
          value: false,
          description: 'Não',
          checked: psychosocial_accompaniment?.housing_has_bathroom === false,
        },
      ]"
      labelText="Tem banheiro?"
      showLabel
      dispatch="PsychosocialAccompaniments/setHousingHasBathroom"
    />
    <AppRadioButton
      :values="[
        {
          name: 'housing_has_garbage_collection',
          value: true,
          description: 'Sim',
          checked: psychosocial_accompaniment?.housing_has_garbage_collection === true,
        },
        {
          name: 'housing_has_garbage_collection',
          value: false,
          description: 'Não',
          checked: psychosocial_accompaniment?.housing_has_garbage_collection === false,
        },
      ]"
      labelText="Tem coleta de lixo?"
      showLabel
      dispatch="PsychosocialAccompaniments/setHousingHasGarbageCollection"
    />
    <div class="col-span-2">
      <BaseTextArea
        active
        inputName="Comentários sobre situação de Moradia"
        labelText="Comentários sobre situação de Moradia"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.housing_situation_comments"
        dispatch="PsychosocialAccompaniments/setHousingSituationComments"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Label from "@/components/Label.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseTextArea from "@/components/forms/BaseTextArea.vue";
import AppRadioButton from "@/components/AppRadioButton.vue";

export default {
  name: "HousingSituation",
  components: {
    Label,
    Multiselect,
    BaseInput,
    BaseTextArea,
    AppRadioButton,
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      housing_situation: (state) => state.PsychosocialAccompaniments.housing_situation,
      housing_situations: (state) => state.PsychosocialAccompaniments.housing_situations,
      housing_building_situation: (state) => state.PsychosocialAccompaniments.housing_building_situation,
      housing_building_situations: (state) => state.PsychosocialAccompaniments.housing_building_situations,
      housing_building_material: (state) => state.PsychosocialAccompaniments.housing_building_material,
      housing_building_materials: (state) => state.PsychosocialAccompaniments.housing_building_materials,
      housing_has_electricity: (state) => state.PsychosocialAccompaniments.housing_has_electricity,
      housing_has_electricities: (state) => state.PsychosocialAccompaniments.housing_has_electricities,
      housing_has_water_supply: (state) => state.PsychosocialAccompaniments.housing_has_water_supply,
      housing_has_water_supplies: (state) => state.PsychosocialAccompaniments.housing_has_water_supplies,
      housing_has_gas_supply: (state) => state.PsychosocialAccompaniments.housing_has_gas_supply,
      housing_has_gas_supplies: (state) => state.PsychosocialAccompaniments.housing_has_gas_supplies,
      housing_bathroom_drain_type: (state) => state.PsychosocialAccompaniments.housing_bathroom_drain_type,
      housing_bathroom_drain_types: (state) => state.PsychosocialAccompaniments.housing_bathroom_drain_types,
    }),
  },
  methods: {
    ...mapActions({
      setHousingSituation: "PsychosocialAccompaniments/setHousingSituation",
      setHousingBuildingSituation: "PsychosocialAccompaniments/setHousingBuildingSituation",
      setHousingBuildingMaterial: "PsychosocialAccompaniments/setHousingBuildingMaterial",
      setHousingHasElectricity: "PsychosocialAccompaniments/setHousingHasElectricity",
      setHousingHasWaterSupply: "PsychosocialAccompaniments/setHousingHasWaterSupply",
      setHousingHasGasSupply: "PsychosocialAccompaniments/setHousingHasGasSupply",
      setHousingBathroomDrainType: "PsychosocialAccompaniments/setHousingBathroomDrainType",
    }),
  },
};
</script>
