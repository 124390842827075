import { getAxios, baseURL } from "./Api";

export async function getStudentsPhaseOne(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/alunos/disponiveis_para_fase_um`;
  return api.get(url, { params: filters });
}

export async function getStudentsPhaseTwo(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/alunos/disponiveis_para_fase_dois`;

  return api.get(url, { params: filters });
}

export async function getStudentsPhaseThree(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/alunos/disponiveis_para_fase_tres`;

  return api.get(url, { params: filters });
}
