import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import { cloneDeep } from "lodash";

import ModalInscription from "./modules/ModalInscription";
import Races from "./modules/Races";
import Deficiencies from "./modules/Deficiencies";
import ModalAllocationTeamBenefit from "./modules/ModalAllocationTeamBenefit";
import Inscription from "./modules/Inscription";
import Equipment from "./modules/Equipment";
import EquipmentAll from "./modules/EquipmentAll";
import Season from "./modules/Season";
import Seasons from "./modules/Seasons";
import Session from "./modules/Session";
import Default from "./modules/Default";
import Neighborhoods from "./modules/Neighborhoods";
import Areas from "./modules/Areas";
import Area from "./modules/Area";
import Atendents from "./modules/Atendents";
import Course from "./modules/Course";
import Courses from "./modules/Courses";
import Meetings from "./modules/Meetings";
import Meeting from "./modules/Meeting";
import Presence from "./modules/Presence";
import Teachers from "./modules/Teachers";
import Teacher from "./modules/Teacher";
import Allocation from "./modules/Allocation";
import City from "./modules/City";
import Student from "./modules/Student";
import Team from "./modules/Team";
import TeamAllocate from "./modules/TeamAllocate";
import Teams from "./modules/Teams";
import Benefits from "./modules/Benefits";
import Benefit from "./modules/Benefit";
import User from "./modules/User";
import Users from "./modules/Users";
import Roles from "./modules/Roles";
import ModalExportTeamPresence from "./modules/ModalExportTeamPresence";
import AccessControl from "./modules/AccessControl";
import Forwardings from "./modules/Forwardings";
import ForwardingPostponementHistories from "./modules/ForwardingPostponementHistories";
import PsychosocialAccompaniments from "./modules/PsychosocialAccompaniments";
import MatrixRiskStratifications from "./modules/MatrixRiskStratifications";
import Facilities from "./modules/Facilities";
import Articulators from "./modules/Articulators";
import SchoolReinsertions from "./modules/SchoolReinsertions";
import VacancyRequests from "./modules/VacancyRequests";
import PlannedAwarenessActivities from "./modules/PlannedAwarenessActivities";
import MatrixProblem from "./modules/MatrixProblem";
import MatrixSituation from "./modules/MatrixSituation";
import Segments from "./modules/Segments";
import Occupations from "./modules/Occupations";
import Partners from "./modules/Partners";
import Companies from "./modules/Companies";
import Opportunities from "./modules/Opportunities";
import ProductiveInsertions from "./modules/ProductiveInsertions";
import ForwardingProductiveInsertions from "./modules/ForwardingProductiveInsertions";
import ForwardingReservationRegistrations from "./modules/ForwardingReservationRegistrations";
import ProfileSearchs from "./modules/ProfileSearchs";
import SpontaneousProductiveInsertions from "./modules/SpontaneousProductiveInsertions";
import SelectFieldsToExport from "./modules/SelectFieldsToExport";
import UtilFunctions from "./modules/UtilFunctions";
import ScholarshipMatrices from "./modules/ScholarshipMatrices";
import Advantages from "./modules/Advantages";
import PaymentSheets from "./modules/PaymentSheets";
import Beneficiaries from "./modules/Beneficiaries";
import Supports from "./modules/Supports";
import SeasonPreviousInscriptions from "@/store/modules/SeasonPreviousInscriptions";
import { undoState } from "./UndoState";

Vue.use(Vuex);

const undoStatePlugin = (store) => {
  undoState.init(store);

  store.subscribe((mutation, state) => {
    if (mutation.type === "ModalInscription/setInscription") {
      undoState.addState(cloneDeep(state));
    }

    if (mutation.type === "Equipment/setEquipment") {
      undoState.addState(cloneDeep(state));
    }
  });
};

const store = new Vuex.Store({
  state: {
    user_areas: JSON.parse(localStorage.getItem("userData")),
  },
  strict: process.env.NODE_ENV === "development",
  plugins: [
    undoStatePlugin,
    createPersistedState({
      paths: ["Session.loggedUser", "Session.role"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 1, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  modules: {
    AccessControl,
    ModalInscription,
    ModalAllocationTeamBenefit,
    Inscription,
    Season,
    Seasons,
    Session,
    Default,
    Student,
    City,
    Areas,
    Area,
    Atendents,
    Meetings,
    Meeting,
    Presence,
    Teacher,
    Teachers,
    Neighborhoods,
    Equipment,
    Races,
    Deficiencies,
    EquipmentAll,
    Team,
    TeamAllocate,
    Teams,
    Course,
    Courses,
    Benefits,
    Benefit,
    Allocation,
    Users,
    User,
    Roles,
    ModalExportTeamPresence,
    Forwardings,
    ForwardingPostponementHistories,
    PsychosocialAccompaniments,
    MatrixRiskStratifications,
    Facilities,
    Articulators,
    SchoolReinsertions,
    VacancyRequests,
    PlannedAwarenessActivities,
    MatrixProblem,
    MatrixSituation,
    Segments,
    Occupations,
    Partners,
    Companies,
    ProductiveInsertions,
    Opportunities,
    ForwardingProductiveInsertions,
    ForwardingReservationRegistrations,
    ProfileSearchs,
    SpontaneousProductiveInsertions,
    SelectFieldsToExport,
    UtilFunctions,
    ScholarshipMatrices,
    Advantages,
    PaymentSheets,
    Beneficiaries,
    Supports,
    SeasonPreviousInscriptions,
  },
});

export default store;
