<template>
  <div v-if="accompanimentOrigin" class="grid grid-cols-4 gap-4 mt-4">
    <div class="col-span-4 text-burnedYellow pb-2">
      <Label
        text="Informações do Atendimento de Origem"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div v-if="accompanimentOrigin?.date_accompaniment">
      <AppTextField
        show-label
        label-text="Data do Encontro"
        :text="formattedDate(accompanimentOrigin?.date_accompaniment)"
        type-text="topic-details"
      />
    </div>
    <div v-if="accompanimentOrigin">
      <AppTextField
        show-label
        label-text="Tipo do Atendimento"
        :text="accompanimentOrigin?.accompaniment_type?.name || ''"
        type-text="topic-details"
      />
    </div>
    <div v-if="accompanimentOrigin">
      <AppTextField
        show-label
        label-text="Status do Acompanhamento"
        :text="accompanimentOrigin?.accompaniment_status?.name || ''"
        type-text="topic-details"
      />
    </div>
    <div class="col-span-2" v-if="accompanimentOrigin">
      <AppTextField
        show-label
        label-text="Usuário responsável pelo cadastro"
        :text="accompanimentOrigin?.user?.name || ''"
        type-text="topic-details"
      />
    </div>
  </div>
</template>

<script>
import AppTextField from "@/components/AppTextField.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    AppTextField,
    Label,
  },
  props: {
    accompanimentOrigin: {
      type: Object,
      required: false,
    },
  },
  methods: {
    formattedDate(data) {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
