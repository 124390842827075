import { getAxios, baseURL } from "./Api";

export async function getSupports(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `suporte`;
  return api.get(url, { params: filters });
}

export async function getSupportById(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`suporte/${id}`);
}

export async function createSupport(support) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/suporte", support);
}

export async function updateSupport(id, support) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`suporte/${id}`, support);
}

export async function destroySupport(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`suporte/${id}`);
}
