<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center container1">
      <div class="mt-4">
        <BaseText
          :text="verifyStatus()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.student.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.student.season.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.student.cpf | VMask('###.###.###-##')"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription?.student?.city?.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="inscription.student.neighborhood.area.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getEquipmentName()"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex justify-center">
        <router-link to="/psicossocial/pagina_principal">
          <a href="javascript:void(0)" @click="setInscription()" class="group">
            <div
              :class="classButtonAssignment"
              class="transition duration-200 ease-in-out transform hover:scale-105 hover:bg-yellow-600 bg-yellow-500 rounded-full p-2 shadow-md flex items-center justify-center"
            >
              <AppMaterialIcon iconName="badge" iconType="round" iconSize="24" class="text-white" />
            </div>
          </a>
        </router-link>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppMaterialIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    inscription: {
      type: Object,
    },
  },
  created() {
    this.forwardingAccess();
    this.forwardingShow();
    this.forwardingChange();
    this.forwardingDelete();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    forwardingAccess() {
      this.canAcces("forwarding_index").then((resp) => {
        this.permit = resp;
      });
    },
    forwardingShow() {
      this.canAcces("forwarding_show").then((resp) => {
        this.permitShow = resp;
      });
    },
    forwardingDelete() {
      this.canAcces("forwarding_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    forwardingChange() {
      this.canAcces("forwarding_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    getEquipmentName() {
      if (this.inscription.student.equipment) {
        return this.inscription.student.equipment.name;
      } else {
        return "Sem equipamento cadastrado";
      }
    },
    hasInitialReception() {
      if (
        this.inscription.psychosocial_accompaniments_total === 1 ||
        this.inscription.psychosocial_accompaniments_total > 1
      ) {
        return "Sim";
      } else {
        return "Não";
      }
    },
    setInscription() {
      this.$store.dispatch("PsychosocialAccompaniments/setInscription", this.inscription);
    },
    openModalAddAttendance() {
      this.$store.dispatch("PsychosocialAccompaniments/openModalAddAttendance");
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
    classButtonAssignment() {
      return {
        border: true,
        "shadow-md": true,
        "m-2": true,
        "rounded-full": true,
        "h-15": true,
        "w-20": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-herbal": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 11% 21% 12% 13% 10% 12% 15% 5%;
}
</style>
