<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
        Matriz Problema-Impacto - Situações
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-facility"
          showIcon
          iconName="add"
          text="Cadastrar Situação"
          dispatch="MatrixSituation/toggleModalMatrixSituations"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            id="select-problem"
            select-placeholder="Problema"
            label-text="Problema"
            close-on-select
            show-label
            required
            :value="problem_filtered"
            :options="matrix_problems"
            :dispatch="filterListByProblem"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2">
          <BaseLabel
            text="Pesquisar por situação"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por situação"
            dispatch="MatrixSituation/filterListBySituation"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="select-situation-risk"
            select-placeholder="Nível da Situação"
            label-text="Nível da Situação"
            close-on-select
            show-label
            required
            :value="situation_risk_filtered"
            :options="situations_risks"
            :dispatch="filterListBySituationRisk"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2">
          <BaseLabel
            text="Pesquisar por impacto"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por impacto"
            dispatch="MatrixSituation/filterListByImpact"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            id="select-impact-level"
            select-placeholder="Nível do Impacto"
            label-text="Nível do Impacto"
            close-on-select
            show-label
            required
            :value="impact_level_filtered"
            :options="impact_levels"
            :dispatch="filterListByImpactLevel"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-matrix-situations"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="MatrixSituation/loadMatrixSituations"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="matrix_situations.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-matrix-situations">
      <div class="mx-12">
        <MatrixSituationsCards :data="matrix_situations" />
      </div>
      <div id="matrix_situations-pagination" class="mx-12 my-6 pb-3" v-if="matrix_situations.length > 0">
        <BasePagination
          :total="totalMatrixSituations"
          :total-per-page="totalPerPage"
          dispatch="MatrixSituation/setMatrixSituationOffset"
        />
      </div>
      <ModalMatrixSituation :open="modalToggleMatrixSituations" />
      <DeleteMatrixSituationConfirmation :open="modalConfirmDeleteMatrixSituation" />
      <ModalViewMatrixSituationData :open="modalToggleViewMatrixSituations" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import MatrixSituationsCards from "./list/MatrixSituationsCards.vue";
import BasePagination from "../components/BasePagination.vue";
import ModalMatrixSituation from "./ModalMatrixSituation.vue";
import DeleteMatrixSituationConfirmation from "./alerts/DeleteMatrixSituationConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import BaseLabel from "../components/BaseLabel.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import ModalViewMatrixSituationData from "../views/datas/ModalViewMatrixSituationData.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    MatrixSituationsCards,
    ModalViewMatrixSituationData,
    ModalMatrixSituation,
    DeleteMatrixSituationConfirmation,
    BaseInput,
    Multiselect,
    ProhibitedAccessPage,
    FilterComponent,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      situations_risks: [
        {
          id: 0,
          name: "Gravíssima",
        },
        {
          id: 1,
          name: "Grave",
        },
        {
          id: 2,
          name: "Moderada",
        },
      ],
      impact_levels: [
        {
          id: 0,
          name: "Alto",
        },
        {
          id: 1,
          name: "Médio",
        },
        {
          id: 2,
          name: "Baixo",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListBySituationRisk: "MatrixSituation/filterListBySituationRisk",
      filterListByImpactLevel: "MatrixSituation/filterListByImpactLevel",
      filterListByProblem: "MatrixSituation/filterListByProblem",
      clearFields() {
        this.$store.dispatch("MatrixSituation/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.$store.dispatch("MatrixSituation/loadMatrixProblems");
    this.canAcces("matrix_situation_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("matrix_situation_create").then((response) => {
      this.permitCreate = response;
    });
  },

  computed: {
    ...mapState({
      matrix_situations: (state) => state.MatrixSituation.matrix_situations,
      matrix_problems: (state) => state.MatrixSituation.matrix_problems,
      problem_filtered: (state) => state.MatrixSituation.params.problem,
      situation_risk_filtered: (state) => state.MatrixSituation.params.situation_risk,
      impact_level_filtered: (state) => state.MatrixSituation.params.impact_level,

      modalToggleMatrixSituations: (state) => state.MatrixSituation.modalToggleMatrixSituations,
      modalConfirmDeleteMatrixSituation: (state) => state.MatrixSituation.modalConfirmDeleteMatrixSituation,
      modalToggleViewMatrixSituations: (state) => state.MatrixSituation.modalToggleViewMatrixSituations,

      city: (state) => state.City.city,

      offset: (state) => state.MatrixSituation.matrixSituationOffset,
      totalPerPage: (state) => state.MatrixSituation.matrixSituationTotalPerPage,
      totalMatrixSituations: (state) => state.MatrixSituation.matrixSituationTotal,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("MatrixSituation/loadMatrixSituations");
      this.backToTop();
    },
  },
};
</script>
