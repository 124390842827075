<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Situação de Vulnerabilidade"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div>
      <AppRadioButton
        :values="vulnerabilityMatrixOptions"
        labelText="Cadastrar Matriz de Vulnerabilidade?"
        showLabel
        dispatch="PsychosocialAccompaniments/setRegisterVulnerabilityMatrix"
      />
    </div>
    <div v-if="showOpenMatrixButton">
      <AppButtonColorful
        text="Abrir Matriz"
        typeButton="danger"
        showIcon
        iconName="add"
        iconType="outlined"
        dispatch="MatrixRiskStratifications/openEditModalMatrixRiskStratification"
      />
    </div>
    <div v-if="showEditMatrixButton">
      <AppButtonColorful
        text="Editar Matriz"
        typeButton="danger"
        showIcon
        iconName="add"
        iconType="outlined"
        dispatch="MatrixRiskStratifications/openEditModalMatrixRiskStratification"
        :payload="matrix_risk_stratifications_by_psychosocial"
      />
    </div>
    <div class="col-span-1" v-if="showSituationTextField">
      <AppTextField
        show-label
        label-text="Situação de acordo com dados marcados até o momento"
        :text="getSituation()"
        color="carmesim"
        type-text="custom"
        weight="semibold"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppRadioButton from "@/components/AppRadioButton.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import AppTextField from "@/components/AppTextField.vue";
import Label from "@/components/Label.vue";

export default {
  name: "VulnerabilitySituation",
  components: {
    AppRadioButton,
    AppButtonColorful,
    AppTextField,
    Label,
  },
  props: {
    psychosocial_accompaniment: Object,
  },
  computed: {
    ...mapState({
      register_vulnerability_matrix: (state) =>
        state.PsychosocialAccompaniments.register_vulnerability_matrix,
      matrix_risk_stratifications_by_psychosocial: (state) =>
        state.PsychosocialAccompaniments.matrix_risk_stratifications_by_psychosocial,
      matrix_situations_array: (state) => state.MatrixRiskStratifications.matrix_situations_array,
    }),
    vulnerabilityMatrixOptions() {
      return [
        {
          name: "register_vulnerability_matrix",
          value: true,
          description: "Sim",
          checked: this.register_vulnerability_matrix === true,
        },
        {
          name: "register_vulnerability_matrix",
          value: false,
          description: "Não",
          checked: this.register_vulnerability_matrix === false,
        },
      ];
    },
    showOpenMatrixButton() {
      return (
        this.psychosocial_accompaniment?.meeting_happened &&
        this.register_vulnerability_matrix &&
        !this.matrix_risk_stratifications_by_psychosocial
      );
    },
    showEditMatrixButton() {
      return (
        this.psychosocial_accompaniment?.meeting_happened &&
        this.register_vulnerability_matrix &&
        this.matrix_risk_stratifications_by_psychosocial
      );
    },
    showSituationTextField() {
      return (
        this.psychosocial_accompaniment?.meeting_happened &&
        this.register_vulnerability_matrix &&
        (this.matrix_situations_array.length > 0 || this.matrix_risk_stratifications_by_psychosocial)
      );
    },
  },
  methods: {
    getSituation() {
      let situations_risk = [];
      if (this.matrix_situations_array.length > 0) {
        this.matrix_situations_array.forEach((item) => {
          situations_risk.push(item.situation_risk);
        });

        if (situations_risk.includes("Gravíssima")) {
          return "Gravíssima";
        } else if (situations_risk.includes("Grave")) {
          return "Grave";
        } else {
          return "Moderada";
        }
      } else if (
        this.matrix_risk_stratifications_by_psychosocial.matrix_risk_stratifications &&
        this.matrix_risk_stratifications_by_psychosocial.matrix_risk_stratifications.length > 0
      ) {
        this.matrix_risk_stratifications_by_psychosocial.matrix_risk_stratifications.forEach((item) => {
          situations_risk.push(item.situation_risk);
        });

        if (situations_risk.includes("Gravíssima")) {
          return "Gravíssima";
        } else if (situations_risk.includes("Grave")) {
          return "Grave";
        } else {
          return "Moderada";
        }
      } else {
        return "-";
      }
    },
  },
};
</script>
