<template>
  <div v-if="inscription.student" class="grid grid-cols-4 gap-4 mt-4">
    <div class="col-span-4 text-burnedYellow pb-2">
      <Label
        text="Informações do Aluno(a)"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <AppTextField
        show-label
        label-text="Nome"
        :text="inscription.student.name || ''"
        type-text="topic-details"
      />
    </div>
    <div>
      <AppTextField
        show-label
        label-text="Status do Aluno(a) no sistema"
        :text="verifyStatus()"
        type-text="topic-details"
      />
    </div>
    <div>
      <AppTextField
        show-label
        label-text="CPF"
        :text="verifyInscription('cpf') | VMask('###.###.###-##')"
        type-text="topic-details"
      />
    </div>
    <div>
      <AppTextField
        show-label
        label-text="Data de Nascimento"
        :text="verifyInscription('birthdate')"
        type-text="topic-details"
      />
    </div>
    <div>
      <AppTextField
        show-label
        label-text="Idade"
        :text="verifyInscription('age')"
        type-text="topic-details"
      />
    </div>
    <div>
      <AppTextField
        show-label
        label-text="Número Contato"
        :text="verifyInscription('cellphone_number') | VMask('(##) #####-####')"
        type-text="topic-details"
      />
    </div>
  </div>
</template>

<script>
import AppTextField from "@/components/AppTextField.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    AppTextField,
    Label,
  },
  props: {
    inscription: {
      type: Object,
      required: true,
    },
  },
  methods: {
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyInscription(type) {
      if (this.inscription.student) {
        if (type === "cpf" && this.inscription.student.cpf) {
          return this.inscription.student.cpf;
        } else if (type === "birthdate" && this.inscription.student.birthdate) {
          return this.formattedDate(this.inscription.student.birthdate);
        } else if (type === "cellphone_number" && this.inscription.student.cellphone_number) {
          return this.inscription.student.cellphone_number;
        } else if (type === "age" && this.inscription.student.age) {
          return this.inscription.student.age + " anos";
        } else if (type === "equipment" && this.inscription.student.equipment) {
          return this.inscription.student.equipment.name;
        }
      }
    },
    formattedDate(data) {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>
