import {
  createSupport,
  destroySupport,
  getSupportById,
  getSupports,
  updateSupport,
} from "../../service/SupportsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

const state = {
  support: {
    id: null,
    email: null,
  },

  supports: [],
  errorMessages: [],
  supportId: null,

  modalToggleAddSupport: false,
  modalToggleDeleteSupport: false,
  modalToggleViewSupport: false,
  modalToggleExportSupportsSpreadsheet: false,

  supportsTotal: 0,
  supportsTotalPerPage: 10,
  supportsOffset: 0,

  params: {
    email: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadSupports: (state, supports) => {
    state.supports = supports;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.support.id = id;
  },
  setEmailMutation: (state, email) => {
    state.support.email = email;
  },

  // --> MUTATIONS - DELETION <--
  supportForDeletion: (state, payload) => {
    if (payload) {
      state.supportId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterEmailMutation: (state, email) => {
    state.params.email = email;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddSupport: (state) => {
    state.modalToggleAddSupport = !state.modalToggleAddSupport;
  },
  toggleModalDeleteSupport: (state) => {
    state.modalToggleDeleteSupport = !state.modalToggleDeleteSupport;
  },
  toggleModalViewSupport: (state) => {
    state.modalToggleViewSupport = !state.modalToggleViewSupport;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setSupportsTotalPerPage: (state, supportsTotalPerPage) => {
    state.supportsTotalPerPage = supportsTotalPerPage;
  },
  setSupportsOffset: (state, supportsOffset) => {
    state.supportsOffset = supportsOffset;
  },
  setSupportsTotal: (state, supportsTotal) => {
    state.supportsTotal = supportsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.support) {
      state.support[prop] = null;
    }
  },
  clearQuery: (state) => {
    state.params.email = null;
    state.supports = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams() {
  return {
    email: state.params.email,
    per_page: state.supportsTotalPerPage,
    offset: state.supportsOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadSupports: ({ commit }, from) => {
    const params = getFilterParams();
    getSupports(params)
      .then((response) => {
        commit("loadSupports", response.data.supports);
        commit("setSupportsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getSupportById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.supports.id);
        commit("setEmailMutation", response.data.supports.email);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddSupport: ({ commit }) => {
    commit("toggleModalAddSupport");
  },
  closeModalAddSupport: ({ commit }) => {
    commit("toggleModalAddSupport");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddSupport: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddSupport");
  },
  closeEditModalAddSupport: ({ commit }) => {
    commit("toggleModalAddSupport");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewSupport");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewSupport");
    commit("clearFields");
  },
  toggleModalDeleteSupport: ({ commit }, payload) => {
    commit("toggleModalDeleteSupport");
    if (state.modalToggleDeleteSupport && payload) {
      commit("supportForDeletion", payload);
    } else {
      commit("supportForDeletion", null);
    }
  },

  // --> ACTIONS - FILTERLIST
  setFilterEmail: ({ commit }, payload) => {
    commit("setFilterEmailMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setSupportsTotalPerPage: ({ commit }, payload) => {
    commit("setSupportsTotalPerPage", payload.totalPerPage);
  },
  setSupportsOffset: ({ commit }, payload) => {
    commit("setSupportsOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createSupport(state.support)
      .then(() => {
        Vue.$toast.success("Email de Suporte cadastrado com sucesso!");
        dispatch("closeModalAddSupport");
        dispatch("loadSupports");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o Email de Suporte! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateSupport(state.support.id, state.support)
      .then(() => {
        dispatch("loadSupports");
        dispatch("closeEditModalAddSupport");
        Vue.$toast.success("Email de Suporte atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o Email de Suporte! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroySupport(state.supportId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Email de Suporte deletado com sucesso!");
        commit("toggleModalDeleteSupport");
        dispatch("loadSupports", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
