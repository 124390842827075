<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Identidade de Gênero e Orientação Sexual"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div>
      <Multiselect
        :close-on-select="true"
        show-label
        clear-on-select
        select-placeholder="Gênero"
        label-text="Gênero"
        :multiple-option="false"
        :value="gender"
        :options="genders"
        :dispatch="setGender"
      />
    </div>
    <div>
      <Multiselect
        :close-on-select="true"
        show-label
        clear-on-select
        select-placeholder="Se identifica como LGBTQIAPN+"
        label-text="Se identifica como LGBTQIAPN+"
        :multiple-option="false"
        :value="identifies_as_lgbtqiapn"
        :options="identifies_as_lgbtqiapns"
        :dispatch="setidentifiesAsLgbtqiapn"
      />
    </div>
    <div class="col-span-2">
      <BaseTextArea
        active
        inputName="gender_identity_observations"
        labelText="Observações"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.gender_identity_observations"
        dispatch="PsychosocialAccompaniments/setGenderIdentityObservations"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Label from "@/components/Label.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import BaseTextArea from "@/components/forms/BaseTextArea.vue";

export default {
  name: "GenderIdentity",
  components: {
    Label,
    Multiselect,
    BaseTextArea,
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      genders: (state) => state.PsychosocialAccompaniments.genders,
      gender: (state) => state.PsychosocialAccompaniments.gender,
      identifies_as_lgbtqiapns: (state) => state.PsychosocialAccompaniments.identifies_as_lgbtqiapns,
      identifies_as_lgbtqiapn: (state) => state.PsychosocialAccompaniments.identifies_as_lgbtqiapn,
    }),
  },
  methods: {
    ...mapActions({
      setGender: "PsychosocialAccompaniments/setGender",
      setidentifiesAsLgbtqiapn: "PsychosocialAccompaniments/setidentifiesAsLgbtqiapn",
    }),
  },
};
</script>
