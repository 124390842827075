<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <HeaderComponent
        :headerText="
          psychosocial_accompaniment?.id ? 'Realizar Acolhimento Inicial' : 'Adicionar Acolhimento Inicial'
        "
        headerClass="text-ashes-dark"
        closeDispatch="PsychosocialAccompaniments/closeModalFirstPsychosocialAccompaniment"
      />
    </template>
    <template v-slot:body>
      <AccompanimentInfo :accompanimentOrigin="accompaniment_origin" />
      <StudentInfo :inscription="inscription" />
      <MeetingDetails
        :psychosocial_accompaniment="psychosocial_accompaniment"
        :meeting_not_happened_reason="meeting_not_happened_reason"
        :meeting_not_happened_reasons="meeting_not_happened_reasons"
      />
      <AttendantTeams />
      <HousingSituation />
      <DisciplinarySanctions />
      <SchoolSituation />
      <GenderIdentity />
      <HealthSituation />
      <VulnerabilitySituation :psychosocial_accompaniment="psychosocial_accompaniment" />
      <ForwardingDetails :psychosocial_accompaniment="psychosocial_accompaniment" />
      <AccompanimentPlan :psychosocial_accompaniment="psychosocial_accompaniment" />
      <MeetingConclusion :psychosocial_accompaniment="psychosocial_accompaniment" />
      <ModalMatrixRiskStratifications :open="modalToggleMatrixRiskStratifications" />
      <SavePsychosocialAccompanimentWithoutMatrixConfirmation
        :open="modalConfirmSavePsychosocialAccompanimentWithoutMatrix"
      />
    </template>
    <template v-slot:footer>
      <ModalFooterActions
        closeDispatch="PsychosocialAccompaniments/closeModalFirstPsychosocialAccompaniment"
        updateDispatch="PsychosocialAccompaniments/update"
        saveDispatch="PsychosocialAccompaniments/save"
      />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import ModalMatrixRiskStratifications from "../ModalMatrixRiskStratifications.vue";
import SavePsychosocialAccompanimentWithoutMatrixConfirmation from "../alerts/SavePsychosocialAccompanimentWithoutMatrixConfirmation.vue";
import HeaderComponent from "@/views/psychosocialaccompanimentpages/components/HeaderComponent.vue";
import AccompanimentInfo from "@/views/psychosocialaccompanimentpages/components/AccompanimentInfo.vue";
import StudentInfo from "@/views/psychosocialaccompanimentpages/components/StudentInfo.vue";
import MeetingDetails from "@/views/psychosocialaccompanimentpages/components/MeetingDetails.vue";
import AttendantTeams from "@/views/psychosocialaccompanimentpages/components/AttendantTeams.vue";
import HousingSituation from "@/views/psychosocialaccompanimentpages/components/HousingSituation.vue";
import DisciplinarySanctions from "@/views/psychosocialaccompanimentpages/components/DisciplinarySanctions.vue";
import SchoolSituation from "@/views/psychosocialaccompanimentpages/components/SchoolSituation.vue";
import GenderIdentity from "@/views/psychosocialaccompanimentpages/components/GenderIdentity.vue";
import HealthSituation from "@/views/psychosocialaccompanimentpages/components/HealthSituation.vue";
import VulnerabilitySituation from "@/views/psychosocialaccompanimentpages/components/VulnerabilitySituation.vue";
import ForwardingDetails from "@/views/psychosocialaccompanimentpages/components/ForwardingDetails.vue";
import AccompanimentPlan from "@/views/psychosocialaccompanimentpages/components/AccompanimentPlan.vue";
import MeetingConclusion from "@/views/psychosocialaccompanimentpages/components/MeetingConclusion.vue";
import ModalFooterActions from "@/views/psychosocialaccompanimentpages/components/ModalFooterActions.vue";
import { mapState } from "vuex";

export default {
  components: {
    ModalMatrixRiskStratifications,
    SavePsychosocialAccompanimentWithoutMatrixConfirmation,
    BaseModal,
    HeaderComponent,
    AccompanimentInfo,
    StudentInfo,
    MeetingDetails,
    AttendantTeams,
    HousingSituation,
    DisciplinarySanctions,
    SchoolSituation,
    GenderIdentity,
    HealthSituation,
    VulnerabilitySituation,
    ForwardingDetails,
    AccompanimentPlan,
    MeetingConclusion,
    ModalFooterActions,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      meeting_not_happened_reasons: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reasons,
      meeting_not_happened_reason: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reason,
      accompaniment_origin: (state) => state.PsychosocialAccompaniments.accompaniment_origin,
      modalToggleMatrixRiskStratifications: (state) =>
        state.MatrixRiskStratifications.modalToggleMatrixRiskStratifications,
      modalConfirmSavePsychosocialAccompanimentWithoutMatrix: (state) =>
        state.PsychosocialAccompaniments.modalConfirmSavePsychosocialAccompanimentWithoutMatrix,
    }),
  },
};
</script>
