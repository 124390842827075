<template>
  <div class="grid grid-cols-2 gap-4 mt-2">
    <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
      <Label
        text="Profissionais que acompanham"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
      <AppRadioButton
        :values="attendantTeamsOptions"
        labelText="Informar profissionais que acompanham o Atendimento?"
        showLabel
        dispatch="PsychosocialAccompaniments/setRegisterAttendatTeams"
      />
    </div>
    <div class="col-span-2" v-if="register_attendant_teams">
      <div
        class="grid grid-cols-12 gap-4 mt-2"
        v-for="(attendant, index) in attendant_teams_attributes"
        :key="index"
      >
        <div class="col-span-11" v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Profissional"
            :multiple-option="false"
            :value="psychosocial_attendant_attributes[index]"
            :options="mountPsychosocialAttendants(index)"
            required
            :dispatch="setPsychosocialAttendant"
          />
        </div>
        <div
          class="mt-5"
          v-if="attendant_teams_attributes.length > 1 && psychosocial_accompaniment.meeting_happened"
        >
          <a href="javascript:void(0)" class="text-burnedYellow underline" @click="deleteAttendantTeam">
            <AppMaterialIcon icon-name="delete" icon-size="36" class="align-middle" />
          </a>
        </div>
      </div>
      <div
        class="col-span-4 mt-5"
        v-if="attendant_teams_attributes.length > 1 && psychosocial_accompaniment.meeting_happened"
      >
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>
    <div class="mt-5" v-if="psychosocial_accompaniment?.meeting_happened && register_attendant_teams">
      <a href="javascript:void(0)" class="text-burnedYellow" @click="addAttendantTeam">
        <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
        <BaseText
          text="Adicionar Profissional"
          type-text="custom"
          weight="medium"
          size="sm"
          class="align-middle mr-2"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Label from "@/components/Label.vue";
import AppRadioButton from "@/components/AppRadioButton.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";
import BaseText from "@/components/BaseText.vue";

export default {
  name: "AttendantTeams",
  components: {
    Label,
    AppRadioButton,
    Multiselect,
    AppMaterialIcon,
    BaseText,
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      register_attendant_teams: (state) => state.PsychosocialAccompaniments.register_attendant_teams,
      attendant_teams_attributes: (state) => state.PsychosocialAccompaniments.attendant_teams_attributes,
      psychosocial_attendant_attributes: (state) =>
        state.PsychosocialAccompaniments.psychosocial_attendant_attributes,
    }),
    attendantTeamsOptions() {
      return [
        {
          name: "register_attendant_teams",
          value: true,
          description: "Sim",
          checked: this.register_attendant_teams === true,
        },
        {
          name: "register_attendant_teams",
          value: false,
          description: "Não",
          checked: this.register_attendant_teams === false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setPsychosocialAttendant: "PsychosocialAccompaniments/setPsychosocialAttendant",
      addAttendantTeam: "PsychosocialAccompaniments/addAttendantTeam",
      deleteAttendantTeam: "PsychosocialAccompaniments/deleteAttendantTeam",
    }),
    mountPsychosocialAttendants(index) {
      return this.$store.state.PsychosocialAccompaniments.psychosocial_attendants_for_psychosocial.map(
        (attendant) => ({ index, ...attendant })
      );
    },
  },
};
</script>
