<template>
  <BaseModal width="w-128" v-if="open">
    <template v-slot:header>
      <div class="flex flex-row-reverse">
        <AppButtonIcon for-close dispatch="SeasonPreviousInscriptions/toggleModalActivateInscription" />
      </div>
      <div class="flex">
        <img src="../../assets/images/icon-alerta.svg" class="mr-2" />
        <BaseText text="Ativar Inscrição" typeText="custom" weight="semibold" size="2xl" color="ashes-dark" />
      </div>
    </template>
    <template v-slot:body>
      <div class="mt-5">
        <BaseText :text="getTitle" typeText="custom" weight="extrabold" color="quantum" size="lg" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end">
        <AppButtonEmpty
          text="Não"
          type-button="danger"
          dispatch="SeasonPreviousInscriptions/toggleModalActivateInscription"
          class="mr-5"
        />
        <AppButtonEmpty
          text="Sim"
          type-button="success"
          dispatch="SeasonPreviousInscriptions/activateInscription"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  data() {
    return {
      permitReactivateSeason: false,
    };
  },
  created() {
    this.permitReactivateSeasonAccess();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitReactivateSeasonAccess() {
      this.canAcces("season_reactivate_season").then((response) => {
        this.permitReactivateSeason = response;
      });
    },
  },
  computed: {
    ...mapState({
      seasonPreviousInscription: (state) => state.SeasonPreviousInscriptions.seasonPreviousInscription,
    }),
    getTitle() {
      return `Ao ativar uma inscrição, todas as outras inscrições do aluno de outras edições serão inativadas. Tem certeza que deseja ativar esta inscrição vinculada à edição ${this.seasonPreviousInscription.season.name}?`;
    },
  },
};
</script>
