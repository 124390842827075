<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="support.id"
            text="Editar Suporte"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Suporte"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Supports/closeModalAddSupport" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="email"
            input-name="email"
            input-type="text"
            show-label
            required
            label-text="Email"
            :value="support.email"
            dispatch="Supports/setEmail"
            :error-message="getErrorMessage('email')"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Supports/closeModalAddSupport" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="support.id"
            text="Atualizar"
            typeButton="success"
            dispatch="Supports/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Supports/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import Label from "@/components/Label.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setState: "Supports/setState",
      setCity: "Supports/setCity",
    }),
  },
  computed: {
    ...mapState({
      support: (state) => state.Supports.support,
    }),
    ...mapGetters({
      getErrorMessage: "Supports/getErrorMessage",
    }),
  },
};
</script>
