import { getAxios, baseURL } from "./Api";

export async function getDisciplinarySanctionTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`sancoes_disciplinares/lista_tipos`);
}

export async function getDisciplinarySanctionStatus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`sancoes_disciplinares/lista_status`);
}

export async function getDisciplinarySanctionComplianceModes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`sancoes_disciplinares/lista_meios`);
}

export async function getDisciplinarySanctionPenaltyTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`sancoes_disciplinares/lista_penas`);
}

export async function getDisciplinarySanctionCompletionTimes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`sancoes_disciplinares/lista_opcoes_tempo_finalizacao`);
}

export async function getDisciplinarySanctionCompletionForecasts() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`sancoes_disciplinares/lista_opcoes_previsao_finalizacao`);
}
