<template>
  <div class="grid grid-cols-2 gap-4 mt-2" v-if="psychosocial_accompaniment?.meeting_happened">
    <div class="col-span-2 text-burnedYellow pb-2">
      <Label
        text="Situação Escolar"
        :icon="null"
        :icon-type="null"
        type="text-sm"
        weight="semibold"
        normal
        mode="uppercase"
      />
      <div class="border-ashes-hover border-b-2 w-full"></div>
    </div>
    <div class="col-span-2">
      <AppRadioButton
        :values="isStudyingOptions"
        labelText="Está estudando?"
        showLabel
        dispatch="PsychosocialAccompaniments/setIsStudying"
      />
    </div>
    <div class="col-span-2">
      <BaseTextArea
        active
        inputName="reason_for_not_studying"
        labelText="Razão para não estar estudando"
        showLabel
        rows="4"
        :value="psychosocial_accompaniment?.reason_for_not_studying"
        dispatch="PsychosocialAccompaniments/setReasonForNotStudying"
        :readonly="psychosocial_accompaniment?.is_studying"
      />
    </div>
    <div class="col-span-1">
      <BaseInput
        active
        input-name="last_grade_studied"
        input-type="text"
        :value="psychosocial_accompaniment?.last_grade_studied"
        show-label
        label-text="Última série estudada"
        dispatch="PsychosocialAccompaniments/setLastGradeStudied"
        :readonly="psychosocial_accompaniment?.is_studying"
      />
    </div>
    <div class="col-span-1">
      <BaseInput
        active
        input-name="last_school_studied"
        input-type="text"
        :value="psychosocial_accompaniment?.last_school_studied"
        show-label
        label-text="Última escola em que estudou"
        dispatch="PsychosocialAccompaniments/setLastSchoolStudied"
        :readonly="psychosocial_accompaniment?.is_studying"
      />
    </div>
    <div class="col-span-2">
      <AppRadioButton
        :values="needReinsertionOptions"
        labelText="Necessita de reinserção escolar?"
        showLabel
        dispatch="PsychosocialAccompaniments/setNeedSchoolReinsertion"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Label from "@//components/Label.vue";
import AppRadioButton from "@//components/AppRadioButton.vue";
import BaseInput from "@//components/forms/BaseInput.vue";
import BaseTextArea from "@//components/forms/BaseTextArea.vue";

export default {
  name: "SchoolSituation",
  components: {
    Label,
    AppRadioButton,
    BaseInput,
    BaseTextArea,
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
    }),
    isStudyingOptions() {
      return [
        {
          name: "is_studying",
          value: true,
          description: "Sim",
          checked: this.psychosocial_accompaniment?.is_studying === true,
        },
        {
          name: "is_studying",
          value: false,
          description: "Não",
          checked: this.psychosocial_accompaniment?.is_studying === false,
        },
      ];
    },
    needReinsertionOptions() {
      return [
        {
          name: "need_school_reinsertion",
          value: true,
          description: "Sim",
          checked: this.psychosocial_accompaniment?.need_school_reinsertion === true,
        },
        {
          name: "need_school_reinsertion",
          value: false,
          description: "Não",
          checked: this.psychosocial_accompaniment?.need_school_reinsertion === false,
        },
      ];
    },
  },
};
</script>
