<template>
  <div class="font-system">
    <div class="mx-12 my-6">
      <Breadcrumbs :breadcrumbs="breadcrumbs" />
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">Vale-Transporte</div>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4">
      <div class="w-52">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          select-placeholder="Selecionar edição"
          :multiple-option="false"
          :value="season"
          :options="seasons"
          label-text="Edição"
          :dispatch="setSeason"
          required
        />
      </div>
    </div>
    <div id="menus_productive_insertion_main_page" class="mx-16 flex" v-if="season">
      <div v-if="permitListTransportationVoucherPaymentSheetTab">
        <a @click="changeTab('transportation_voucher_payment_sheet')" href="javascript:void(0)">
          <BaseText
            text="Folha de Pagamento"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorTransportationVoucherPaymentSheets"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitListTransportationVoucherPaymentControlTab">
        <a @click="changeTab('transportation_voucher_payment_control')" href="javascript:void(0)">
          <BaseText
            text="Controle de Pagamento"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorTransportationVoucherPaymentControls"
          />
        </a>
      </div>
    </div>
    <div id="list-productive-insertion-types">
      <div v-if="season">
        <TransportationVoucherPaymentSheetMainPage :open="toggleTransportationVoucherPaymentSheets" />
      </div>
      <div v-if="season">
        <TransportationVoucherPaymentControlMainPage :open="toggleTransportationVoucherPaymentControls" />
      </div>
    </div>
  </div>
</template>

<script>
import TransportationVoucherPaymentSheetMainPage from "@/views/benefits/transportation_voucher/payment_sheet/TransportationVoucherPaymentSheetMainPage.vue";
import TransportationVoucherPaymentControlMainPage from "@/views/benefits/transportation_voucher/payment_control/TransportationVoucherPaymentControlMainPage.vue";
import BaseText from "@/components/BaseText.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    TransportationVoucherPaymentSheetMainPage,
    TransportationVoucherPaymentControlMainPage,
    Multiselect,
    Breadcrumbs,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitListTransportationVoucherPaymentSheetTab: false,
      permitListTransportationVoucherPaymentControlTab: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "Advantages/setSeason",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.$store.dispatch("Advantages/setTransportationVoucherTabAdvantageView", value);
    },
  },
  created() {
    this.$store.dispatch("Advantages/loadSeasons");
    this.$store.dispatch(
      "Advantages/setTransportationVoucherTabAdvantageView",
      "transportation_voucher_payment_sheet"
    );
    const season = JSON.parse(localStorage.getItem("season"));
    if (season) {
      this.$store.dispatch("Advantages/setSeason", season);
    }

    this.canAcces("benefit_transportation_voucher_payment_sheet_tab_permission").then((response) => {
      this.permitListTransportationVoucherPaymentSheetTab = response;
    });
    this.canAcces("benefit_transportation_voucher_payment_control_tab_permission").then((response) => {
      this.permitListTransportationVoucherPaymentControlTab = response;
    });
    this.$store.dispatch("PaymentSheets/clearQuery");
  },
  computed: {
    ...mapState({
      seasons: (state) => state.Advantages.seasons,
      season: (state) => state.Advantages.season,
      tabTransportationVoucherAdvantageView: (state) =>
        state.Advantages.tabTransportationVoucherAdvantageView,
      toggleTransportationVoucherPaymentSheets: (state) =>
        state.Advantages.toggleTransportationVoucherPaymentSheets,
      toggleTransportationVoucherPaymentControls: (state) =>
        state.Advantages.toggleTransportationVoucherPaymentControls,
    }),
    setColorTransportationVoucherPaymentSheets() {
      return this.tabTransportationVoucherAdvantageView === "transportation_voucher_payment_sheet"
        ? "burnedYellow"
        : "ashes-hover";
    },
    setColorTransportationVoucherPaymentControls() {
      return this.tabTransportationVoucherAdvantageView === "transportation_voucher_payment_control"
        ? "burnedYellow"
        : "ashes-hover";
    },
    breadcrumbs() {
      return [
        { name: "Home", path: "/admin" },
        { name: "Vale-Transporte", path: "/vale_transporte" },
      ];
    },
  },
};
</script>
