import { getAxios, baseURL } from "./Api";

export async function getMeasures(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `medidas_cautelares`;
  return api.get(url, { params: filters });
}

export async function getMeasuresForMenus(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `medidas_cautelares/para_menus`;
  return api.get(url, { params: filters });
}
