<template>
  <div class="grid grid-cols-2 col-auto">
    <div class="justify-self-start">
      <AppButtonEmpty text="descartar" typeButton="danger" :dispatch="closeDispatch" />
    </div>
    <div class="justify-self-end">
      <AppButtonEmpty
        v-if="psychosocial_accompaniment?.id"
        text="Salvar"
        typeButton="success"
        :dispatch="updateDispatch"
      />
      <AppButtonEmpty v-else text="criar" typeButton="success" :dispatch="saveDispatch" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";

export default {
  name: "ModalFooterActions",
  components: {
    AppButtonEmpty,
  },
  props: {
    closeDispatch: {
      type: String,
      required: true,
    },
    updateDispatch: {
      type: String,
      required: true,
    },
    saveDispatch: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
    }),
  },
};
</script>
