import { getUsers, getUsersForSpreadsheet } from "../../service/UsersApi";
import Vue from "vue";

const state = {
  users: [],
  filterUsers: [],
  areas_by_city: [],
  users_spreadsheet: [],
  modalDeleteUsersConfirmationOpen: false,
  modalToggleExportUsersSpreadsheet: false,

  hasSpreadsheetUsersResponse: true,
  json_data_user: null,

  userIdForDeletion: null,
  usersTotal: 0,
  usersTotalPerPage: 10,
  usersOffset: 0,
  statuses: [
    {
      id: 1,
      name: "Ativo",
    },
    {
      id: 2,
      name: "Desativado",
    },
  ],
  params: {
    name: null,
    email: null,
    cpf: null,
    ability_profile: null,
    status: null,
    city: null,
    area: null,
  },
};

const mutations = {
  setUsersMutation: (state, users) => {
    state.users = users;
  },
  addUserMutation: (state, user) => {
    state.users.push(user);
  },
  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterEmailMutation: (state, email) => {
    state.params.email = email;
  },
  setFilterCpfMutation: (state, cpf) => {
    state.params.cpf = cpf;
  },
  setFilterAbilityProfileMutation: (state, ability_profile) => {
    state.params.ability_profile = ability_profile;
  },
  setFilterStatusMutation: (state, status) => {
    state.params.status = status;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  filterListMutation: (state, input) => {
    let users = state.users;
    state.filterUsers = users.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());

      return name > -1 || cpf > -1;
    });
  },
  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportUsersSpreadsheet: (state) => {
    state.modalToggleExportUsersSpreadsheet = !state.modalToggleExportUsersSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetUsersResponseMutation: (state, hasSpreadsheetUsersResponse) => {
    state.hasSpreadsheetUsersResponse = hasSpreadsheetUsersResponse;
  },
  // --> MUTATIONS - PAGINATION FIELDS <--
  setUsersTotalPerPage: (state, usersTotalPerPage) => {
    state.usersTotalPerPage = usersTotalPerPage;
  },
  setUsersOffset: (state, usersOffset) => {
    state.usersOffset = usersOffset;
  },
  setUsersTotal: (state, usersTotal) => {
    state.usersTotal = usersTotal;
  },
  setUsersPageToExportMutation: (state, user) => {
    state.json_data_user = null;
    const arrayData = [];

    user.forEach((element, index, array) => {
      let name = array[index].name ? array[index].name : "-";
      let email = array[index].email ? array[index].email : "-";
      let cpf = array[index].cpf ? array[index].cpf : "-";
      let ability_profile = array[index].ability_profile ? array[index].ability_profile.name : "-";
      let active = array[index].active === true ? "Ativo" : "Desativado";
      let user_cities = [];
      if (array[index].cities_attributes && array[index].cities_attributes?.length > 0) {
        array[index].cities_attributes.forEach((item) => {
          user_cities.push(item.name);
        });
      }
      let user_areas = [];
      if (array[index].areas && array[index].areas?.length > 0) {
        array[index].areas.forEach((item) => {
          user_areas.push(item.name);
        });
      }

      const arrayItem = [];
      arrayItem["name"] = name;
      arrayItem["email"] = email;
      arrayItem["cpf"] = cpf;
      arrayItem["ability_profile"] = ability_profile;
      arrayItem["active"] = active;
      arrayItem["user_cities"] = user_cities;
      arrayItem["user_areas"] = user_areas;
      arrayData.push(arrayItem);
    });

    state.json_data_user = arrayData;
    state.hasSpreadsheetUsersResponse = true;
    state.modalToggleExportUsersSpreadsheet = true;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setUsersSpreadsheetMutation: (state, users_spreadsheet) => {
    state.users_spreadsheet = users_spreadsheet;
  },
  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.name = null;
    state.params.email = null;
    state.params.cpf = null;
    state.params.ability_profile = null;
    state.params.status = null;
    state.params.city = null;
    state.params.area = null;
    state.users = [];
  },
};

function getFilterParams() {
  return {
    name: state.params.name,
    email: state.params.email,
    cpf: state.params.cpf,
    ability_profile_id: state.params.ability_profile ? state.params.ability_profile.id : null,
    area_id: state.params.area ? state.params.area.id : null,
    status: state.params.status ? state.params.status.id : null,
    city: state.params.city ? state.params.city.id : null,
    per_page: state.usersTotalPerPage,
    offset: state.usersOffset,
  };
}

const actions = {
  setUsers: ({ commit }, users) => {
    commit("setUsersMutation", users);
  },
  addUser: ({ commit }, user) => {
    commit("addUserMutation", user);
  },
  filterList: ({ commit }, payload) => {
    commit("filterListMutation", payload);
  },
  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterEmail: ({ commit }, payload) => {
    commit("setFilterEmailMutation", payload.value);
  },
  setFilterCpf: ({ commit }, payload) => {
    commit("setFilterCpfMutation", payload.value);
  },
  setFilterAbilityProfile: ({ commit }, payload) => {
    commit("setFilterAbilityProfileMutation", payload);
  },
  setFilterStatus: ({ commit }, payload) => {
    commit("setFilterStatusMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  // --> ACTIONS - PAGINATIONS <--
  setUsersTotalPerPage: ({ commit }, payload) => {
    commit("setUsersTotalPerPage", payload.totalPerPage);
  },
  setUsersOffset: ({ commit }, payload) => {
    commit("setUsersOffset", payload.offset);
  },
  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportUsersSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportUsersSpreadsheet");
  },
  closeExportUsersSpreadsheetModal: ({ commit, dispatch }) => {
    commit("toggleModalToggleExportUsersSpreadsheet");
    dispatch("SelectFieldsToExport/clearFields", null, { root: true });
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  loadUsers: ({ commit, rootGetters }) => {
    const params = getFilterParams(rootGetters);
    getUsers(params)
      .then((response) => {
        commit("setUsersMutation", response.data.user);
        commit("setUsersTotal", response.data.total);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadUsersForExport: ({ commit, rootState }) => {
    commit("hasSpreadsheetUsersResponseMutation", false);
    const params = getFilterParams(rootState);
    getUsersForSpreadsheet(params)
      .then(async (response) => {
        commit("setUsersSpreadsheetMutation", response.data.users);
        if (state.users_spreadsheet.length > 0) {
          await commit("setUsersPageToExportMutation", response.data.users);
        } else {
          commit("hasSpreadsheetUsersResponseMutation", true);
          commit("toggleModalToggleExportUsersSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e);
        commit("hasSpreadsheetUsersResponseMutation", true);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
