<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
        Participantes de Edições Anteriores
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="params.name"
            label-text="Pesquisar pelo nome"
            input-placeholder="Pesquisar pelo nome"
            dispatch="SeasonPreviousInscriptions/setFilterName"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="cpf"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="params.cpf"
            label-text="Pesquisar pelo cpf"
            input-placeholder="Pesquisar pelo cpf"
            dispatch="SeasonPreviousInscriptions/setFilterCpf"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="rg"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="params.rg"
            label-text="Pesquisar pelo rg"
            input-placeholder="Pesquisar pelo rg"
            dispatch="SeasonPreviousInscriptions/setFilterRg"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-partners"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="SeasonPreviousInscriptions/loadSeasonPreviousInscriptions"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="seasonPreviousInscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-partners"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-partners">
      <div class="mx-12">
        <SeasonPreviousInscriptionsCards :data="seasonPreviousInscriptions" />
      </div>
      <div id="partners-pagination" class="mx-12 my-6 pb-3" v-if="seasonPreviousInscriptions.length > 0">
        <BasePagination
          :total="totalSeasonPreviousInscriptions"
          :total-per-page="totalPerPage"
          dispatch="SeasonPreviousInscriptions/setSeasonPreviousInscriptionsOffset"
        />
      </div>
      <ActivateInscriptionConfirmation :open="modalToggleActivateInscription" />
      <DeactivateInscriptionConfirmation :open="modalToggleDeactivateInscription" />
      <ModalTransferInscriptionToSeason :open="modalToggleTransferInscription" />
    </div>
  </div>
</template>

<script>
import SeasonPreviousInscriptionsCards from "@/views/list/SeasonPreviousInscriptionsCards.vue";
import BasePagination from "@/components/BasePagination.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import ActivateInscriptionConfirmation from "@/views/alerts/ActivateInscriptionConfirmation.vue";
import DeactivateInscriptionConfirmation from "@/views/alerts/DeactivateInscriptionConfirmation.vue";
import ModalTransferInscriptionToSeason from "@/views/ModalTransferInscriptionToSeason.vue";

export default {
  components: {
    FilterComponent,
    SeasonPreviousInscriptionsCards,
    BasePagination,
    ActivateInscriptionConfirmation,
    DeactivateInscriptionConfirmation,
    ModalTransferInscriptionToSeason,
    AppButtonColorful,
    BaseInput,
  },
  data: () => ({
    permit: false,
  }),
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      clearFields() {
        this.$store.dispatch("SeasonPreviousInscriptions/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },

  created() {
    this.$store.dispatch("UtilFunctions/loadCities");
    this.$store.dispatch("SeasonPreviousInscriptions/loadSeasons");
    this.canAcces("season_previous_participants").then((response) => {
      this.permit = response;
    });
  },

  computed: {
    ...mapState({
      params: (state) => state.SeasonPreviousInscriptions.params,
      modalToggleActivateInscription: (state) =>
        state.SeasonPreviousInscriptions.modalToggleActivateInscription,
      modalToggleDeactivateInscription: (state) =>
        state.SeasonPreviousInscriptions.modalToggleDeactivateInscription,
      modalToggleTransferInscription: (state) =>
        state.SeasonPreviousInscriptions.modalToggleTransferInscription,
      seasonPreviousInscriptions: (state) => state.SeasonPreviousInscriptions.seasonPreviousInscriptions,
      offset: (state) => state.SeasonPreviousInscriptions.seasonPreviousInscriptionsOffset,
      totalPerPage: (state) => state.SeasonPreviousInscriptions.seasonPreviousInscriptionsTotalPerPage,
      totalSeasonPreviousInscriptions: (state) =>
        state.SeasonPreviousInscriptions.seasonPreviousInscriptionsTotal,
      cities: (state) => state.UtilFunctions.cities,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("SeasonPreviousInscriptions/loadSeasonPreviousInscriptions");
      this.backToTop();
    },
  },
};
</script>
