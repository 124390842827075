import { getStudentsPhaseOne, getStudentsPhaseTwo, getStudentsPhaseThree } from "../../service/AllocationApi";
import { updateTeam, removeTeamStudent, transferTeamStudent } from "../../service/TeamApi";
import { getMeetingByTeam } from "../../service/MeetingApi";
import Vue from "vue";

const state = {
  toggleModal: false,
  allTeams: [],
  meetings: [],
  teams: {
    students_attributes: [],
  },
  students: [],
  phaseOneStudents: [],
  phaseTwoStudents: [],
  phaseThreeStudents: [],

  phaseOneStudentsTotal: 0,
  phaseOneStudentsTotalPerPage: 10,
  phaseOneStudentsOffset: 0,

  filters: {
    area: null,
    equipment: null,
    name: null,
  },
  loading: {
    modalAllocationStudents: false,
  },
};

const mutations = {
  toggleModalMutation: (state) => {
    state.toggleModal = !state.toggleModal;
  },
  setStudentsMutation: (state, student) => {
    state.students = student;
  },
  cleanStudentsAttributesMutation: (state, student) => {
    state.teams.students_attributes = student;
  },
  addStudentMutation: (state, student) => {
    if (typeof state.teams.students_attributes == "undefined") {
      state.teams.students_attributes = [];
    }
    if (!state.teams.students_attributes.some((attr) => attr.id === student.id)) {
      state.teams.students_attributes.push(student);
    }
  },
  removeStudentMutation: (state, student) => {
    state.teams.students_attributes = state.teams.students_attributes.filter(
      (attr) => attr.id !== student.id
    );
  },
  setFilterAreaMutation: (state, area) => {
    state.filters.area = area;
  },
  setFilterEquipmentMutation: (state, equipment) => {
    state.filters.equipment = equipment;
  },
  setFilterNameMutation: (state, name) => {
    state.filters.name = name;
  },
  setLoadingModalAllocationStudents: (state, modalAllocationStudents) => {
    state.loading.modalAllocationStudents = modalAllocationStudents;
  },
  setPhaseOneStudentsMutation: (state, students) => {
    if (typeof state.teams.students_attributes === "undefined") {
      state.teams.students_attributes = [];
    }

    state.phaseOneStudents = students.map((student) => ({
      ...student,
      checked: state.teams.students_attributes.some((attr) => attr.id === student.id),
    }));
  },
  toggleStudentCheckedMutation: (state, { studentId, checked }) => {
    const student = state.phaseOneStudents.find((s) => s.id === studentId);
    if (student) {
      student.checked = checked;
    }
  },
  setAllTeamsMutation: (state, payload) => {
    state.allTeams = payload;
  },
  setMeetings: (state, meetings) => {
    state.meetings = meetings;
  },
  // --> MUTATIONS - PAGINATION FIELDS <--
  setPhaseOneStudentsTotalPerPage: (state, phaseOneStudentsTotalPerPage) => {
    state.phaseOneStudentsTotalPerPage = phaseOneStudentsTotalPerPage;
  },
  setPhaseOneStudentsOffset: (state, phaseOneStudentsOffset) => {
    state.phaseOneStudentsOffset = phaseOneStudentsOffset;
  },
  setPhaseOneStudentsTotal: (state, phaseOneStudentsTotal) => {
    state.phaseOneStudentsTotal = phaseOneStudentsTotal;
  },
  transferStudentToTeamMutation: (state, payload) => {
    const idSource = payload.source_team.id;
    const idTarget = payload.target_team.id;
    const studentId = payload.inscription.student.id;

    transferTeamStudent(studentId, idSource, idTarget)
      .then((response) => {
        Vue.$toast.success(response.data.status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  removeStudentFromTeamMutation: (state, payload) => {
    const idSource = payload.id;
    removeTeamStudent(payload.inscription.student.id, idSource)
      .then((response) => {
        Vue.$toast.success(response.data.status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  clearQuery: (state) => {
    state.filters.name = null;
    state.filters.area = null;
    state.filters.equipment = null;
    state.phaseOneStudents = [];
  },
};
function getFilterParams(payload) {
  return {
    season_id: payload.season_id,
    phase_id: payload.phase_id,
    disciplineId: payload?.disciplineId,
    name: state.filters.name,
    area_id: state.filters.area?.id,
    equipment_id: state.filters.equipment?.id,
    per_page: state.phaseOneStudentsTotalPerPage,
    offset: state.phaseOneStudentsOffset,
  };
}
const actions = {
  cleanStudentsAttributesMutation: ({ commit }) => {
    commit("cleanStudentsAttributesMutation");
  },
  openModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalMutation");
    commit("clearQuery");
  },
  setAllTeams: ({ commit }, payload) => {
    commit("setAllTeamsMutation", payload);
  },
  loadMeetings: ({ commit }, id) => {
    getMeetingByTeam(id)
      .then((response) => {
        commit("setMeetings", response.data.meetings);
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  addStudent: ({ commit }, payload) => {
    commit("addStudentMutation", payload);
  },
  setStudents: ({ commit }, payload) => {
    commit("setStudentsMutation", payload);
  },
  removeStudent: ({ commit }, payload) => {
    commit("removeStudentMutation", payload);
  },
  loadStudentsForAllocation: ({ dispatch, rootState }) => {
    const seasonId = rootState.Team.season.id;
    const phaseId = rootState.Team.discipline.phase.id;

    switch (phaseId) {
      case 1:
        dispatch("loadPhaseOneStudents", { season_id: seasonId, phase_id: phaseId });
        break;
      case 2:
        dispatch("loadPhaseTwoStudents", { season_id: seasonId });
        break;
      case 3:
        dispatch("loadPhaseThreeStudents", { season_id: seasonId });
        break;
    }
  },
  loadPhaseOneStudents: ({ commit }, payload) => {
    commit("setLoadingModalAllocationStudents", true);
    const params = getFilterParams(payload);
    getStudentsPhaseOne(params)
      .then((response) => {
        commit("setPhaseOneStudentsMutation", response.data.students);
        commit("setPhaseOneStudentsTotal", response.data.total);
        commit("setLoadingModalAllocationStudents", false);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
        commit("setLoadingModalAllocationStudents", false);
      });
  },
  loadPhaseTwoStudents: ({ commit, rootState }, payload) => {
    commit("setLoadingModalAllocationStudents", true);
    payload.disciplineId = rootState.Team.discipline.id;
    const params = getFilterParams(payload);
    getStudentsPhaseTwo(params)
      .then((response) => {
        commit("setPhaseOneStudentsMutation", response.data.students);
        commit("setPhaseOneStudentsTotal", response.data.total);
        commit("setLoadingModalAllocationStudents", false);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
        commit("setLoadingModalAllocationStudents", false);
      });
  },

  loadPhaseThreeStudents: ({ commit, rootState }, payload) => {
    commit("setLoadingModalAllocationStudents", true);
    payload.disciplineId = rootState.Team.discipline.id;
    const params = getFilterParams(payload);
    getStudentsPhaseThree(params)
      .then((response) => {
        commit("setPhaseOneStudentsMutation", response.data.students);
        commit("setPhaseOneStudentsTotal", response.data.total);
        commit("setLoadingModalAllocationStudents", false);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
        commit("setLoadingModalAllocationStudents", false);
      });
  },
  allocatePhaseOneStudents: ({ commit, rootState, dispatch, state }) => {
    const id = rootState.Team.id;
    if (
      typeof state.teams.students_attributes !== "undefined" &&
      state.teams.students_attributes.length > 0
    ) {
      const anterior = state.students;
      anterior.forEach((element) => {
        commit("addStudentMutation", element);
      });
      updateTeam(id, state.teams)
        .then((response) => {
          Vue.$toast.success(response.data.message);
          commit("toggleModalMutation");
          commit("setStudentsMutation", state.teams.students_attributes);
          commit("cleanStudentsAttributesMutation");
          dispatch("loadStudentsForAllocation");
        })
        .catch((e) => {
          let mess_error = "Não foi possivel alocar o aluno na turma!";
          if (e.response.data.exception.indexOf("ForeignKeyViolation")) {
            mess_error += "\nJá foram gerados benefícios para esta turma...";
          }
          Vue.$toast.error(mess_error);
        });
    } else {
      commit("cleanStudentsAttributesMutation");
      let mess_error = "Nenhum estudante selecionado...";
      Vue.$toast.error(mess_error);
    }
  },
  removeStudentFromTeam: ({ dispatch }, payload) => {
    const teamIdToRemove = payload.team.id;
    const studentId = payload.inscription.student.id;
    removeTeamStudent(studentId, teamIdToRemove)
      .then((response) => {
        Vue.$toast.success(response.data.status);
        dispatch("Inscription/load", payload.inscription, { root: true });
        dispatch("ModalInscription/updateInscription", {}, { root: true });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  transferStudentToTeam: ({ dispatch }, payload) => {
    const idSource = payload.source_team.id;
    const idTarget = payload.target_team.id;
    const studentId = payload.inscription.student.id;

    transferTeamStudent(studentId, idSource, idTarget)
      .then((response) => {
        Vue.$toast.success(response.data.status);
        dispatch("Inscription/load", payload.inscription, { root: true });
        dispatch("ModalInscription/updateInscription", {}, { root: true });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  // --> ACTIONS - PAGINATIONS <--
  setPhaseOneStudentsTotalPerPage: ({ commit }, payload) => {
    commit("setPhaseOneStudentsTotalPerPage", payload.totalPerPage);
  },
  setPhaseOneStudentsOffset: ({ commit }, payload) => {
    commit("setPhaseOneStudentsOffset", payload.offset);
  },
  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  // --> OTHER ACTIONS
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  toggleStudentChecked: ({ commit }, { studentId, checked }) => {
    commit("toggleStudentCheckedMutation", { studentId, checked });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
